import { useMemo } from 'react'
import { useAuth } from '@/components/context/AuthProvider'
import { EASETECH_COMPANY } from '@/utilities/global-variables'

const useApprovalSetUserProfileIds = (appData?: ApplicationEntity): string[] => {
  const {user} = useAuth()
  const userOrganizationSlug = user?.organization?.data?.attributes?.slug
  const userOrgId = user?.organization?.data?.id
  const userCompanyId = user?.userProfile?.data?.attributes?.company?.data?.id

  const isOutEasetech = userOrganizationSlug !== EASETECH_COMPANY
  const isAppCreatedOutside = userOrgId !== appData?.attributes?.project?.data?.attributes?.organization?.data?.id
  const isIncomingTransferredApp = isAppCreatedOutside && isOutEasetech

  return useMemo(() => {
    if(isIncomingTransferredApp) {
      const filteredAppSet = appData?.attributes?.approvalSet?.filter(it => it?.company?.data?.id === userCompanyId)
      return (
        filteredAppSet?.reduce<string[]>((accum, it) => {
          if (it?.isSiteVisitRequired) {
            const contacts = it?.contacts?.data?.map(contact => contact.id || '') || [];
            return accum.concat(contacts);
          }
          return accum;
        }, []) || []
      );
    } else {
      return (
        appData?.attributes?.approvalSet?.reduce<string[]>((accum, it) => {
          if (it?.isSiteVisitRequired) {
            const contacts = it?.contacts?.data?.map(contact => contact.id || '') || [];
            return accum.concat(contacts);
          }
          return accum;
        }, []) || []
      );
    }

  }, [appData]);
};

export default useApprovalSetUserProfileIds;