import { useMediaQuery } from "react-responsive"

enum Breakpoints {
  XXS = 480,
  XS = 575,
  SM = 576,
  MD = 768,
  LG = 992,
  XL = 1200,
  XXL = 1600,
}

type SizeMap = Readonly<{ [key in keyof typeof Breakpoints]: Breakpoints }>

const sizes: SizeMap = {
  XXS: Breakpoints.XXS,
  XS: Breakpoints.XS,
  SM: Breakpoints.SM,
  MD: Breakpoints.MD,
  LG: Breakpoints.LG,
  XL: Breakpoints.XL,
  XXL: Breakpoints.XXL,
} as const

type DeviceType = "xxs"|"xs" | "sm" | "md" | "lg" | "xl" | "xxl"

type BreakpointHook = { [key in `is${Capitalize<DeviceType>}`]: boolean } & { sizes: SizeMap }

function useBreakpoints(): BreakpointHook {
  return {
    isXxs: useMediaQuery({
      maxWidth: Breakpoints.XXS,
    }),

    isXs: useMediaQuery({
      maxWidth: Breakpoints.SM - 1,
    }),

    isSm: useMediaQuery({
      minWidth: Breakpoints.SM,
      maxWidth: Breakpoints.MD - 1,
    }),

    isMd: useMediaQuery({
      minWidth: Breakpoints.MD,
      maxWidth: Breakpoints.LG - 1,
    }),

    isLg: useMediaQuery({
      minWidth: Breakpoints.LG,
      maxWidth: Breakpoints.XL - 1,
    }),

    isXl: useMediaQuery({
      minWidth: Breakpoints.XL,
      maxWidth: Breakpoints.XXL - 1,
    }),
    isXxl: useMediaQuery({ minWidth: Breakpoints.XXL }),
    sizes,
  }
}

export { Breakpoints, useBreakpoints }
