import PindropCircle from '@/components/google-map/marker-circle/PindorpCircle'
import PindropIcon from '@/components/google-map/marker-circle/PindropIcon'
import { Feature, Point } from 'geojson'
import { FC } from 'react'

type Props = {
  locationPoint: Feature<Point>
  index?: string
}
const MarkerCircle: FC<Props> = ({ locationPoint, index = '0' }) => {

  return (
    <>
      <PindropCircle locationPoint={locationPoint} index={index}  />
      <PindropIcon locationPoint={locationPoint} index={index} />
    </>
  )
}

export default MarkerCircle
