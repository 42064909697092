import { AuthProvider } from '@/components/context/AuthProvider'
import { getAuthToken } from '@/helpers/auth'
import { ApolloClient, ApolloProvider, createHttpLink, InMemoryCache } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { createContext, FC, PropsWithChildren, ReactNode, useContext, useEffect, useState } from 'react'
import ReactGA from 'react-ga4'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

import introspection from '../../graphql'
import Pages from '../../pages'
import { ErrorBoundary } from './ErrorBoundary'


// ===> initial settings just for backup the fetched settings from Strapi
export const PLATFORM_SETTINGS: Partial<Setting> = {
  afrigisBuffer: 20,
  chunkSize: 10,
  easetechSearchBuffer: 10,
  maxProjectPoints: 5,
  maxProjectLength: 2,
  maxProjectArea: 2,
  quotationVat: 15,
  closeToExpireDays: 30,
}
type TooltipMessages = {
  temporaryUnavailable: string
  createApplicationBtn: string
}
export const TOOLTIP_MESSAGES: TooltipMessages = {
  temporaryUnavailable: 'Temporary unavailable',
  createApplicationBtn: 'To get a free quote and create a project click here and follow the steps',
}

type AppProps = {
  user: { authenticated: boolean | null }
}

const defaultValue: AppProps = {
  user: { authenticated: null },
}

const UserContext = createContext<AppProps>(defaultValue)
const ContextProvider: FC<PropsWithChildren<Partial<ReactNode>>> = ({ children }) => {
  const token = getAuthToken()
  const [isAuth, setIsAuth] = useState<boolean>(false)

  useEffect(() => {
    if (token) {
      setIsAuth(!!token)
    }
  }, [token])

  return (
    <UserContext.Provider
      value={{ user: { authenticated: isAuth } }}
    >
      {children}
    </UserContext.Provider>
  )
}
const httpLink = createHttpLink({
  uri: `${import.meta.env.WEBSITE_API_URL ?? '/graphql'}`,
  credentials: 'same-origin',
})

const authLink = setContext((_, { headers }) => {
  const token = getAuthToken()
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : undefined,
    },
  }
})
const client = new ApolloClient({
  link: authLink.concat(httpLink),
  connectToDevTools: import.meta.env.DEV,
  queryDeduplication: true,
  assumeImmutableResults: true,
  cache: new InMemoryCache({
    resultCaching: import.meta.env.PROD,
    possibleTypes: introspection.possibleTypes,
  }),
})
const queryClient = new QueryClient()

const App: FC = () => {
  const GOOGLE_ANALYTICS_ID = import.meta.env.WEBSITE_GOOGLE_ANALYTICS_ID || '0000000' as string

  useEffect(() => {
    ReactGA.initialize(GOOGLE_ANALYTICS_ID)
    ReactGA.send({ hitType: 'pageview', page: window.location.pathname, title: window.location.pathname })
  }, [GOOGLE_ANALYTICS_ID])

  return (
    <ErrorBoundary>
      <ApolloProvider client={client}>
        <QueryClientProvider client={queryClient}>
          <AuthProvider>
            <ContextProvider>
              <Pages />
            </ContextProvider>
          </AuthProvider>
        </QueryClientProvider>
      </ApolloProvider>
    </ErrorBoundary>
  )
}

const useApp = () => useContext<AppProps>(UserContext)
export { App, useApp }


