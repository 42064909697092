import BlackNotification from '@/components/blackNotification/BlackNotification'
import { useAuth } from '@/components/context/AuthProvider'
import FormItemDivider from '@/components/form-item/FormItemDivider'
import { SvgIcon } from '@/components/icon'
import ItemTag from '@/components/item-tag/ItemTag'
import { globalFetchPolicy } from '@/components/layout/DashboardLayout'
import { useCompaniesQuery, useSendCompaniesNotificationsMutation } from '@/graphql'
import { longDash } from '@/helpers/globalVariables'
import useApplicationByUuid from '@/hooks/useApplicationByUuid'
import { Row, Skeleton, Space } from 'antd'
import moment from 'moment'
import { FC, useEffect, useState } from 'react'
import Button from '../../button/Button'
import { useGeneralContext } from '../../context/GeneralContext'
import Typography from '../../typography/Typography'

const { Title, Paragraph, Text } = Typography
const FollowUp: FC = () => {
  const {user} = useAuth()
  const { setIsModalOpen } = useGeneralContext()

  const [companies, setCompanies] = useState<CompanyEntity[]>([])
  const [approvals, setApprovals] = useState<ApprovalEntity[] | null>(null)

  const [sendCompaniesNotifications, { loading: notificationIsSending }] = useSendCompaniesNotificationsMutation()
  const { data: appDataQuery, loading: appDataLoading } = useApplicationByUuid()
  const appData = appDataQuery?.applications?.data?.[0] as ApplicationEntity

  useEffect(() => {
    const appApprovals = appData?.attributes?.approvals?.data as ApprovalEntity[]
    const twoWeeksAgo = moment().subtract(2, 'weeks')

    const outdatedApprovalItems = appApprovals?.filter(it => (it?.attributes?.status !== 'approved' && !it?.attributes?.feedback) || (it?.attributes?.status !== 'approved' && moment(it?.attributes?.feedback).isBefore(twoWeeksAgo)))

    setApprovals(outdatedApprovalItems)
  }, [appData?.attributes?.approvals?.data])

  const onCancel = () => {
    setIsModalOpen(false)
  }
  console.log(approvals)

  const onConfirm = async () => {
    const sentIds = approvals?.map(it => it.attributes?.company?.data?.id) as string[]
    sentIds && sentIds?.length && await sendCompaniesNotifications({
      fetchPolicy: globalFetchPolicy,
      variables: {
        input: {
          companiesIds: sentIds,
          applicationId: appData?.id || '',
          organizationId: user?.organization?.data?.id || '',
          category: 'approval',
          profileId: user?.userProfile?.data?.id || '',
        },
      },
      onCompleted: () => {
        BlackNotification('Notifications were sent successfully.')
        setIsModalOpen(false)
      },
      onError: (error) => {
        console.log(error)
        BlackNotification(error.message)
        setIsModalOpen(false)
      },
    })
  }

  const onItemClick = (id: Maybe<string>) => {
    const filteredApprovals = approvals?.filter(it => it?.id !== id) || []
    setApprovals(filteredApprovals)
  }

  return (
    <>
      <FormItemDivider
        title={'Follow Up approvals'}
        subTitle={'Below is a list of companies that have not yet approved the current application, with feedback dates older than two weeks.'}
      />
      {!approvals?.length ? <Paragraph style={{ marginTop: 32 }} size={'sm'} weight={'w400'}>{'No approval items to follow up'}</Paragraph> : (
        <>
          <Paragraph style={{ marginTop: 12 }} size={'sm'} weight={'w700'}>
            {`Notification email will be sent to all listed companies`}
          </Paragraph>

        </>

      )}
      {appDataLoading && (
        <Skeleton paragraph={{ rows: 5 }} loading={true} />
      )}
      <Space direction={'vertical'} className={'tag-list-container'}>
        {approvals?.map(it => (
          <ItemTag
            key={it?.id}
            mainText={it?.attributes?.name}
            secondaryText={it?.attributes?.company?.data?.attributes?.companyEmail}
            iconType={'envelop'}
            closable={true}
            onClick={onItemClick}
            itemId={it?.id}
            extra={<Text size={'xs'} color={'gray'}>{`Feedback: ${it?.attributes?.feedback ? moment(it?.attributes?.feedback)?.format('DD-MMM YYYY'): longDash}`}</Text>}
          />
        ))}

      </Space>
      <Row style={{ paddingTop: 30 }} justify={'end'}>
        <Button
          text={'Cancel'}
          btnType={'text'}
          onClick={onCancel}
        />
        <Button
          text={'Send notifications'}
          btnType={'primary'}
          onClick={onConfirm}
          loading={notificationIsSending || appDataLoading}
          icon={<SvgIcon type={'envelop'} />}
          isIconWhite={true}
        />
      </Row>
    </>
  )
}
export default FollowUp
