import BlackNotification from '@/components/blackNotification/BlackNotification'
import Button from '@/components/button/Button'
import FormItemDivider from '@/components/form-item/FormItemDivider'
import { lineLayerProps } from '@/components/google-map/helpers/lineLayersProps'
import MapBoxMap from '@/components/google-map/map-box-map/MapBoxMap'
import ProvincesSelection
  from '@/components/google-map/provinces-selection/ProvincesSelection'
import { globalFetchPolicy } from '@/components/layout/DashboardLayout'
import {
  useAreaQuery,
  useProvincesQuery,
  useUpdateAreaMutation,
  useUpdateCompanyMutation,
} from '@/graphql'
import { Form, Row, Skeleton } from 'antd'
import { useForm } from 'antd/es/form/Form'
import moment from 'moment/moment'
import { FC, MutableRefObject, Ref, useEffect, useRef, useState } from 'react'
import { Layer, MapRef, Source } from 'react-map-gl'
import { useSearchParams } from 'react-router-dom'
import { useGeneralContext } from '../../context/GeneralContext'
import FormItem, { SelectOptionsType } from '../../form-item/FormItem'
import {
  getGeoJsonFromUrl,
} from '@/components/google-map/helpers/getGeoJsonFromUrl'
import { mapFitBounds } from '@/components/google-map/helpers/mapFitBounds'

const statusOptions = [
  {
    label: 'Active',
    value: 'active',
  },
  {
    label: 'Outdated',
    value: 'outdated',
  },
  {
    label: 'Inactive',
    value: 'inactive',
  },
]
const selectOptions = [
  {
    label: 'General',
    value: 'general',
  },
  {
    label: 'Utility',
    value: 'utility',
  },
]
const GeometrySettings: FC = () => {
  const [form] = useForm()
  const mapRef = useRef<MapRef>()
  const [searchParams] = useSearchParams()
  const currentCompanyId = searchParams.get('id')

  const {
    setIsModalOpen,
    currentId,
    setModalTopOffset,
    setModalWidth,
  } = useGeneralContext()
  const [selectedProvincesIds, setSelectedProvincesIds] = useState<string[]>([])
  const [isMapSaveDisabled, setIsMapSaveDisabled] = useState(true)
  const [isSaveDisabled, setIsSaveDisabled] = useState(true)

  const [updateCompany, { loading: companyUpdating }] = useUpdateCompanyMutation()
  const [updateArea, { loading: areaUpdating }] = useUpdateAreaMutation()
  const { data: provincesData } = useProvincesQuery({
    fetchPolicy: globalFetchPolicy,
  })
  const provinces = provincesData?.provinces?.data as ProvinceEntity[]
  const provincesOptions = provinces?.map(it => ({
    label: it?.attributes?.name?.replace('.kml', ''),
    value: it?.id,
  })) as SelectOptionsType[]

  const { data, loading } = useAreaQuery({
    fetchPolicy: globalFetchPolicy,
    variables: { id: currentId },
  })
  const currentArea = data?.area?.data as AreaEntity

  useEffect(() => {

    setSelectedProvincesIds(currentArea?.attributes?.provinces?.data?.map(it => it?.id as string) || [])
  }, [currentArea?.attributes?.provinces?.data])

  useEffect(() => {
    const getCurrentItemGeoJson = async () => {
      const currentGeoJson = await getGeoJsonFromUrl(currentArea?.attributes?.geoJson?.data?.attributes?.url)
      mapFitBounds(mapRef as unknown as MutableRefObject<MapRef | undefined>, currentGeoJson)
    }
    getCurrentItemGeoJson().then()

  }, [currentArea?.attributes?.geoJson?.data?.attributes?.url])

  const handleChange = () => {
    setIsSaveDisabled(false)
  }

  const initialValues = {
    status: currentArea?.attributes?.status,
    provinces: currentArea?.attributes?.provinces?.data?.map(it => it?.id),
    category: currentArea?.attributes?.category,
  }
  const onProvinceChange = (idsArray: string[]) => {
    idsArray && setSelectedProvincesIds(idsArray)
    setIsMapSaveDisabled(false)
  }

  const onMapSave = async () => {
    await updateArea({
      fetchPolicy: globalFetchPolicy,
      variables: {
        id: currentId,
        data: {
          provinces: selectedProvincesIds,
        },
      },
      onError: (error) => {
        console.log(error)
      },
      onCompleted: () => {
        BlackNotification('Geometry settings updated successfully.')
        setIsMapSaveDisabled(true)
      },

    })
    currentCompanyId && await updateCompany({
      fetchPolicy: globalFetchPolicy,
      variables: {
        id: currentCompanyId,
        data: {
          geometryLastUpdate: moment().format('YYYY-MM-DD'),
        },
      },
      onError: (error) => {
        console.log(error)
        BlackNotification(error.message)
      },
      refetchQueries: 'active',
    })
  }
  const onSave = async (values: {
    status: EnumAreaStatus,
    category: InputMaybe<EnumAreaCategory>
  }) => {
    await updateArea({
      fetchPolicy: globalFetchPolicy,
      variables: {
        id: currentId,
        data: {
          category: values?.category,
          status: values.status,
          statusUpdated: moment().format('YYYY-MM-DD'),
        },
      },
      onError: (error) => {
        console.log(error)
      },
      onCompleted: () => {
        BlackNotification('Geometry status updated successfully.')
        setIsModalOpen(false)
        setModalTopOffset(null)
        setModalWidth(null)
      },
      refetchQueries: 'active',
    })
    currentCompanyId && await updateCompany({
      fetchPolicy: globalFetchPolicy,
      variables: {
        id: currentCompanyId,
        data: {
          geometryLastUpdate: moment().format('YYYY-MM-DD'),
        },
      },
      onError: (error) => {
        console.log(error)
        BlackNotification(error.message)
      },
    })
  }
  const onClose = () => {
    setIsModalOpen(false)
    setModalTopOffset(null)
    setModalWidth(null)
  }
  return (
    <>
      <FormItemDivider
        title={'Geometry settings'}
        subTitle={`Geometry item: ${currentArea?.attributes?.name}`}
      />
      <div className={'big-modal-with-scroll-container'}>

        {loading ? (
          <Skeleton active paragraph={{ rows: 6 }} />
        ) : (
          <Form form={form} layout={'vertical'} autoComplete={'off'}
                initialValues={initialValues} onFinish={onSave}>
            <FormItemDivider
              label={'Geometry province'}
              subTitle={'Geometry province determines that the geometry will be processed  only within selected provinces while creating an application.'}
            />
            <FormItem
              name={'provinces'}
              type={'multi-select'}
              label={'Provinces'}
              selectOptions={provincesOptions}
              handleMultiChange={onProvinceChange}
              placeholder={'Select Provinces'}
              loading={loading}
            />
            <MapBoxMap
              isAreasCreating={areaUpdating}
              isSaving={areaUpdating}
              mapRef={mapRef as Ref<MapRef>}
              mapContainerHeight={500}
              isSaveButtonShow={true}
              isDrawTools={false}
              isGeometryVisibilityToggle={false}
              onSave={onMapSave}
              isSaveLoading={areaUpdating}
              isSaveDisabled={isMapSaveDisabled}
              isLoading={loading || companyUpdating}
            >
              <ProvincesSelection provinces={provinces}
                                  selectedProvincesIds={selectedProvincesIds} />
              <Source
                key={currentArea?.id}
                id={currentArea?.id as string}
                type={'geojson'}
                data={currentArea?.attributes?.geoJson?.data?.attributes?.url}

              >
                <Layer
                  {...lineLayerProps}
                  id={currentArea?.id as string}
                  paint={{
                    'line-width': 2,
                    'line-color': '#d93232',
                  }}
                />
              </Source>
            </MapBoxMap>
            <FormItemDivider
              marginTop={24}
              label={'Geometry category'}
              subTitle={'Geometry category determines whether the geometry will be processed during the Create Application stage or be available for checking only at the Approval stage.'}
            />
            <FormItem
              notFoundText={'No empty projects...'}
              name={'category'}
              type={'select'}
              label={'Category'}
              placeholder={'Select geometry category'}
              selectOptions={selectOptions}
              handleSelectChange={handleChange}
            />
            <FormItemDivider
              marginTop={24}
              label={'Geometry status'}
              subTitle={'Geometry status shows how long ago the geometry was loaded and whether it will be taken into account when creating an application (not activated now).'}
            />
            <FormItem name={'status'} placeholder={'Select geometry status'}
                      type={'select'} label={'Status'}
                      selectOptions={statusOptions}
                      handleSelectChange={handleChange} />
            <Row justify={'end'}>
              <Button text={'Close'} btnType={'text'} onClick={onClose} />
              <Button text={'Save changes'} loading={areaUpdating}
                      disabled={isSaveDisabled} htmlType={'submit'} />
            </Row>
          </Form>
        )}
      </div>

    </>
  )
}
export default GeometrySettings
