import BlackNotification from '@/components/blackNotification/BlackNotification'
import FormItem from '@/components/form-item/FormItem'
import { globalFetchPolicy } from '@/components/layout/DashboardLayout'
import { useAppApprovalProgressUpdateMutation, useCreateApprovalMutation, useUpdateApplicationMutation } from '@/graphql'
import useApplicationByUuid from '@/hooks/useApplicationByUuid'
import useCompanyOptions, { ModeType } from '@/hooks/useCompaniesOptions'
import { Form, Row } from 'antd'
import { useForm } from 'antd/es/form/Form'
import { FC, useState } from 'react'
import { v4 } from 'uuid'
import Button from '../../button/Button'
import { useGeneralContext } from '../../context/GeneralContext'
import Typography from '../../typography/Typography'

const { Title, Paragraph } = Typography
const AddApprovalItem: FC = () => {
  const [form] = useForm()
  const [isConfirmDisabled, setIsConfirmDisabled] = useState(true)
  const [updateApplication, { loading: applicationUpdating }] = useUpdateApplicationMutation()
  const { data: appDataQuery, loading: appDataLoading } = useApplicationByUuid()
  const appData = appDataQuery?.applications?.data?.[0] as ApplicationEntity
  const { setIsModalOpen } = useGeneralContext()

  const { data, loading } = useApplicationByUuid()
  const currentApplication = data?.applications?.data?.[0] as ApplicationEntity
  const currentApplicationType = currentApplication?.attributes?.applicationType?.data?.attributes?.slug as ModeType

  const { companyOptions, loading: companiesDataLoading } = useCompanyOptions(currentApplicationType)
  const [createApproval, { loading: creatingApproval }] = useCreateApprovalMutation()
  const [updateAppProgress, { loading: appProgressUpdating }] = useAppApprovalProgressUpdateMutation()


  const onCancel = () => {
    setIsModalOpen(false)
  }
  const onConfirm = async () => {
    form.submit()
  }
  const handleSelectChange = () => {
    setIsConfirmDisabled(false)
  }
  const onFinish = async (values: { company: string }) => {
    console.log(values)
    const selectedOption = companyOptions?.find(it => it?.value === values?.company)
    console.log(selectedOption)
    const createdApprovalResult = await createApproval({
      variables: {
        input: {
          name: selectedOption?.label as string || '',
          uuid: v4(),
          company: values?.company,
        },
      },
    })
    const createdApproval = createdApprovalResult?.data?.createApproval?.data as ApprovalEntity
    console.log(createdApproval)

    // const newApprovalItem = {
    //   name: values?.company,
    //   company: values?.company,
    // } as ComponentDataApplicationServiceProviderInput
    // const newApprovalSet = [newApprovalItem, ...approvalSetCopy || []]

    // const newProgress = approvalsProgressCalculator(newApprovalSet)
    const existedApprovals = appData?.attributes?.approvals?.data?.map(it => it?.id) || []

    appData?.id && await updateApplication({
      fetchPolicy: globalFetchPolicy,
      variables: {
        id: appData?.id,
        data: {
          approvals: [createdApproval?.id, ...existedApprovals],
        },
      },
      onError: (error) => {
        console.log(error)
        BlackNotification(`Error while trying to create approval item. ${error.message}`)
      },
    })
    appData?.id && await updateAppProgress({
      variables: { input: { appId: appData?.id } },
      onCompleted: () => {
        setIsModalOpen(false)
        BlackNotification('New approval item created successfully. Application progress updated')
      },
      refetchQueries: 'active',
    })
  }

  return (
    <>
      <Title level={3}>
        {'Add approval item'}
      </Title>
      <Paragraph style={{ marginTop: 32 }} size={'sm'} weight={'w400'}>
        {`Please, select a Company in the form below to add new approval item to a current application.`}
      </Paragraph>
      <Form layout={'vertical'} onFinish={onFinish} form={form}>
        <FormItem
          name={'company'}
          label={'Company'}
          type={'select'}
          selectOptions={companyOptions}
          placeholder={'Select Company'}
          loading={companiesDataLoading || loading}
          showSearch={true}
          handleSelectChange={handleSelectChange}

        />
      </Form>
      <Row style={{ paddingTop: 30 }} justify={'end'}>
        <Button
          text={'Cancel'}
          btnType={'text'}
          onClick={onCancel}
          loading={creatingApproval || applicationUpdating}
        />
        <Button
          text={'Confirm'}
          btnType={'primary'}
          onClick={onConfirm}
          loading={applicationUpdating || appDataLoading || creatingApproval}
          disabled={isConfirmDisabled}
        />
      </Row>
    </>
  )
}
export default AddApprovalItem
