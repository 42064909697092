import { FC } from 'react'
import './TitleTag.less'
import Typography from '../typography/Typography'

const { Text } = Typography
type Props = {
  text: Maybe<string>
  fontSize?: 'md' | 'sm' | 'xs'
}
const TitleTag:FC<Props> = ({text, fontSize = 'xs'}) => {
  return (
    <div className={'title-tag'}>
      <Text weight={'w700'} size={fontSize}>{text}</Text>
    </div>
  )
}

export default TitleTag
