import Typography from '@/components/typography/Typography'
import { Checkbox, Row } from 'antd'
import { FC } from 'react'

const { Text } = Typography
type Props = {
  name: string,
  checked: Maybe<boolean>,
}
const CheckBoxInfoItem:FC<Props> = ({name, checked}) => {
  return (
    <Row align={'middle'} style={{paddingBottom: 12}}>
      <Checkbox name={'approved'} checked={checked ?? true} disabled={true} />
      <Text style={{paddingLeft: 8}}>{name}</Text>
    </Row>
  )
}

export default CheckBoxInfoItem
