import GeometryCollectionRender from '@/components/google-map/helpers/MapFeatureRenderes/GeometryCollectionRender'
import LineStringRender from '@/components/google-map/helpers/MapFeatureRenderes/LineStringRender'
import MarkerRender from '@/components/google-map/helpers/MapFeatureRenderes/MarkerRender'
import PolygonRender from '@/components/google-map/helpers/MapFeatureRenderes/PolygonRender'
import MarkerCircle from '@/components/google-map/marker-circle/MarkerCircle'
import {
  point,
} from '@turf/turf'
import { Feature, FeatureCollection, Geometry, GeometryCollection, LineString, Point, Polygon, Position } from 'geojson'
import React, { FC, MutableRefObject, Ref } from 'react'
import { MapRef } from 'react-map-gl'


type Props = {
  project: FeatureCollection<Geometry | GeometryCollection | Point> | null
  isSimplify?: boolean
  color?: string
  fillColor?: string
}
const GeoJsonRender: FC<Props> = ({ project, isSimplify = false, color, fillColor }) => {
  return (
    <>
      {project?.features?.map((feature, index) => {
        if (feature?.geometry?.type === 'Point') {
          return (
            <React.Fragment key={index}>
              {/*<MarkerRender feature={feature as Feature<Point>} index={index + 'marker'} />*/}
              <MarkerCircle locationPoint={feature as Feature<Point>} index={index + 'marker'}/>
            </React.Fragment>

            )
        }
        if (feature?.geometry?.type === 'LineString') {
          const currentFeature = feature as Feature<LineString>
          const firstPoint = point(currentFeature?.geometry?.coordinates?.[0])
          const lastPoint = point(currentFeature?.geometry?.coordinates?.[currentFeature?.geometry?.coordinates?.length - 1])

          return (
            <React.Fragment key={index}>
              <LineStringRender feature={feature as Feature<LineString>} index={index + 'lineString'} />
              {!isSimplify && (
              <>
                <MarkerCircle locationPoint={firstPoint} index={index + 'firsPoint'} />
                <MarkerCircle locationPoint={lastPoint} index={index + 'lastPoint'} />
              </>
              )}
            </React.Fragment>
          )
        }
        if (feature?.geometry?.type === 'Polygon') {
          const currentFeature = feature as Feature<LineString>
          // const firstPoint = point(currentFeature?.geometry?.coordinates?.[0])

          const pointsCoords = currentFeature?.geometry?.coordinates?.[0]
          const slicedPointsCoords = pointsCoords?.slice(0, pointsCoords?.length - 1) as unknown as Position[]
          const points = slicedPointsCoords?.map(it => point(it))

          return (
            <React.Fragment key={index}>
              {!isSimplify && points?.map((it, index) => {
                return <MarkerCircle key={index + 'marker'} index={index + 'marker'} locationPoint={it} />
              })}
              <PolygonRender feature={feature as Feature<Polygon>} index={index + 'polygon'} color={color} fillColor={fillColor} />
            </React.Fragment>
          )
        }
        if (feature?.geometry?.type === 'GeometryCollection') {
          return <GeometryCollectionRender key={feature?.id || ''} geometryCollection={feature?.geometry as GeometryCollection} />
        }
        return null
      })
      }
    </>
  )
}

export default GeoJsonRender
