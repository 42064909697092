import { useState, useEffect, useCallback } from "react";
import { useEmailMessageLazyQuery } from "@/graphql";
import { message } from "antd";

type UseEmailHistoryHook = {
  emails: EmailMessageEntity[];
  loading: boolean;
  refetchEmails: () => void; // Function to trigger refetch
};

const useEmailHistory = (approvalItem: ApprovalEntity): UseEmailHistoryHook => {
  const [emails, setEmails] = useState<EmailMessageEntity[]>([]);
  const [fetchEmailMessage, { loading }] = useEmailMessageLazyQuery();

  const approvalItemEmails = approvalItem?.attributes?.emailMessages?.data || ([] as EmailMessageEntity[]);

  const fetchReferencedEmails = useCallback(async () => {
    if (!approvalItem?.attributes?.emailMessages?.data?.length) return;

    try {
      const emailList: EmailMessageEntity[][] = await Promise.all(
        approvalItemEmails.map(async (email) => {
          let currentEmails: EmailMessageEntity[] = [];

          if (email?.attributes?.references?.data?.length) {
            const fetchedEmails = await Promise.all(
              email.attributes?.references.data.map(async (ref) => {
                if (!ref?.id) return null;

                const response = await fetchEmailMessage({
                  fetchPolicy: 'network-only',
                  variables: { id: ref.id },
                  onError: (e) => {
                    console.error(e);
                    message.error(e.message);
                  },
                });

                return response?.data?.emailMessage?.data ?? null;
              })
            );
            const refEmails = fetchedEmails?.filter((email) => email !== null) as EmailMessageEntity[]
            currentEmails = [...refEmails, email]
          } else {
            currentEmails = [email]
          }

          return currentEmails;
        })
      );

      setEmails(emailList.flat());
    } catch (error) {
      message.error("Failed to fetch email history");
    }
  }, [approvalItem, fetchEmailMessage]);

  // Fetch emails on mount and when approvalItem changes
  useEffect(() => {
    fetchReferencedEmails().then();
  }, [fetchReferencedEmails]);

  return { emails, loading, refetchEmails: fetchReferencedEmails };
};

export default useEmailHistory;
