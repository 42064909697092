import sectionBg from './images/section-bg-02.webp'
import { Row, Skeleton } from 'antd'
import { FC } from 'react'

import './WebsiteLandingLoader.less'

const WebsiteLandingLoader: FC = () => {

  return (
    <div className={'wl-loader-wrapper'}>

      <div className={'wl-loader-bg'}>
        <img src={sectionBg} alt={''} />
      </div>

      <Row style={{ padding: 12 }}>
        <Skeleton active round paragraph={{ rows: 2 }}  style={{ marginBottom: 30 }} />
        <Skeleton active round paragraph={{ rows: 8 }} />
      </Row>
    </div>
  )
}

export default WebsiteLandingLoader