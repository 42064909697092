import Button from '@/components/button/Button'
import { ApprovalLetterInfo, useGeneralContext } from '@/components/context/GeneralContext'
import FormItemDivider from '@/components/form-item/FormItemDivider'
import PdfViewer from '@/components/modal/modal-content/components/pdf-viewer/PdfViewer'
import Typography from '@/components/typography/Typography'
import { Col, Row, Skeleton, Space } from 'antd'
import { FC } from 'react'

type Props = {
  docInfo: ApprovalLetterInfo | null
  onClose: ()=>void
  handlePreviewClose: ()=>void
}
const { Paragraph } = Typography
const ApprovalLetterPreview: FC<Props> = ({docInfo, onClose, handlePreviewClose}) => {
  const {setApprovalLetterInfo} = useGeneralContext()
const handleAddToApproval = () => {
  setApprovalLetterInfo(docInfo)
  handlePreviewClose()
}
  return (
    <Row>
      <Col span={24}>
        <FormItemDivider title={'Approval letter preview'} subTitle={'PDF approval letter preview'} />
        <Paragraph>{'Please review the generated approval letter. If everything looks good, click the \'Add to approval documents\' button.'}</Paragraph>
      </Col>
      <Col span={24} className={'approval-letter-preview-doc-wrapper'}>
        {!docInfo?.url ? <Skeleton active paragraph={{ rows: 8 }} /> : (
          // <embed src={url || ''} width={'100%'} height={'600px'} type={'application/pdf'} />
          <PdfViewer url={docInfo?.url} />
        )}
      </Col>
      <Col span={24} style={{paddingTop: 24}}>
        <Row justify={'end'}>
          <Space>
            <Button btnType={'ghost'} text={'Close'} onClick={onClose} />
            <Button text={'Add to approval documents'} disabled={!docInfo?.url} onClick={handleAddToApproval}/>
          </Space>
        </Row>
      </Col>
    </Row>
  )
}

export default ApprovalLetterPreview
