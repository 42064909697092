import Button from '@/components/button/Button'
import { SvgIcon } from '@/components/icon'
import ApprovalReview from '@/components/modal/modal-content/ApprovalReview'
import CompanyInfo from '@/components/modal/modal-content/CompanyInfo'
import CreateCompany from '@/components/modal/modal-content/CreateCompany'
import CreateUserProfile from '@/components/modal/modal-content/CreateUserProfile'
import EmailLog from '@/components/modal/modal-content/EmailLog'
import GenerateApprovalDocument from '@/components/modal/modal-content/GenerateApprovalDocument'
import UserProfileReview from '@/components/modal/modal-content/UserProfileReview'
import { FC } from 'react'
import { useGeneralContext } from '../context/GeneralContext'
import './modal-content/DrawerContent.less'


const DrawerContent: FC = () => {
  const { drawerType, setIsDrawerOpen } = useGeneralContext()
const onDrawerClose = () => {
  setIsDrawerOpen(false)
}
  const drawerContentGenerator = () => {
    switch (drawerType) {
      case 'email-history':
        return <EmailLog />

      case 'generate-approval-document':
        return <GenerateApprovalDocument />

      case 'create-profile':
        return <CreateUserProfile />

      case 'create-company':
        return <CreateCompany />

      case 'company-info':
        return <CompanyInfo />

      case 'approval-review':
        return <ApprovalReview />

      case 'profile-review':
        return <UserProfileReview />

      default:
        return <p>No drawer content ...</p>
    }
  }
  return <div className={'drawer-content'}>
    <>
      <Button size={'middle'} className={'drawer-close-icon'} btnType={'icon'} icon={<SvgIcon type={"close-modal"} />} onClick={onDrawerClose} />
      {drawerContentGenerator()}
    </>
  </div>
}

export default DrawerContent
