import { useAuth } from '@/components/context/AuthProvider'
import { SvgIcon } from '@/components/icon'
import { genMenuItems } from '@/components/sidebar-new/sider-menu/genMenuItems'
import { ROUTES } from '@/router/routes'
import { EASETECH_COMPANY } from '@/utilities/global-variables'
import { Menu, MenuProps } from 'antd'
import classnames from 'classnames'
import { FC, useEffect, useMemo, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { genProjectMenuItems } from './genProjectMenuItems'
import './SiderMenu.less'

const { dashboard } = ROUTES

const SiderMenu: FC<{ collapsed: boolean }> = ({ collapsed }) => {
  const mode = localStorage.getItem('menu-mode')
  const currentProjectUuid = localStorage.getItem('current-project-uuid')

  const [searchParams] = useSearchParams()
  const uuid = searchParams.get('id')

  const { role, organization } = useAuth()
  const currentPage = location?.pathname?.split('/')?.[2]
  const navigate = useNavigate()
  const [current, setCurrent] = useState('')

  useEffect(() => {
    setCurrent(currentPage)
  }, [currentPage])

  useEffect(() => {
    if (!mode) {
      localStorage.setItem('menu-mode', 'default')
    }
  }, [mode])

  const menuClasses = classnames({
    'sider-menu-container': true,
    'collapsed': collapsed,
  })
  const backLinkClasses = classnames({
    'back-link-icon': true,
    'collapsed': collapsed,
  })
  const menuWrapper = classnames({
    'sider-menu-wrapper': true,
    'collapsed': collapsed,
  })

  let defaultProjectsPageKey = 'outgoing'
  if(organization === EASETECH_COMPANY) {
    defaultProjectsPageKey = 'outgoing'
  }
  if(organization !== EASETECH_COMPANY && role === 'client') {
    defaultProjectsPageKey = 'incoming'
  }

  const onMenuClick: MenuProps['onClick'] = (e) => {
    setCurrent(e.key)
    switch (e.key) {
      case 'projects':
        navigate(`/dashboard/${e.key}/${defaultProjectsPageKey}`)
        break
      case 'companies':
        navigate(`/dashboard/${e.key}/all?page=1`)
        break
      case 'email-logs':
        navigate(`/dashboard/${e.key}/all?page=1`)
        break
      case 'platform-settings':
        navigate(`/dashboard/${e.key}/general`)
        break
      default:
        navigate(`/dashboard/${e.key}`)
    }
  }
  const onProjectMenuClick: MenuProps['onClick'] = (e) => {
    setCurrent(e.key)
    switch (e.key) {
      case 'overview':
        navigate(`${dashboard.project}/overview?id=${currentProjectUuid}`)
        break
      case 'applications':
        navigate(`${dashboard.project}/applications?id=${currentProjectUuid}`)
        break
      default:
        navigate(`${dashboard.projects}/outgoing`)
    }
  }
  const onProjectInfoIconClick = () => {
    navigate(`${dashboard.projects}/outgoing`)
    localStorage.setItem('menu-mode', 'default')
  }

  const menuItems = useMemo(() => genMenuItems(role, organization || ''), [organization, role])
  const projectMenuItems = useMemo(() => genProjectMenuItems(), [])
  return (
    <div className={menuWrapper}>
      {mode === 'default' && (
        <Menu
          items={menuItems}
          onClick={onMenuClick}
          className={menuClasses}
          defaultSelectedKeys={[current]}
          selectedKeys={[current]}
          mode={"inline"}
          inlineCollapsed={collapsed}
        />
      )}
      {mode === 'project-info' && (
        <>
          <div onClick={onProjectInfoIconClick} className={backLinkClasses}>
            <SvgIcon type={'project-info'} />
          </div>
          <Menu
            items={projectMenuItems}
            onClick={onProjectMenuClick}
            className={menuClasses}
            defaultSelectedKeys={[current]}
            selectedKeys={[current]}
          />
        </>
      )}
    </div>
  )
}

export default SiderMenu
