import './Header.less'
import Button from '@/components/button/Button'
import { SvgIcon } from '@/components/icon'
import { globalFetchPolicy } from '@/components/layout/DashboardLayout'
import { useBreakpoints } from '@/components/screen'
import WebsiteButton from '@/components/website-button/WebsiteButton'
import websiteTypography from '@/components/websiteTypography/WebsiteTypography'
import { useOrganizationsQuery } from '@/graphql'
import { getAuthToken } from '@/helpers/auth'
import { scrollToSection } from '@/helpers/scrollToSection'
import { websiteRoutes } from '@/router/routes'
import { defaultDarkPrimaryColor, EASETECH_COMPANY } from '@/utilities/global-variables'

import { Menu, MenuProps, Popover, Skeleton, Space } from 'antd'

import { FC, useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router'

const { Text } = websiteTypography

const items = [
  {
    label: 'home',
    key: 'home',
  },
  {
    label: 'our services',
    key: 'our-services',
  },
  {
    label: 'industries',
    key: 'industries',
  },
  {
    label: 'consulting',
    key: 'consulting',
  },
  {
    label: 'contact us',
    key: 'contact-us',
  },
  // {
  //   label: 'blog',
  //   key: 'blog',
  // },
]

const Header: FC = () => {

  const navigate = useNavigate()
  const { website, dashboard } = websiteRoutes
  const params = useParams()
  const currentOrgSlug = params.org || EASETECH_COMPANY
  const { pathname } = useLocation()

  const { isXxl, isXl, isLg } = useBreakpoints()

  const parts = pathname.split('/')
  const currentPageKey = parts[parts.length - 1]

  const [pageKey, setPageKey] = useState(currentPageKey)
  const [isPopoverOpen, setPopoverOpen] = useState(false)

  const { data, loading } = useOrganizationsQuery({
    fetchPolicy: globalFetchPolicy,
    variables: {
      filter: { slug: { eq: currentOrgSlug } },
    },
  })

  useEffect(() => {

    setPageKey(currentPageKey)
  }, [currentPageKey])

  const currentOrganization = data?.organizations?.data?.[0]

  const onLogoClick = () => {

    setPageKey('home')
    navigate(`/${currentOrgSlug}${website.home}`)
  }

  const onOpenChange = (isOpen: boolean)=>{
    setPopoverOpen(isOpen)
  }

  const onSignIn = ()=>{
    navigate(`/${currentOrgSlug}${website.auth}/login`)
  }

  const onRegister = ()=>{
    navigate(`/${currentOrgSlug}${website.auth}/client`)
  }

  const onClick: MenuProps['onClick'] = async (e) => {

    onOpenChange(false)

    if (e.key === 'home') {
      navigate(`/${currentOrgSlug}${website.home}`)
    }
    if (e.key === 'our-services') {
      navigate(`/${currentOrgSlug}${website.ourServices}`)
    }
    if (e.key === 'industries') {
      navigate(`/${currentOrgSlug}${website.industries}`)
    }
    if (e.key === 'consulting') {
      navigate(`/${currentOrgSlug}${website.consulting}`)
    }
    if (e.key === 'contact-us') {
      await scrollToSection('phone-section')
    }

    // if (e.key === 'blog') {
    //   navigate(`/${currentOrgSlug}${website.blog}`)
    // }
  }

  const onGoToDashboard = () => {
    navigate(dashboard.overview)
  }

  const content = (

    <div
      className={'burger-popup-content'}
      style={{ backgroundColor:
          currentOrgSlug !== EASETECH_COMPANY && currentOrganization?.attributes?.primaryColor
            ? currentOrganization?.attributes?.primaryColor
            : undefined }}
    >
      {currentOrgSlug === EASETECH_COMPANY && (
        <Menu
          selectedKeys={[pageKey]}
          items={items}
          className={'header-menu'}
          onClick={onClick}
        />
      )}

      {!getAuthToken() ? (
        <Space
          className={'burger-btn-block'}
          direction={'vertical'}
          style={{ paddingTop: '10px', paddingBottom: '10px', width: '100%' }}
        >
            <WebsiteButton
              onClick={onSignIn}
              btnType={'ghost-white'}
              style={{ marginRight: 8, width: '100%' }}
            >
              <Text size={'md'}>{'SIGN IN'}</Text>
            </WebsiteButton>

            <WebsiteButton
              onClick={onRegister}
              btnType={'primary'}
              style={{ marginRight: 8, width: '100%' }}
            >
              <Text size={'md'}>{'REGISTER'}</Text>
            </WebsiteButton>

        </Space>
      ) : (
        <WebsiteButton onClick={onGoToDashboard}>{'go to dashboard'}</WebsiteButton>
      )}
    </div>

  )

  return (
    <div className={'main-header'} style={{ backgroundColor: currentOrganization?.attributes?.primaryColor || defaultDarkPrimaryColor }}>
      <div className={'header-content'}>
        <div className={'header-logo-wrapper'} onClick={onLogoClick}>
          {loading ? <Skeleton loading={true} paragraph={{ rows: 1 }} /> : (
            <img src={currentOrganization?.attributes?.logoWithCompanyName?.data?.attributes?.url} alt={'logo'} />
          )}
        </div>
        <div
          className={'header-content-wrapper'}
          style={currentOrgSlug !== EASETECH_COMPANY ? { justifyContent: 'end' } : undefined}
        >
          {(currentOrgSlug === EASETECH_COMPANY && (isXxl || isXl || isLg)) && (
            <Menu selectedKeys={[pageKey]} items={items} mode={'horizontal'} className={'header-menu'} onClick={onClick} />
          )}

          {(isXxl || isXl || isLg) ? (
              <div className={'btn-block'}>
                {!getAuthToken() ? (
                <>
                  <WebsiteButton
                    onClick={onSignIn}
                    btnType={'ghost-white'}
                    style={{ marginRight: 8, width: '100%' }}
                  >
                    <Text size={'md'}>{'SIGN IN'}</Text>
                  </WebsiteButton>

                  <WebsiteButton
                    onClick={onRegister}
                    btnType={'primary'}
                    style={{ marginRight: 8, width: '100%' }}
                  >
                    <Text size={'md'}>{'REGISTER'}</Text>
                  </WebsiteButton>
                </>
              ) : (
                <WebsiteButton
                  onClick={onGoToDashboard}
                  // className={'to-dashboard-btn'}
                >
                  {'to dashboard'}
                </WebsiteButton>
              )}
            </div>
          ) :
            <Popover
              placement={'bottomRight'}
              title={null}
              content={content}
              trigger={'click'}
              showArrow={false}
              overlayClassName={'popover-burger-container'}
              overlayStyle={{
                borderRadius: '7px',
                padding: 0,
                border: `1px solid ${
                  currentOrgSlug !== EASETECH_COMPANY && currentOrganization?.attributes?.primaryColor
                    ? currentOrganization?.attributes?.primaryColor
                    : '@primary-dark-blue'
                }`
              }}
              onOpenChange={onOpenChange}
              open={isPopoverOpen}
            >
              <Button
                className={'burger-button'}
                btnType={'icon'}
                icon={<SvgIcon type={isPopoverOpen ? 'close-modal' : 'burger-icon'} />}
              />
            </Popover>
          }
        </div>
      </div>
    </div>
  )
}

export default Header
