import { SvgIcon } from '@/components/icon'
import { Row } from 'antd'
import { FC } from 'react'
import './file-image-placeholder.less'
import Typography from '../typography/Typography'

const { Paragraph } = Typography
type Props = {
  text: string
}
const FileImagePlaceholder:FC<Props> = ({text}) => {
  return (
    <Row className={'image-placeholder'} align={'middle'}>
        <SvgIcon className={'image-icon-wrapper'} type={'image'} color={'light-gray'} />
        <Paragraph color={'light-gray'} style={{margin: 0}}>{text}</Paragraph>
    </Row>
  )
}

export default FileImagePlaceholder
