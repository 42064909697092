import Button from '@/components/button/Button'
import { LetterTypeItemType } from '@/components/modal/modal-content/GenerateApprovalDocument'
import Typography from '@/components/typography/Typography'
import { Col, Radio } from 'antd'
import { FC } from 'react'
import './LetterTypeItem.less'

const { Text } = Typography
type Props = {
  it: LetterTypeItemType
  isSelected: boolean
  handleSelectType: (id:string) => void
}
const LetterTypeItem:FC<Props> = ({it, isSelected, handleSelectType}) => {
  return (
    <Col span={8}>
      <Button btnType={'ghost'} block className={`letter-type-item ${isSelected ? 'selected' : undefined}`} onClick={()=>handleSelectType(it?.id)}>
        <Text>{it?.name}</Text>
        <Radio checked={isSelected}/>
      </Button>
    </Col>
  )
}

export default LetterTypeItem
