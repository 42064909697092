import { SvgIcon } from '@/components/icon'
import { Col, Row, Space } from 'antd'
import React, { useState } from "react";
import '@/helpers/pdfWorker'
import { Document, Page } from 'react-pdf';
import './pdf-viewer.less'
import Typography from '@/components/typography/Typography';
import Button from '@/components/button/Button';

const { Text } = Typography
const PdfViewer: React.FC<{ url: string }> = ({ url }) => {
  const [numPages, setNumPages] = useState<number>();
  const [pageNumber, setPageNumber] = useState<number>(1);
  console.log(url)

  function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
    setNumPages(numPages);
  }



  return (
    <Row className={'viewer-container'}>
      <Col span={24}>
        <Document file={url} onLoadSuccess={onDocumentLoadSuccess}>
          {/*{Array.from({ length: pageNumber }, (_, index) => (*/}
          {/*  <Page*/}
          {/*    key={index + 1}*/}
          {/*    pageNumber={index + 1}*/}
          {/*    renderTextLayer={false}*/}
          {/*    renderAnnotationLayer={false}*/}
          {/*  />*/}
          {/*))}*/}
          <Page
            pageNumber={pageNumber}
            renderTextLayer={false}
            renderAnnotationLayer={false}
          />
        </Document>

      </Col>
      <Col span={24}>
        <Row justify={'center'}>
          <Col>
            <Text>{`Page ${pageNumber} of ${numPages}`}</Text>
          </Col>
        </Row>
        {numPages && numPages > 1 && (
          <Row justify={'center'}>
            <Button onClick={() => setPageNumber(pageNumber - 1)} className={'viewer-button left'} btnType={'icon'} icon={<SvgIcon type={'arrow-left'} /> } bordered size={'small'} />
            <Button onClick={() => setPageNumber(pageNumber + 1)} className={'viewer-button right'} btnType={'icon'} icon={<SvgIcon type={'arrow-right'} /> } bordered size={'small'} />
          </Row>
        )}

      </Col>
    </Row>
  );
};
export default PdfViewer