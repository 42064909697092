import FormItemDivider from '@/components/form-item/FormItemDivider'
import { globalFetchPolicy } from '@/components/layout/DashboardLayout'
import TitleTag from '@/components/title-tag/TitleTag'
import { useArchiveProjectMutation, useProjectQuery, useRestoreProjectMutation } from '@/graphql'
import { message, Row, Skeleton } from 'antd'
import { FC } from 'react'
import BlackNotification from '../../blackNotification/BlackNotification'
import Button from '../../button/Button'
import { useGeneralContext } from '../../context/GeneralContext'
import Typography from '../../typography/Typography'

const { Paragraph } = Typography
const RestoreProject: FC = () => {
  const { setIsModalOpen, currentId } = useGeneralContext()
  const [restoreProject, { loading }] = useRestoreProjectMutation()
  const { data, loading: projectDataLoading } = useProjectQuery({
    fetchPolicy: globalFetchPolicy,
    variables: { id: currentId },
  })
  const project = data?.project?.data as ProjectEntity

  const onCancel = () => {
    setIsModalOpen(false)
  }
  const onConfirm = async () => {
    await restoreProject({
      fetchPolicy: globalFetchPolicy,
      variables: {
        input: {
          projectId: currentId,
        },
      },
      onError: (error) => {
        message.error(error.message)
      },
      onCompleted: () => {
        BlackNotification('Project restored successfully. All applications inside selected project restored successfully.')
        setIsModalOpen(false)
      },
      refetchQueries: 'active',
    })
  }
  return projectDataLoading ? (
    <>
      <Skeleton active={true} paragraph={{ rows: 1, width: '100%' }} />
      <Skeleton active={true} paragraph={{ rows: 5 }} />
    </>
  ) : (
    <>
      <FormItemDivider title={'Restore project'} />
      <Paragraph style={{ marginTop: 32 }} size={'sm'}>
        {`You are going to restore the project with title ${project?.attributes?.title}.`}
      </Paragraph>

      <Paragraph size={'sm'}>
        {'The following applications will be restored automatically:'}
      </Paragraph>
      <Row>
        {project?.attributes?.applications?.data?.map(it => (
          <TitleTag key={it?.id} text={it?.attributes?.title} />
        ))}
      </Row>
      <FormItemDivider />
      <Row justify={'end'}>
        <Button text={'Cancel'} btnType={'text'} onClick={onCancel} loading={loading} />
        <Button text={'Restore project'} btnType={'primary'} onClick={onConfirm} loading={loading} />
      </Row>
    </>
  )
}
export default RestoreProject
