import { Feature, Polygon } from 'geojson'
import { FC } from 'react'
import { Layer, Source } from 'react-map-gl'


type Props = {
  feature: Feature<Polygon>
  index: number | string
  color?: string
  fillColor?: string
}
const PolygonRender: FC<Props> = ({ feature, index, color = 'red', fillColor =  '#e66a9b'}) => {
  return (
    <>
      <Source
        key={index}
        id={`project-${index}`}
        type={'geojson'}
        data={feature}
      >
        <Layer
          id={`project-${index}`}
          type={'line'}
          paint={{
            'line-width': 2,
            'line-color': color,
          }}
        />
      </Source>
      <Source
        key={`fill-${index}`}
        id={`fill-${index}`}
        type={'geojson'}
        data={feature}
      >
        <Layer
          id={`fill-${index}`}
          type={'fill'}
          paint={{
            'fill-color': fillColor,
            'fill-opacity': 0.2,
          }}
        />
      </Source>
    </>
  )
}

export default PolygonRender
