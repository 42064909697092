import Button from '@/components/button/Button'
import { scrollToSection } from '@/helpers/scrollToSection'
import { useBreakpoint } from '@/hooks/useBreakpoint'
import { websiteRoutes } from '@/router/routes'
import { Col, Row } from 'antd'
import { FC } from "react"
import { useNavigate, useParams } from 'react-router'
import sectionBg from '../create-application/split-application/images/split-section-bg.png'
import Typography from '@/components/typography/Typography'
import notFoundImg from './image/not-found.png'

import './NotFound.less'

const {Title, Paragraph} = Typography

export interface NotFoundProps {
  mode: 'page' | 'org';
}

const NotFound: FC<NotFoundProps> = ({mode}) => {

  const navigate = useNavigate()
  const  params  = useParams();
  const org = params.org;
  const { website } = websiteRoutes

  const onGetAssistance = async () => {
    navigate(`/easetech${website.home}#phone-section`);
    await scrollToSection("phone-section");
  };

  const onGoHome = ()=>{
    navigate(`/${org}${website.home}`);
  }

  const onGoBack = ()=>{
    navigate(-1);
  }

  const {xxl, xl, lg} = useBreakpoint()

  return (
    <div className={'not-found-wrapper'}>
      <div className={'not-found-bg'}>
        <img src={sectionBg} alt={''} />
      </div>

      <Row justify={'center'} gutter={[0, 16]}>
        <Col
          span={11}
          xxl={11} xl={12} lg={14} md={18} sm={22} xs={22}
        >
          <Col className={'not-found-text-content'} span={24}>
            <Row justify={'center'} style={{ paddingBottom: 20 }}>
              <Col span={24}>
                <div className={'not-found-img-wrapper'}>
                  <img src={notFoundImg} alt={'illustration for error page'} />
                </div>
              </Col>
            </Row>

            {mode === 'org' ? (
              <>
                <Title level={3} centered color={'white'} style={{ paddingBottom: 20 }}>
                  {'Organization Not Found'}
                </Title>
                <Paragraph centered color={'website-secondary'}>
                  {'Please check the URL for accuracy'}
                </Paragraph>
                <Paragraph centered color={'website-secondary'}>
                  {'If you need assistance, please click the button below'}
                </Paragraph>
                <Row justify={'center'} style={{ width: '100%', paddingTop: 20 }}>
                  <Button
                    btnType={'primary'}
                    block={!(xxl || xl || lg)}
                    text={'Get easetech assistance'}
                    size={'large'}
                    onClick={onGetAssistance}
                  />
                </Row>
              </>
            ) : (
              <>
                <Title level={3} centered color={'white'} style={{ paddingBottom: 20 }}>
                  {'Page Not Found'}
                </Title>
                <Paragraph centered color={'website-secondary'}>
                  {'The page you are looking for does not exist.'}
                </Paragraph>

                <Row justify={'center'} style={{paddingTop: 20}}>
                  <Col
                    span={16}
                    xxl={16} xl={16} lg={16} md={18} sm={24} xs={24}
                  >

                    <Row gutter={[8, 8]}>
                      <Col
                        span={12}
                        xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}
                      >
                        <Button
                          btnType={'primary'}
                          block
                          text={'Home'}
                          size={'large'}
                          onClick={onGoHome}
                        />
                      </Col>
                      <Col
                        span={12}
                        xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}
                      >
                        <Button
                          btnType={'ghost'}
                          block
                          text={'Previous Page'}
                          size={'large'}
                          onClick={onGoBack}
                          textColor={''}
                          className={'not-found-button'}
                        />
                      </Col>
                    </Row>

                  </Col>
                </Row>

              </>
            )}
          </Col>
        </Col>
      </Row>
    </div>
  )
}

export { NotFound as default }
