import { TOOLTIP_MESSAGES } from '@/components/app'
import { useGeneralContext } from '@/components/context/GeneralContext'
import { getUploadProps } from '@/components/google-map/helpers/uploadProps'
import { getAuthToken } from '@/helpers/auth'
import { websiteRoutes } from '@/router/routes'
import { UploadedFileAWSResponse } from '@/types/upload'
import { Col, Row, Tooltip, Upload, UploadProps } from 'antd'
import React, { FC } from 'react'
import { useLocation, useNavigate } from 'react-router'
import { Link, useSearchParams } from 'react-router-dom'
import { useLocalStorage } from 'react-use'
import Button from '../button/Button'
import { SvgIcon } from '../icon'
import './AddGenerateDocumentBtnBlock.less'

type Props = {
  onUpload: (info: any) => void
  fileList?: UploadedFileAWSResponse[]
  isExpiryDateDefined?: boolean
  isTemplatesAvailable?: boolean
}
const AddGenerateDocumentBtnBlock: FC<Props> = ({ onUpload, isExpiryDateDefined = false, isTemplatesAvailable = false }) => {
  const { setIsDrawerOpen, setDrawerType } = useGeneralContext()
  const [searchParams] = useSearchParams()
  const uuid = searchParams.get('id') as string
  const { pathname } = useLocation()
  const navigate = useNavigate()

  const uploadProps = getUploadProps('', true, false)
  console.log('is template available: ===> ',isTemplatesAvailable)

  // const props: UploadProps = {
  //   name: 'files',
  //   action: `${(import.meta.env.WEBSITE_API_URL ?? '').replace('/graphql', '')}/api/upload`,
  //   listType: 'picture',
  //   headers: { Authorization: `Bearer ${getAuthToken()}` },
  //   multiple: true,
  //   showUploadList: false,
  //   style: { width: '100%', border: '1px solid red'},
  //   className: 'approval-form-upload'
  // }
  const onGenerateClick = () => {
    setDrawerType('generate-approval-document')
    setIsDrawerOpen(true)
  }
  return (
    <Row gutter={8}>
      <Col span={12}>
        <Tooltip title={'Upload approval documents'}>
          <Upload {...uploadProps} className={'approval-form-upload'} onChange={info => onUpload(info)} >
            <Button
              size={'small'}
              text={'Add document'}
              icon={<SvgIcon type={'plus'} />}
              btnType={'ghost'}
              style={{ width: '100%' }}
              block={true}
            />
          </Upload>

        </Tooltip>
      </Col>
      <Col span={12}>
        <Tooltip title={!isExpiryDateDefined ? 'Define the expiry date of approval in the date-picker above.' : !isTemplatesAvailable ? 'No templates available for the current organization' : 'Generate a PDF approval document based on your organization\'s custom template'}>
          <div>
            <Button
              text={'Generate document'}
              icon={<SvgIcon type={'doc-sheet'} />}
              btnType={'ghost'}
              size={'small'}
              onClick={onGenerateClick}
              style={{ width: '100%' }}
              disabled={!isExpiryDateDefined || !isTemplatesAvailable}
            />
          </div>
        </Tooltip>
      </Col>
    </Row>
  )
}

export default AddGenerateDocumentBtnBlock
