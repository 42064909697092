import FormItemDivider from '@/components/form-item/FormItemDivider'
import { globalFetchPolicy } from '@/components/layout/DashboardLayout'
import { useAreaQuery, useDeleteAreaMutation } from '@/graphql'
import { Row, Skeleton } from 'antd'
import { FC } from 'react'
import BlackNotification from '../../blackNotification/BlackNotification'
import Button from '../../button/Button'
import { useGeneralContext } from '../../context/GeneralContext'
import Typography from '../../typography/Typography'
import { appEvents } from '@/helpers/events'

const { Paragraph } = Typography
const DeleteGeometryItem: FC = () => {
  const { setIsModalOpen, currentId } = useGeneralContext()

  const {data, loading: dataLoading} = useAreaQuery({
    fetchPolicy: globalFetchPolicy,
    variables: { id: currentId },
  })
  const currentArea = data?.area?.data as AreaEntity

  const [deleteArea, { loading }] = useDeleteAreaMutation()

  const onCancel = () => {
    setIsModalOpen(false)
  }

  const onDelete = async () => {
    await deleteArea({
      variables: {
        id: currentId,
      },
      fetchPolicy: globalFetchPolicy,
      onError: (error) => {
        BlackNotification(`Error deleting geometry item. ${error.message}`)
      },
      onCompleted: (result) => {
        appEvents.emit('areasUpdated');
        const removedItem = result?.deleteArea?.data?.attributes?.name
        BlackNotification(`Geometry item with the name "${removedItem}" deleted successfully`)
        setIsModalOpen(false)
      },
      refetchQueries: 'all',
    })
  }
  const itemName = currentArea?.attributes?.name || ''
  return dataLoading ? <Skeleton active paragraph={{rows: 4}} /> : (
    <>
      <FormItemDivider title={'Deleting geometry item'} />

      <Paragraph style={{marginBottom: 8}}>{`You are going to delete current geometry item with the name: `}</Paragraph>
      <Paragraph size={'md'} weight={'w700'}>{itemName}</Paragraph>
      <Row style={{ paddingTop: 30 }} justify={'end'}>
        <Button text={'Cancel'} btnType={'text'} onClick={onCancel} />
        <Button text={'Delete'} btnType={'primary'} onClick={onDelete} loading={loading} />
      </Row>
    </>
  )
}
export default DeleteGeometryItem
