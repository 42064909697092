import { globalFetchPolicy } from '@/components/layout/DashboardLayout'
import { useOrganizationsIdsQuery } from '@/graphql'
import NotFound from '@/pages/not-found/NotFound'
import WebsiteLandingLoader from '@/pages/website-landing/components/website-landing-loader/WebsiteLandingLoader'
import { EASETECH_COMPANY } from '@/utilities/global-variables'
import { FC, ReactNode } from 'react'
import { useLocation } from 'react-router'
import { useParams } from 'react-router-dom'

type CheckOrgExistenceProps = {
  children: ReactNode;
}

const CheckOrgExistence: FC<CheckOrgExistenceProps> = ({ children }) => {
  const { org } = useParams()
  const location = useLocation()
  const { data, loading } = useOrganizationsIdsQuery({
    fetchPolicy: globalFetchPolicy,
    variables: {
      filter: { slug: { eqi: org } },
    },
  })

  const currentPageName = location.pathname.split('/')[2]

  if (loading) {
    return <WebsiteLandingLoader />
  }

  if (!data?.organizations?.data?.length) {
    return <NotFound mode={'org'} />
  }

  if (org !== EASETECH_COMPANY && currentPageName !== 'home' && (currentPageName && currentPageName?.length)) {
    return <NotFound mode={'page'} />
  }

  return <>{children}</>
}

export default CheckOrgExistence