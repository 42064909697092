import { App } from '@/components/app'
import { createRoot } from 'react-dom/client'
import './styles/check-box.less'
import './styles/form-elements.less'
import './styles/global.less'
import './styles/modal.less'
import './styles/drawer.less'
import './styles/popovers.less'
import './styles/tables.less'
import './styles/tabs.less'
import './styles/sider-menu.less'
import './styles/mixins/scroll-bar.less'


const rootElement = document.querySelector('#root')
if (!rootElement) throw new Error('Failed to find the root element')

createRoot(rootElement).render(<App />)
