import FormItemDivider from '@/components/form-item/FormItemDivider'
import { globalFetchPolicy } from '@/components/layout/DashboardLayout'
import { useUpdateApplicationMutation } from '@/graphql'
import useApplicationByUuid from '@/hooks/useApplicationByUuid'
import { Checkbox, message, Row, Skeleton } from 'antd'
import { CheckboxChangeEvent } from 'antd/es/checkbox'
import { FC, useState } from 'react'
import Button from '../../button/Button'
import { useGeneralContext } from '../../context/GeneralContext'
import Typography from '../../typography/Typography'

const { Text, Paragraph } = Typography

const ApplicationInitialInfo: FC = () => {
  const { setIsModalOpen, setClosedInfoPopupAppIds, closedInfoPopupAppIds } = useGeneralContext()
  const [updateApplication, { loading: applicationIsUpdating }] = useUpdateApplicationMutation()

  const [isChecked, setIsChecked] = useState(false)
  const { data, loading } = useApplicationByUuid()
  const application = data?.applications?.data?.[0] as ApplicationEntity

  const onConfirm = async () => {
    if (application?.id && isChecked) {
      await updateApplication({
        fetchPolicy: globalFetchPolicy,
        variables: {
          id: application?.id,
          data: {
            isInfoPopupShow: false,
          },
        },
        onError: (error) => {
          message.error(error.message)
        },
        onCompleted: () => {
          setIsModalOpen(false)
        },
        refetchQueries: 'active',
      })

    } else {
      setIsModalOpen(false)
      application?.id && setClosedInfoPopupAppIds([...closedInfoPopupAppIds, application.id])
    }
  }
  const onCheckboxChange = (e: CheckboxChangeEvent) => {
    setIsChecked(e.target.checked)
  }
  return loading ? (
    <>
      <Skeleton active={true} paragraph={{ rows: 1, width: '100%' }} />
      <Skeleton active={true} paragraph={{ rows: 5 }} />
    </>
  ) : (
    <>
      <FormItemDivider
        marginBottom={24}
        title={'Application initial info'}
        subTitle={`Here by are some basic steps you need to process your application`}
      />

      <Paragraph>{'1. Review Application Details – Verify your application details and project location in the corresponding tabs.'}</Paragraph>
      <Paragraph>{'2. Upload Required Documents – Submit all necessary documents listed under the "Documents" tab.'}</Paragraph>
      <Paragraph>{'3. Process Payment (if applicable) – Complete the payment if required and upload the proof of payment (POP) under the "Payments" tab'}</Paragraph>
      <Paragraph>{'4. Confirm Application Status – Ensure that the application status is "Ready for Submission."'}</Paragraph>
      <Paragraph>{'5. Submit Your Application – Click the "Submit Application" button to send your application to the assigned agent.'}</Paragraph>
      <Paragraph>{'6. Track Approvals – Collect and monitor approvals under the "Approval" tab.'}</Paragraph>
      <FormItemDivider />

      <Row justify={'space-between'} align={'middle'}>
        <Checkbox onChange={onCheckboxChange}>
          <Text>{'Do not show again'}</Text>
        </Checkbox>
        <Button text={'Close'} btnType={'primary'} onClick={onConfirm} loading={loading || applicationIsUpdating} />
      </Row>
    </>
  )
}
export default ApplicationInitialInfo
