import Typography from '@/components/typography/Typography'
import { Col, Row } from 'antd'
import { FC, PropsWithChildren } from 'react'

const { Text } = Typography
type Props = {
  name: string
}
const UniversalInfoItem: FC<PropsWithChildren<Props>> = ({ name, children }) => {
  return (
    <Row style={{paddingBottom: 8}}>
      <Col span={6}>
        <Text color={'light-gray'}>{name}</Text> </Col>
      <Col span={18}>
        {children}
      </Col>
    </Row>
  )
}

export default UniversalInfoItem
