import { Feature, LineString } from 'geojson'
import { FC } from 'react'
import { Layer, Source } from 'react-map-gl'


type Props = {
  feature: Feature<LineString>
  index: number | string
}
const LineStringRender: FC<Props> = ({ feature, index }) => {
  return (
    <Source
      key={index}
      id={`project-${index}`}
      type={'geojson'}
      data={feature}
    >
      <Layer
        id={`project-${index}`}
        type={'line'}
        paint={{
          'line-width': 2,
          'line-color': `red`,
        }}
      />
    </Source>
  )
}

export default LineStringRender
