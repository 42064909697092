import { globalFetchPolicy } from '@/components/layout/DashboardLayout'
import { useAppApprovalProgressUpdateMutation, useApplicationLazyQuery, useApprovalQuery, useDeleteApprovalMutation } from '@/graphql'
import { longDash } from '@/helpers/globalVariables'
import { checkIsReadyForApproval } from '@/helpers/isReadyForApprovalChecker'
import { message, Row } from 'antd'
import { FC } from 'react'
import BlackNotification from '../../blackNotification/BlackNotification'
import Button from '../../button/Button'
import { useGeneralContext } from '../../context/GeneralContext'
import Typography from '../../typography/Typography'

const { Title, Paragraph, Text } = Typography
const DeleteApprovalItem: FC = () => {
  const { setIsModalOpen, currentApplicationId, currentApprovalItemId } = useGeneralContext()
  const [fetchApplication, { loading: applicationDataLoading }] = useApplicationLazyQuery()
  const [updateProgress, { loading: progressLoading }] = useAppApprovalProgressUpdateMutation()
  const [deleteApproval, { loading }] = useDeleteApprovalMutation()
  const onCancel = () => {
    setIsModalOpen(false)
  }
  const { data, loading: approvalLoading } = useApprovalQuery({
    fetchPolicy: globalFetchPolicy,
    variables: {
      id: currentApprovalItemId,
    },
  })
  const currentApproval = data?.approval?.data as ApprovalEntity
  const onDelete = async () => {
    await deleteApproval({
      variables: {
        id: currentApprovalItemId,
      },
      onError: (error) => {
        message.error(error.message)
      },
      onCompleted: () => {
        BlackNotification('Approval item deleted successfully')
      },
    })
    const currentApplicationData = await fetchApplication({
      fetchPolicy: globalFetchPolicy,
      variables: {
        id: currentApplicationId,
      },
      onError: (e)=>message.error(e.message)
    })
    const currentApp = currentApplicationData?.data?.application?.data as ApplicationEntity
    await updateProgress({
      variables: { input: { appId: currentApplicationId } },
      onError: (e) => message.error(e.message),
      refetchQueries: 'active',
    })
    const checkResult = currentApp?.attributes?.approvals?.data && checkIsReadyForApproval(currentApp?.attributes?.approvals?.data)
    if (checkResult) {
      BlackNotification(
        'Application ready for approval. Press \'Approve application\' button to approve the application. You will not be allowed to undo this action.',
      )
    }
    setIsModalOpen(false)
  }
  return (
    <>
      <Title level={3}>
        {'Delete Approval Item'}
      </Title>
      <Paragraph style={{ margin: '32px 0 0', padding: 0 }} size={'sm'} weight={'w400'}>
        {`You are going to delete approval item for the company: `}
      </Paragraph>
      <Text weight={'w700'} size={'sm'}>{`${currentApproval?.attributes?.company?.data?.attributes?.name ?? longDash}`}</Text>
      <Row style={{ paddingTop: 30 }} justify={'end'}>
        <Button text={'Cancel'} btnType={'text'} onClick={onCancel} />
        <Button text={'Delete'} btnType={'primary'} onClick={onDelete} loading={loading || approvalLoading || progressLoading || applicationDataLoading} />
      </Row>
    </>
  )
}
export default DeleteApprovalItem
