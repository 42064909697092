import { defaultValues, GeneralContext } from '@/components/context/GeneralContext'
import { WebsiteLoader } from '@/components/layout/WebsiteLoader'
import Drawer from '@/components/modal/Drawer'
import DrawerContent from '@/components/modal/DrawerContent'
import { Modal } from '@/components/modal/Modal'
import ModalContent from '@/components/modal/ModalContent'
import { getAuthToken } from '@/helpers/auth'
import { Layout } from 'antd'
import { FC, Suspense, useState } from 'react'
import { Outlet } from 'react-router'
import { Navigate } from 'react-router-dom'
import './index.less'
import AppMobilePlaceholder from '../app-mobile-placeholder/AppMobilePlaceholder'

const PlatformLayout: FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(defaultValues.isModalOpen)
  const [isDrawerOpen, setIsDrawerOpen] = useState(defaultValues.isDrawerOpen)
  const [createAppCurrentStep, setCreateAppCurrentStep] = useState(defaultValues.createAppCurrentStep)
  const [modalType, setModalType] = useState(defaultValues.modalType)
  const [drawerType, setDrawerType] = useState(defaultValues.drawerType)
  const [currentApplicationId, setCurrentApplicationId] = useState(defaultValues.currentApplicationId)
  const [currentCompanyId, setCurrentCompanyId] = useState(defaultValues.currentCompanyId)
  const [currentId, setCurrentId] = useState(defaultValues.currentId)
  const [currentApprovalItemId, setCurrentApprovalItemId] = useState(defaultValues.currentApprovalItemId)
  const [locationConfirmationFormStep, setLocationConfirmationFormStep] = useState(
    defaultValues.locationConfirmationFormStep,
  )
  const [triggerRefetch, setTriggerRefetch] = useState<boolean | null>(null)
  const [modalSettings, setModalSettings] = useState(defaultValues.modalSettings)
  const [documentInfo, setDocumentInfo] = useState(defaultValues.documentInfo)
  const [sidebarMode, setSidebarMode] = useState(defaultValues.sidebarMode)
  const [signUpMode, setSignUpMode] = useState(defaultValues.signUpMode)
  const [isPaymentCreated, setIsPaymentCreated] = useState(defaultValues.isPaymentCreated)
  const [isReadyForReview, setIsReadyForReview] = useState(defaultValues.isReadyForReview)
  const [userLevelOfApproval, setUserLevelOfApproval] = useState(defaultValues.userLevelOfApproval)
  const [modalWidth, setModalWidth] = useState(defaultValues.modalWidth)
  const [drawerWidth, setDrawerWidth] = useState(defaultValues.modalWidth)
  const [modalTopOffset, setModalTopOffset] = useState(defaultValues.modalTopOffset)
  const [mode, setMode] = useState(defaultValues.mode)
  const [pageViewMode, setPageViewMode] = useState(defaultValues.pageViewMode)
  const [tabActiveKey, setTabActiveKey] = useState(defaultValues.tabActiveKey)
  const [approvalCategory, setApprovalCategory] = useState(defaultValues.approvalCategory)
  const [isLoading, setIsLoading] = useState(defaultValues.isLoading)
  const [closedInfoPopupAppIds, setClosedInfoPopupAppIds] = useState(defaultValues.closedInfoPopupAppIds)
  const [url, setUrl] = useState(defaultValues.url)
  const [imageId, setImageId] = useState(defaultValues.imageId)
  const [approvalLetterInfo, setApprovalLetterInfo] = useState(defaultValues.approvalLetterInfo)
  const [expiryDate, setExpiryDate] = useState(defaultValues.expiryDate)
  const [assignModalMode, setAssignModalMode] = useState(defaultValues.assignModalMode)

  if (getAuthToken()) {

    const isDesktopDevice = ()=> {
      return window.matchMedia('(min-width: 1024px)').matches
    }

    if (!isDesktopDevice()) {
      return <AppMobilePlaceholder />
    }

    return (
      <GeneralContext.Provider
        value={{
          assignModalMode,
          setAssignModalMode,
          expiryDate,
          setExpiryDate,
          approvalLetterInfo,
          setApprovalLetterInfo,
          url,
          setUrl,
          imageId,
          setImageId,
          closedInfoPopupAppIds,
          setClosedInfoPopupAppIds,
          isLoading,
          setIsLoading,
          approvalCategory,
          setApprovalCategory,
          drawerType,
          setDrawerType,
          drawerWidth,
          setDrawerWidth,
          isDrawerOpen,
          setIsDrawerOpen,
          sidebarMode,
          modalType,
          setModalType,
          isModalOpen,
          setIsModalOpen,
          locationConfirmationFormStep,
          setLocationConfirmationFormStep,
          createAppCurrentStep,
          setCreateAppCurrentStep,
          setSidebarMode,
          triggerRefetch,
          setTriggerRefetch,
          modalSettings,
          setModalSettings,
          currentApplicationId,
          setCurrentApplicationId,
          currentCompanyId,
          setCurrentCompanyId,
          documentInfo,
          setDocumentInfo,
          signUpMode,
          setSignUpMode,
          currentId,
          setCurrentId,
          currentApprovalItemId,
          setCurrentApprovalItemId,
          isPaymentCreated,
          setIsPaymentCreated,
          isReadyForReview,
          setIsReadyForReview,
          userLevelOfApproval,
          setUserLevelOfApproval,
          modalWidth,
          setModalWidth,
          modalTopOffset,
          setModalTopOffset,
          mode,
          setMode,
          pageViewMode,
          setPageViewMode,
          tabActiveKey,
          setTabActiveKey,
        }}
      >
        <Layout>
          <Suspense fallback={<WebsiteLoader />}>
            <Outlet />
          </Suspense>
        </Layout>
        <Modal open={isModalOpen} width={modalWidth ? modalWidth : 450} topOffset={modalTopOffset}>
          <ModalContent />
        </Modal>
        <Drawer open={isDrawerOpen} width={drawerWidth ? drawerWidth : 650} >
          <DrawerContent />
        </Drawer>
      </GeneralContext.Provider>
    )
  } else {
    return <Navigate to='/' replace />
  }
}
export { PlatformLayout }
