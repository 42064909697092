import { Button as WrappedBtn } from 'antd'
import classnames from 'classnames'
import { CSSProperties, ComponentProps, FC } from 'react'
import './Button.less'

type ButtonProps = {
  bordered?: boolean
  btnType?: 'primary' | 'ghost' | 'text' | 'icon' | 'extra-wide' | 'table-action' | 'hero' | 'block' | 'pure-text'
  btnSize?: 'block'
  link?: string
  mirrored?: boolean
  text?: string
  textColor?: string
  style?: CSSProperties
  isIconWhite?: boolean
  isTransparent?: boolean
}
type BtnProps = ComponentProps<typeof WrappedBtn>;

const Button: FC<BtnProps & ButtonProps> = ({
                                              mirrored = false,
                                              textColor,
                                              btnType = 'primary',
                                              icon,
                                              link = '#',
                                              text,
                                              bordered,
                                              children,
                                              btnSize,
  isIconWhite= false,
  isTransparent= false,
                                              ...props
                                            }) => {
  const btnClasses = classnames({
    'gw-button': true,
    'primary': btnType === 'primary',
    'ghost': btnType === 'ghost',
    'text': btnType === 'text',
    'icon': btnType === 'icon',
    'table-action': btnType === 'table-action',
    'pure-text': btnType === 'pure-text',
    'mirrored': mirrored,
    'bordered': bordered === true,
    'extra-wide primary': btnType === 'extra-wide',
    'hero-type': btnType === 'hero',
    'block': btnSize === 'block',
    'white-icon': isIconWhite,
    'transparent': isTransparent,
    [props?.className as string]: true,
  })

  return (
    <WrappedBtn icon={icon} {...props} className={btnClasses}>
      {text ? text : children ? children : null}
    </WrappedBtn>
  )
}

export default Button
