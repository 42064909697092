import { useAuth } from '@/components/context/AuthProvider'
import CustomUpload from '@/components/custom-upload/CustomUpload'
import EmailMessage from '@/components/email-message/EamilMessage'
import EmailRenderer from '@/components/email-renderer/EmailRenderer'
import FormItem from '@/components/form-item/FormItem'
import FormItemDivider from '@/components/form-item/FormItemDivider'
import { SvgIcon } from '@/components/icon'
import InfoTextItem from '@/components/info-text-item/InfoTextItem'
import { globalFetchPolicy } from '@/components/layout/DashboardLayout'
import ApprovalLetterPreview from '@/components/modal/modal-content/ApprovalLetterPreview'
import TablePlaceholder from '@/components/table-placeholder/TablePlaceholder'
import {
  useApprovalLetterMutation,
  useEmailMessageLazyQuery,
  useLetterTemplateLazyQuery,
  useOrganizationQuery,
  useSendApprovalItemEmailMutation,
} from '@/graphql'
import useApplicationByUuid from '@/hooks/useApplicationByUuid'
import useEmailHistory from '@/hooks/useEmailHistory'
import { DefaultFileListItem } from '@/types/upload'
import { getDateAndTime } from '@/utilities/utilities'
import { Col, Form, message, Modal, Popover, Radio, Row, Skeleton, Space, Tooltip } from 'antd'
import { useForm } from 'antd/es/form/Form'
import classnames from 'classnames'
import { useDebouncedState } from 'emoji-picker-react/dist/hooks/useDebouncedState'
import moment from 'moment'
import { BaseSyntheticEvent, FC, SyntheticEvent, useEffect, useState } from 'react'
import ReactMarkdown from 'react-markdown'
import Button from '../../button/Button'
import { ApprovalLetterInfo, useGeneralContext } from '../../context/GeneralContext'
import Typography from '../../typography/Typography'
import TemplateItem from './components/TemplateItem'
import LetterTypeItem from './components/LetterTypeItem'
import LetterInfoItem from './components/LetterInfoItem'

type FormValues = {
  text: string
}
export type LetterTypeItemType = {
  id: string
  name: string
}

const letterTypes: LetterTypeItemType[] = [
  {
    id: '1',
    name: 'Affected',
  },
  {
    id: '2',
    name: 'Not Affected',
  },
  {
    id: '3',
    name: 'Rejected',
  },
]
type LetterSubtitle = 'affected/approved' | 'not affected/approved' | 'rejected'

const { Title, Paragraph, Text } = Typography
const GenerateApprovalDocument: FC = () => {
  const { user } = useAuth()
  const [form] = useForm()

  const [open, setOpen] = useState(false)
  const [selectedTemplate, setSelectedTemplate] = useState<Maybe<LetterTemplateEntity>>(null)
  const [selectedTypeId, setSelectedTypeId] = useState('1')
  const [staticText, setStaticText] = useState<Maybe<string>>('')
  const [text, setText] = useState(""); // Stores the latest input value
  const [isPreviewOpened, setIsPreviewOpened] = useState(false)
  const [response, setResponse] = useState<ApprovalLetterInfo | null>(null)
  const [letterSubtitle, setLetterSubtitle] = useState<LetterSubtitle>('affected/approved')

  const { currentId, currentApprovalItemId, setIsDrawerOpen, setUrl, setImageId, expiryDate } = useGeneralContext()
  const { data, loading: appDataLoading } = useApplicationByUuid()
  const { data: orgData, loading: organizationLoading } = useOrganizationQuery({
    variables: user?.organization?.data?.id ? {
      id: user?.organization?.data?.id,
    } : undefined,
  })
  const [fetchTemplate, { loading: fetchingTemplate }] = useLetterTemplateLazyQuery()
  const [generateApprovalLetter, {loading: approvalLetterLoading}] = useApprovalLetterMutation()


  const application = data?.applications?.data?.[0] as ApplicationEntity
  const organization = orgData?.organization?.data as OrganizationEntity
  const templates = organization?.attributes?.letterTemplates?.data as LetterTemplateEntity[]

  useEffect(() => {
    const firstTemplate = organization?.attributes?.letterTemplates?.data?.[0]
    setSelectedTemplate(firstTemplate)
    setStaticText(firstTemplate?.attributes?.affectedText)
  }, [organization?.attributes?.letterTemplates?.data])

  const handleSelectType = (id: string) => {
    setSelectedTypeId(id)
    switch (id) {
      case '1' : setStaticText(selectedTemplate?.attributes?.affectedText)
        setLetterSubtitle('affected/approved')
        break
      case '2' : setStaticText(selectedTemplate?.attributes?.notAffectedText)
        setLetterSubtitle('not affected/approved')
        break
      case '3' : setStaticText(selectedTemplate?.attributes?.rejectedText)
        setLetterSubtitle('rejected')
        break
      default: setStaticText(selectedTemplate?.attributes?.affectedText)
    }
  }

  const handleTemplateSelect = async (id: string) => {
    await fetchTemplate({
      fetchPolicy: globalFetchPolicy,
      variables: {
        id,
      },
      onCompleted: (result) => {
        setSelectedTemplate(result?.letterTemplate?.data as LetterTemplateEntity)
        switch (selectedTypeId) {
          case '1' : setStaticText(result?.letterTemplate?.data?.attributes?.affectedText)
            break
          case '2' : setStaticText(result?.letterTemplate?.data?.attributes?.notAffectedText)
            break
          case '3' : setStaticText(result?.letterTemplate?.data?.attributes?.rejectedText)
            break
          default: setStaticText(result?.letterTemplate?.data?.attributes?.affectedText)
        }

      },
      onError: (error) => {
        message.error(error.message)
      },
    })
  }

  const onGenerateApprovalLetter = async() => {

    const text = form?.getFieldValue('text')

    application?.id && selectedTemplate?.id && await generateApprovalLetter({
      variables: {
        input: {
          appId: application?.id,
          approvalItemId: currentApprovalItemId,
          templateId: selectedTemplate?.id,
          templateType: selectedTypeId,
          customText: text,
          expiryDate: moment(expiryDate).format("DD-MMM, YYYY")
        }
      },
      onCompleted: (result)=>{
        setImageId(result?.approvalTemplateLetter?.id || null)
        setUrl(result?.approvalTemplateLetter?.url || null)
        setResponse({url: result?.approvalTemplateLetter?.url || '', id: result?.approvalTemplateLetter?.id || '', name: result?.approvalTemplateLetter?.name || ''})
        setIsPreviewOpened(true)
      }
    })
  }

const onModalClose = () => {
  setIsPreviewOpened(false)
}
const handlePreviewClose = () => {
  setIsPreviewOpened(false)
  setIsDrawerOpen(false)
}
  return (
    <>
      <FormItemDivider
        marginTop={12}
        marginBottom={24}
        title={'Generate approval document'}
        subTitle={'Generate approval document based on your organization\'s custom template'}
        // extra={<Button btnType={'table-action'} text={'Refresh'} icon={<SvgIcon type={'refresh'} />} />}
      />

      <div className={'drawer-scroll-container'}>
        {templates?.map(it => (
          <TemplateItem
            key={it?.id}
            it={it}
            isChecked={selectedTemplate?.id === it?.id}
            handleTemplateSelect={handleTemplateSelect}
          />
        ))}
        <Row gutter={8}>
            {letterTypes?.map(it => (
              <LetterTypeItem key={it?.id} it={it} isSelected={selectedTypeId === it?.id} handleSelectType={handleSelectType} />
            ))}
        </Row>
        <FormItemDivider marginTop={24} label={'Template general info:'} />
        <InfoTextItem label={'Letter title'} text={`${selectedTemplate?.attributes?.letterTitle} - ${letterSubtitle}`} />
        <InfoTextItem label={'Signatory Name'} text={selectedTemplate?.attributes?.signatoryName} />
        <InfoTextItem label={'Signatory Position'} text={selectedTemplate?.attributes?.signatoryPosition} />
        <InfoTextItem label={'Expiry date'} text={moment(expiryDate).format('DD-MMM, YYYY')} />

        <FormItemDivider marginTop={24} label={'Template main text'} />
        <Paragraph size={'xs'}>
          {<ReactMarkdown className={'markdown-wrapper'}>{staticText as string}</ReactMarkdown>}
          </Paragraph>

        <FormItemDivider marginTop={24} label={'Template custom text'} />
        <Form layout={'vertical'} form={form}>
          <FormItem required={false} name={'text'} type={'textarea'} label={''} placeholder={'Enter you custom text here...'}/>
        </Form>
      </div>

      <Row style={{ paddingTop: 30 }} justify={'end'}>
        <Space>
        <Button
          icon={<SvgIcon type={'eye'} color={!response ? 'white-stroke' : 'white'}/>}
          btnType={'ghost'}
          text={'Review PDF document'}
          disabled={!response}
          onClick={()=>setIsPreviewOpened(true)}

        />
        <Button
          icon={<SvgIcon type={'pdf-logo'} />}
          text={'Generate PDF'}
          btnType={'primary'}
          loading={approvalLetterLoading}
          onClick={onGenerateApprovalLetter}
        />
        </Space>
      </Row>
      <Modal
        open={isPreviewOpened}
        onCancel={()=>setIsPreviewOpened(false)}
        width={800}
        footer={null}
        closeIcon={<SvgIcon type={"close-modal"} />}
        destroyOnClose={true}
        style={{top: 40}}
      >
        <ApprovalLetterPreview docInfo={response} onClose={onModalClose} handlePreviewClose={handlePreviewClose} />
      </Modal>
    </>
  )
}
export default GenerateApprovalDocument
