import classnames from 'classnames'
import { FC } from "react"
import Typography, { Size } from "../typography/Typography"
import welcomeImage from "./images/welcome.png"
import "./TablePlaceholder.less"
export type ImageSize = 'small' | 'medium' | 'large'
const { Text } = Typography
type Props = {
  title?: string
  text?: string
  titleSize?: Size
  imageSize?: ImageSize
  isImage?: boolean
}
const TablePlaceholder: FC<Props> = ({isImage = true, title, text, imageSize = 'large', titleSize = 'md' }) => {
  const imageClasses = classnames({
    "img-wrapper": true,
    "small-image": imageSize === "small",
    "medium-image": imageSize === "medium",
    "large-image": imageSize === "large",
  })
  return (
    <div className={'welcome-picture-container'}>
      <div className={'welcome-picture-wrapper'}>
        {isImage ? (
        <div className={imageClasses}>
          <img src={welcomeImage ?? 'https://ground-works-cms.ams3.digitaloceanspaces.com/easetech/uploads/welcome_67d8097cf9.webp'} alt={'Error image'} />
        </div>
        ) : null}
        <Text size={titleSize} weight={"w700"} style={{ display: "block", marginBottom: 8 }} centered>
          {title ? title : ""}
        </Text>
        <Text size={'sm'} color={"light-gray"} centered>
          {text ? text : ""}
        </Text>
      </div>
    </div>
  )
}

export default TablePlaceholder
