import FormItemDivider from '@/components/form-item/FormItemDivider'
import { SvgIcon } from '@/components/icon'
import Typography from '@/components/typography/Typography'
import './AppMobilePlaceholder.less'
import WebsiteButton from '@/components/website-button/WebsiteButton'
import { Row } from 'antd'
import { FC } from 'react'
import { useNavigate } from 'react-router'
import Button from '../button/Button'
import bg from './images/mobile-plaseholder-bg.png'

const { Paragraph } = Typography
const AppMobilePlaceholder:FC = () => {
  const navigate = useNavigate()
  const onBack = () => {
    navigate('/easetech/home')
    localStorage.clear()
  }
  return (
    <div className={'mobile-page-container'}>
      <div className={'mob-container-bg'}>
        <img src={bg} alt='bg' />
      </div>
      <div className={'mobile-page-content'}>
        <SvgIcon type={'logo-easetech-white'} className={'mobile-page-logo'} />
        <div className={'mp-text-wrapper'}>
          <Paragraph size={'md'} centered color={'white'}>{'Currently, the Dashboard of our platform is optimized for devices with a screen size of 1024px or larger.'}</Paragraph>
          <FormItemDivider />
          <Paragraph size={'sm'} centered color={'light-gray'}>{'Our team is actively working on a mobile version of the Dashboard, and we plan to launch it as soon as possible. We truly appreciate your understanding and patience during this time.'}</Paragraph>
        </div>
        <Row style={{paddingTop: 40}}>
          <Button onClick={onBack} size={'middle'}>{'Back to Home page'}</Button>
        </Row>
      </div>
    </div>
  )
}

export default AppMobilePlaceholder
