import BlackNotification from '@/components/blackNotification/BlackNotification'
import { globalFetchPolicy } from '@/components/layout/DashboardLayout'
import { Form, Select } from 'antd'
import { FC } from 'react'
import { useGeneralContext, UserLevelOfApproval } from '@/components/context/GeneralContext'
import { SvgIcon } from '@/components/icon'
import { useUpdateUserProfileMutation, useUsersQuery } from '@/graphql'
import './styles.less'

type LevelOfApprovalOption = {
  label: string
  value: EnumUserprofileLevelofapproval
}
export const levelOfApprovalOptions: LevelOfApprovalOption[] = [
  {
    label: 'First',
    value: 'first',
  },
  {
    label: 'Second',
    value: 'second',
  },
  {
    label: 'Third',
    value: 'third',
  },
  {
    label: 'First and only',
    value: 'firstAndOnly',
  },
]
type Props = {
  item: UserProfileEntity
}
const LevelOfApprovalSelect: FC<Props> = ({ item }) => {

  const [updateUserProfile, {loading}] = useUpdateUserProfileMutation()

  // const { data, loading } = useUsersQuery({ variables: { filter: { uuid: { eq: userUuid } } } })
  // const userData = data?.usersPermissionsUsers?.data?.[0]
  // const userId = userData?.id

  const onChange = async (value: EnumUserprofileLevelofapproval) => {
    item?.id && await updateUserProfile({
      fetchPolicy: globalFetchPolicy,
      variables: {
        id: item?.id,
        data: {
          levelOfApproval: value
        }
      },
      onError: (error) => {
        console.log(error.message)
        BlackNotification(error.message)
      },
      onCompleted: () => {
        BlackNotification('Level of approval updated')
      }
    })
  }
  const initialValues = { levelOfApproval: item?.attributes?.levelOfApproval ?? 'firstAndOnly' }
  return (
    <Form layout={'vertical'} initialValues={initialValues}>
      <Form.Item name={'levelOfApproval'} className={'level-form-item'}>
        <Select
          options={levelOfApprovalOptions}
          placeholder={'Select level of approval'}
          className={'level-select'}
          suffixIcon={<SvgIcon type={'down'} />}
          onClick={(e)=> e.stopPropagation()}
          onChange={onChange}
          loading={loading}
        />
      </Form.Item>
    </Form>
  )
}

export default LevelOfApprovalSelect
