import { PLATFORM_SETTINGS } from '@/components/app'
import { useAuth } from '@/components/context/AuthProvider'
import { useGeneralContext } from '@/components/context/GeneralContext'
import UploadedFileDocCardOld from '@/components/doc-card-old/UploadedFileDocCard-old'
import FileImagePlaceholder from '@/components/file-image-placeholder/FileImagePlaceholder'
import { geometryIntersectionChecker } from '@/components/google-map/helpers/geometryIntersectionChecker'
import { getGeoJsonFromUrl } from '@/components/google-map/helpers/getGeoJsonFromUrl'
import { getMapSnapshot } from '@/components/google-map/helpers/getMapSnapshot'
import { SvgIcon } from '@/components/icon'
import ImageFileCard from '@/components/image-file-card/ImageFileCard'
import { globalFetchPolicy } from '@/components/layout/DashboardLayout'
import SelectUserItem from '@/components/select-user-item/SelectUserItem'
import TablePlaceholder from '@/components/table-placeholder/TablePlaceholder'
import {
  useAppApprovalProgressUpdateMutation,
  useApplicationLazyQuery,
  useApprovalMapLetterMutation, useApprovalQuery, useApprovalsLazyQuery,
  useCompanyQuery,
  useCreateNotificationMessageMutation, useOrganizationQuery,
  useSettingsQuery,
  useUpdateApplicationMutation, useUpdateApprovalMutation,
  useUserProfilesQuery,
} from '@/graphql'
import { approvalsProgressCalculator } from '@/helpers/approvalsProgressCalculator'
import useApplicationByUuid from '@/hooks/useApplicationByUuid'
import ApprovalMap from '@/pages/application/content/approval-details/approval-map/ApprovalMap'
import GeometryTable, { GeometryDataItem } from '@/pages/application/content/approval-details/geometry-table/GeometryTable'
import { ImageItem, Response, UploadedFileAWSResponse } from '@/types/upload'
import { checkApproval, ResultType } from '@/utilities/checkApproval'
import './ApprovalForm.less'
import { Checkbox, Col, Form, message, Radio, RadioChangeEvent, Row, Space, Spin, Tooltip } from 'antd'
import { CheckboxChangeEvent } from 'antd/es/checkbox'
import useForm from 'antd/es/form/hooks/useForm'
import { UploadChangeParam } from 'antd/es/upload'
import classnames from 'classnames'
import Lottie, { LottieRefCurrentProps } from 'lottie-react'
import moment from 'moment'
import { FC, Key, MutableRefObject, useEffect, useMemo, useRef, useState } from 'react'
import { MapRef } from 'react-map-gl'
import { useLocalStorage } from 'react-use'
import AddGenerateDocumentBtnBlock from '../../../../components/add-generate-document-btn-block/AddGenerateDocumentBtnBlock'
import BlackNotification from '../../../../components/blackNotification/BlackNotification'
import Button from '../../../../components/button/Button'
import FormItem, { SelectOptionsType } from '../../../../components/form-item/FormItem'
import FormItemDivider from '../../../../components/form-item/FormItemDivider'
import Typography from '../../../../components/typography/Typography'
import checkSuccess from './assets/check.json'

const { Text, Paragraph } = Typography

export const approvalDocumentTypesOptions = [
  {
    label: 'Draft approval',
    value: 'draft',
  },
  {
    label: 'Final approval',
    value: 'final',
  },
]
type DocumentData = {   id: string;   url: string;   name: string; } | null | undefined
export type FormValues = {
  approval: 'approved'[] | undefined
  isAffected: 'affected' | 'notAffected',
  description: string,
  expiryDate: moment.Moment | null,
  feedbackDate: moment.Moment | null,
  docType: InputMaybe<EnumComponentdataapplicationserviceproviderApprovaldocumenttype>
  nextSignatory: string | null | undefined
  title: string | null | undefined
  contacts: string[]
}

export type ApprovalDetailsMode = 'approval' | 'info'
const ApprovalForm: FC<{ mode: ApprovalDetailsMode }> = ({ mode }) => {
  const mapRef = useRef<MapRef>(undefined)
  const lottieRef = useRef<LottieRefCurrentProps>(null)
  const wrapperRef = useRef(null)

  const { user, role } = useAuth()
  const [form] = useForm()
  const [approvalPdfLink, , removePdfLink] = useLocalStorage('approval-details-pdfLink')
  const [, , removeApprovalDetails] = useLocalStorage('approval-details')
  const {
    setIsModalOpen,
    setModalWidth,
    currentApprovalItemId,
    setCurrentId,
    setIsDrawerOpen,
    setDrawerType,
    approvalCategory,
    setApprovalLetterInfo,
    approvalLetterInfo,
    setExpiryDate
  } = useGeneralContext()

  const companyId = user?.userProfile?.data?.attributes?.company?.data?.id || ''

  const [isAffected, setIsAffected] = useState(false)
  const [uploadedFileList, setUploadedFileList] = useState<ImageItem[]>([])
  const [pdfLink, setPdfLink] = useState('')
  const [isChanges, setIsChanges] = useState(false)
  const [fileList, setFileList] = useState<UploadedFileAWSResponse[] | undefined>(undefined)
  const [comment, setComment] = useState<string>('')
  const [isFormDisabled, setIsFormDisabled] = useState<boolean>(false) //TODO: Check if we can approve current application before other colleagues

  const [applicationData, setApplicationData] = useState<ApplicationEntity | null>(null)
  const [siteVisitChecked, setSiteVisitChecked] = useState(false)
  // const [currentApprovalItem, setCurrentApprovalItem] = useState<ApprovalEntity | null | undefined>(null)
  const [uploadedDocuments, setUploadedDocuments] = useState<UploadFileEntity[] | undefined>([])
  const [spUploadedPdfLink, setSpUploadedPdfLink] = useState<string | null>(null)
  const [isShowGeneralGeometry, setIsShowGeneralGeometry] = useState(false)
  const [isShowUtilityGeometry, setIsShowUtilityGeometry] = useState(false)
  const [generalRange, setGeneralRange] = useState(10)
  const [utilityRange, setUtilityRange] = useState(10)
  const [isLoading, setIsLoading] = useState(false)
  const [isUtilityAffected, setIsUtilityAffected] = useState(false)
  const [isApproved, setIsApproved] = useState(false)
  const [snapshotArray, setSnapshotArray] = useState<UploadFileFiltersInput[]>([])
  const [snapshotLoading, setSnapshotLoading] = useState(false)
  // const [approvalItemCompanyData, setApprovalItemCompanyData] = useState<CompanyEntity | null>(null)
  const [isSaving, setIsSaving] = useState(false)
  const [templateMapDocument, setTemplateMapDocument] = useState<DocumentData>(null)
  const [isExpiryDateDefined, setIsExpiryDateDefined] = useState(false)
  const [isTemplateAvailable, setIsTemplateAvailable] = useState(false)

  const [updateApproval] = useUpdateApprovalMutation()
  const [updateApplication] = useUpdateApplicationMutation()
  const [updateApplicationProgress] = useAppApprovalProgressUpdateMutation()
  const [fetchApplication] = useApplicationLazyQuery()
  const [createMessage] = useCreateNotificationMessageMutation()
  const [createApprovalMapDocument, { loading: creatingApprovalMapDocument }] = useApprovalMapLetterMutation()

  const { data, loading: appDataLoading } = useApplicationByUuid()
  const {data: approvalData, loading: approvalDataLoading} = useApprovalQuery({
    fetchPolicy: globalFetchPolicy,
    variables: {
      id: currentApprovalItemId
    }
  })
  const currentApprovalItem = approvalData?.approval?.data as ApprovalEntity
  const approvalItemCompanyData = currentApprovalItem?.attributes?.company?.data as CompanyEntity

  const { data: companyData, loading: companyDataLoading } = useCompanyQuery({
    variables: { id: companyId },
    fetchPolicy: globalFetchPolicy,
    skip: !companyId,
  })
  const {data: organizationData} = useOrganizationQuery({
    fetchPolicy: globalFetchPolicy,
    variables: user?.organization?.data?.id ? {
      id: user?.organization?.data?.id
    }: undefined,
    skip: !user?.organization?.data?.id
  })
const currentOrganization = organizationData?.organization?.data as OrganizationEntity
  console.log(currentOrganization?.attributes?.letterTemplates?.data)
  const defaultTemplateId = currentOrganization?.attributes?.letterTemplates?.data?.[0]?.id



  // const { data: approvalItemCompanyDataRes } = useCompanyQuery({
  //   variables: { id: currentApprovalItem?.attributes?.company?.data?.id || '' },
  //   fetchPolicy: globalFetchPolicy,
  //   skip: !currentApprovalItem?.attributes?.company?.data?.id,
  // })

  const currentItemUserProfilesId = approvalItemCompanyData?.attributes?.userProfiles?.data?.map(it => it?.id)
  const { data: userProfilesDataRes, loading: userProfilesDataLoading } = useUserProfilesQuery({
    fetchPolicy: globalFetchPolicy,
    variables: { filter: { id: { in: currentItemUserProfilesId } } },
  })
  const approvalItemUserProfiles = userProfilesDataRes?.userProfiles?.data as UserProfileEntity[]

  const currentCompanyEmployeesOptions = approvalItemUserProfiles?.map(it => ({
    label: <SelectUserItem name={it?.attributes?.fullName} email={it?.attributes?.primaryEmail} />,
    value: it?.id,
  })) || []

  const { data: settingsData, loading: settingsLoading } = useSettingsQuery({
    fetchPolicy: globalFetchPolicy,
  })



  // ===== VARIABLES ========
  const settings = settingsData?.setting?.data as SettingEntity
  const chunkSize = settings?.attributes?.chunkSize || PLATFORM_SETTINGS?.chunkSize as number
  const initialBuffer = settings?.attributes?.easetechSearchBuffer || PLATFORM_SETTINGS?.easetechSearchBuffer as number

  const myLevelOfApproval = user?.userProfile?.data?.attributes?.levelOfApproval
  const myCompanyName = user?.userProfile?.data?.attributes?.company?.data?.attributes?.name

  const companyGeometryData = approvalItemCompanyData?.attributes?.areas?.data as AreaEntity[]
  const generalGeometry = companyGeometryData?.filter(it => it?.attributes?.category === 'general')
  const utilityGeometry = companyGeometryData?.filter(it => it?.attributes?.category === 'utility')

  const currentApplication = data?.applications?.data?.[0] as ApplicationEntity
  const approvalsData = currentApplication?.attributes?.approvals?.data as ApprovalEntity[]
  const renewalsData = currentApplication?.attributes?.renewals?.data as ApprovalEntity[]
  const spCompany = companyData?.company?.data
  const applicationApprovalMode = applicationData?.attributes?.approvalMode

  // ======= USE-EFFECTS =======
  useEffect(() => {
    setIsTemplateAvailable(!!currentOrganization?.attributes?.letterTemplates?.data)
    console.log(!!currentOrganization?.attributes?.letterTemplates?.data)
  }, [currentOrganization?.attributes?.letterTemplates?.data])

  // useEffect(() => {
  //   const currentItemCompany = approvalItemCompanyDataRes?.company?.data as CompanyEntity
  //   setApprovalItemCompanyData(currentItemCompany)
  // }, [approvalItemCompanyDataRes?.company?.data])

  useEffect(() => {
    if (!settingsLoading) {
      setGeneralRange(initialBuffer)
      setUtilityRange(initialBuffer)
    }
  }, [initialBuffer, settingsLoading])

  useEffect(() => {
    if (!appDataLoading) {
      setApplicationData(currentApplication)
    }
  }, [appDataLoading, currentApplication])

  useEffect(() => {
    if (!appDataLoading) {
      const currentApproveItem = (approvalCategory === 'approval' ? currentApplication?.attributes?.approvals?.data : currentApplication?.attributes?.renewals?.data)?.find(
        it => currentApprovalItemId === it?.id,
      ) as ApprovalEntity

      // setCurrentApprovalItem(currentApproveItem)
      setUploadedDocuments(currentApproveItem?.attributes?.attachments?.data)
      setSiteVisitChecked(currentApproveItem?.attributes?.isSiteVisitRequired as boolean)
      setIsApproved(currentApproveItem?.attributes?.status === 'approved')
    }
  }, [appDataLoading, approvalCategory, currentApplication?.attributes?.approvals?.data, currentApplication?.attributes?.renewals?.data, currentApprovalItemId])

  useEffect(() => {
    if (applicationApprovalMode === 'strict' && role === 'government') {
      // ===> TODO: Checking if other conciliators has approved current application
      // const approvedArray = otherConciliators?.map(it => it?.approved)
      // if (approvedArray.length && approvedArray?.includes(false)) {
      setIsFormDisabled(true)
      setComment('You will be able to approve this application only after all service-providers approved it')
      // }
    }
  }, [applicationApprovalMode, role])

  //  ======================================================================

  let checkResult: ResultType | null = null
  if (!appDataLoading && !companyDataLoading) {
    checkResult = checkApproval(spCompany as CompanyEntity, approvalsData)
  }

  const spApprovalAttachments = currentApprovalItem?.attributes?.attachments?.data?.map(
    it =>
      ({
        id: it?.id,
        name: it?.attributes?.name,
        size: it?.attributes?.size,
        url: it?.attributes?.url,
        ext: it?.attributes?.ext,
      } as ImageItem),
  ) as ImageItem[]

  const initialValues = useMemo(() => (
    {
      title: applicationData?.attributes?.title,
      isAffected: currentApprovalItem?.attributes?.isAffected ? 'affected' : 'notAffected',
      expiryDate: currentApprovalItem?.attributes?.expiryDate ? moment(currentApprovalItem?.attributes?.expiryDate) : null,
      description: currentApprovalItem?.attributes?.description ? currentApprovalItem?.attributes?.description : '',
      nextSignatory: checkResult?.nextSignatory ? checkResult?.nextSignatory : '—',
      docType: currentApprovalItem?.attributes?.approvalDocumentType ? currentApprovalItem?.attributes?.approvalDocumentType : 'final',
      feedbackDate: currentApprovalItem?.attributes?.feedback ? moment(currentApprovalItem?.attributes?.feedback) : null,
      approval: currentApprovalItem?.attributes?.status === 'approved' ? ['approved'] : undefined,
      contacts: currentApprovalItem?.attributes?.contacts?.data ? currentApprovalItem?.attributes?.contacts?.data?.map(it => it?.id) : [],
    } as FormValues
  ), [
    applicationData?.attributes?.title,
    checkResult?.nextSignatory,
    currentApprovalItem?.attributes?.approvalDocumentType,
    currentApprovalItem?.attributes?.status,
    currentApprovalItem?.attributes?.description,
    currentApprovalItem?.attributes?.expiryDate,
    currentApprovalItem?.attributes?.feedback,
    currentApprovalItem?.attributes?.isAffected,
    currentApprovalItem?.attributes?.contacts?.data,
  ])

  useEffect(() => {
    form.resetFields()
  }, [form, initialValues])

  useEffect(() => {
    if (mode === 'info') {
      setIsFormDisabled(true)
    }
  }, [mode])

  useEffect(() => {
    if (approvalPdfLink) {
      setIsChanges(true)
      setPdfLink(approvalPdfLink as string)
    }
  }, [approvalPdfLink])

  useEffect(() => {
    if (!approvalDataLoading) {
      setIsAffected(currentApprovalItem?.attributes?.isAffected ?? false)
      setSiteVisitChecked(currentApprovalItem?.attributes?.isSiteVisitRequired ?? false)
    }
  }, [currentApprovalItem, approvalDataLoading])

  useEffect(() => {
    if (!appDataLoading && uploadedDocuments?.length) {
      setUploadedFileList(
        uploadedDocuments?.map(
          (it: UploadFileEntity) =>
            ({
              id: it?.id,
              name: it?.attributes?.name,
              size: it?.attributes?.size,
              url: it?.attributes?.url,
              ext: it?.attributes?.ext,
            } as ImageItem),
        ),
      )
    }
    if (!appDataLoading && spUploadedPdfLink) {
      setPdfLink(spUploadedPdfLink)
    }
  }, [uploadedDocuments, appDataLoading, spUploadedPdfLink])

  const onChange = (e: RadioChangeEvent) => {
    setIsAffected(e.target.value)
  }
  const onUpload = (info: UploadChangeParam) => {
    const currentUploadedFile = info.file as unknown as UploadedFileAWSResponse
    if (info.file.status !== 'uploading') {
      setFileList(info?.fileList as unknown as UploadedFileAWSResponse[])
      const newFileResponse = currentUploadedFile?.response?.[0] as unknown as Response
      const newUploadedFile = {
        id: newFileResponse?.id,
        name: newFileResponse?.name,
        size: newFileResponse?.size + ' KB',
        url: newFileResponse?.url,
      } as unknown as ImageItem

      setUploadedFileList(prev => [newUploadedFile, ...prev] as ImageItem[])
      setIsChanges(true)
    }
  }
  const onRemoveItem = async (id: InputMaybe<IdFilterInput>) => {
    const newFileList = uploadedFileList.filter(it => it?.id !== id)
    setUploadedFileList(newFileList)
    setIsChanges(true)
    setFileList(fileList?.filter(it => (it?.response?.[0]?.id || '').toString() !== id as unknown as string))
  }
  const onRemoveApprovalLetter = async () => {
    setApprovalLetterInfo(null)
  }
  const onRemoveTemplateMapDocument = async () => {
    setTemplateMapDocument(null)
  }
  const onRemovePdfItem = () => {
    removePdfLink()
    removeApprovalDetails()
    setPdfLink('')
  }
  const onFormChange = () => {
    setIsChanges(true)
  }
  const onCancel = () => {
    form.resetFields()
    setIsModalOpen(false)
    setModalWidth(null)
  }
  const onSaveClick = () => {
    form.validateFields().catch(() => {
      const errors = form.getFieldsError()
      console.log(errors)
      errors?.forEach(it => {
        if (it?.errors?.length) {
          message.info(`${it?.name}: ${it?.errors?.toString()}`)
        }
      })
    })
  }
  const onFinish = async (values: FormValues) => {


    setIsSaving(true)
    const uploadedIds = uploadedFileList?.map(it => it?.id)
    const approvalLetterId = approvalLetterInfo?.id ? approvalLetterInfo?.id : null
    const approvalMapLetterId = templateMapDocument?.id ? templateMapDocument?.id : null
    let isApproved = false
    let newStatus = applicationData?.attributes?.status

    if (values?.approval?.length && values?.approval?.[0] === 'approved') {
      isApproved = true
      newStatus = checkResult?.isFinalApprove && checkResult?.allowedToApprove ? 'approved' : 'approvals_in_process'
    }
    const feedbackDate = moment().format('YYYY-MM-DDTHH:mm:ssZ') as unknown as InputMaybe<Scalars['DateTime']>



    // const newApprovalItems: ComponentDataApplicationServiceProviderInput[] = (approvalCategory === 'approval' ? approvalSetData : renewalSetData)?.map(it => {
    //
    //   if (it?.id === currentApprovalItemId) {
    //     return {
    //       isAffected: values.isAffected === 'affected',
    //       description: values.description,
    //       expiryDate: values.expiryDate ? moment(values.expiryDate).format('YYYY-MM-DD') : null,
    //       approved: isApproved,
    //       isSiteVisitRequired: siteVisitChecked,
    //       feedback: feedbackDate,
    //       attachments: [...approvalLetterId, ...approvalMapLetterId, ...uploadedIds],
    //       approvalDocumentType: values.docType,
    //       company: it?.company?.data?.id,
    //       approvalDocumentPdfLink: currentApprovalItem?.approvalDocumentPdfLink,
    //       contacts: values?.contacts,
    //       name: it?.company?.data?.attributes?.name,
    //       status: isApproved ? 'approved' : 'pending',
    //       uuid: it?.uuid,
    //       baseInitialItemUuid: it?.baseInitialItemUuid,
    //     } as ComponentDataApplicationServiceProviderInput
    //   }
    //   return {
    //     name: it?.company?.data?.attributes?.name,
    //     company: it?.company?.data?.id,
    //     feedback: it?.feedback !== null ? moment(it?.feedback).format('YYYY-MM-DDTHH:mm:ssZ') : null,
    //     approved: it?.approved,
    //     description: it?.description,
    //     expiryDate: it?.expiryDate !== null ? moment(it?.expiryDate).format('YYYY-MM-DD') : null,
    //     approvalDocumentType: it?.approvalDocumentType,
    //     isAffected: it?.isAffected,
    //     isSiteVisitRequired: it?.isSiteVisitRequired,
    //     attachments: it?.attachments?.data?.map(item => item?.id),
    //     approvalDocumentPdfLink: it?.approvalDocumentPdfLink,
    //     contacts: it?.contacts?.data?.map(it => it?.id),
    //     status: it?.status ?? 'new',
    //     uuid: it?.uuid,
    //     baseInitialItemUuid: it?.baseInitialItemUuid,
    //   }
    // }) as ComponentDataApplicationServiceProviderInput[]

    try {
      const updatedApprovalData: ApprovalInput = {
        isAffected: values.isAffected === 'affected',
        description: values.description,
        expiryDate: values.expiryDate ? moment(values.expiryDate).format('YYYY-MM-DD') : null,
        isSiteVisitRequired: siteVisitChecked,
        feedback: feedbackDate,
        approvalLetter: approvalLetterId,
        approvalMapLetter: approvalMapLetterId,
        attachments: [...uploadedIds] as unknown as InputMaybe<InputMaybe<Scalars['ID']>[]>,
        approvalDocumentType: values.docType,
        contacts: values?.contacts,
        status: isApproved ? 'approved' : currentApprovalItem?.attributes?.status === 'new' ? 'pending' : currentApprovalItem?.attributes?.status,
      }
      await updateApproval({
        fetchPolicy: globalFetchPolicy,
        variables: {
          id: currentApprovalItemId,
          data: updatedApprovalData
        },
        onError: (error)=>{
          message.error(error.message)
        },
        onCompleted: ()=>{
          if (isApproved) {
            BlackNotification('Approval item approved successfully.')
          } else {
            BlackNotification('Changes saved. Status of approval is "Pending" as you still do not approve current approval item.')
          }
        },
        refetchQueries: 'active',
      })

      if(approvalCategory === 'approval' && currentApplication?.id) {
        await updateApplicationProgress({
          variables: {input: {appId: currentApplication?.id}}
        })
        await updateApplication({
            variables: {
              id: currentApplication?.id,
              data: {
                status: newStatus,
                approvedDate: checkResult?.isFinalApprove && checkResult?.allowedToApprove ? moment().format('YYYY-MM-DD') : null,
              }
            },
            fetchPolicy: globalFetchPolicy,
            onError: (error) => {
              message.error(error.message)
              setIsSaving(false)
            },
            refetchQueries: 'active'
          }
        )
      }


      if (approvalCategory === 'approval'  && isApproved && checkResult?.isLastApprove && checkResult?.allowedToApprove) {
        await createMessage({
          variables: {
            input: {
              title: 'Application status',
              message: `Application No${applicationData?.attributes?.title} is approved by ${spCompany?.attributes?.name}.`,
              application: applicationData?.id,
              from: user?.userProfile?.data?.id,
              to: applicationData?.attributes?.project?.data?.attributes?.applicant?.data?.id,
            },
          },
          fetchPolicy: globalFetchPolicy,
          refetchQueries: 'active',
          onError: (error) => {
            message.error(error.message)
          },
        })
      }

      if (approvalCategory === 'approval' && checkResult?.isLastApprove && checkResult?.isFinalApprove) {
        await createMessage({
          variables: {
            input: {
              title: 'Application approved',
              message: `Your wayleave application ${applicationData?.attributes?.title} has been approved and finalised. Please review this and contact us for any questions. Thank you for your business and we look forward to being of assistance again soon.`,
              application: applicationData?.id,
              from: user?.userProfile?.data?.id,
              to: applicationData?.attributes?.project?.data?.attributes?.applicant?.data?.id,
            },
          },
          fetchPolicy: globalFetchPolicy,
          refetchQueries: 'active',
          onError: (error) => {
            message.error(error.message)
          },
        })
      }
      if (approvalCategory === 'renewal' && isApproved) {
        await createMessage({
          variables: {
            input: {
              title: 'Renewal approved',
              message: `Your renewal for application ${applicationData?.attributes?.title} has been approved.`,
              application: applicationData?.id,
              from: user?.userProfile?.data?.id,
              to: applicationData?.attributes?.project?.data?.attributes?.applicant?.data?.id,
            },
          },
          fetchPolicy: globalFetchPolicy,
          refetchQueries: 'active',
          onError: (error) => {
            message.error(error.message)
          },
        })
      }
      setIsSaving(false)
      setIsChanges(false)
      removePdfLink()
      removeApprovalDetails()
      setIsModalOpen(false)
      setModalWidth(null)
    } catch (error: unknown) {
      setIsSaving(false)
      console.log(error)
      BlackNotification('Error saving. Try again')
    }
  }

  const onSiteVisitChange = async (e: CheckboxChangeEvent) => {
    setSiteVisitChecked(e.target.checked)
  }
  const onGeneralShow = (value: boolean) => {
    setIsShowGeneralGeometry(value)
  }
  const onUtilityShow = (value: boolean) => {
    setIsShowUtilityGeometry(value)
  }
  const onUtilityRunCheck = async () => {
    setIsLoading(true)
    const projectsFeature = await getGeoJsonFromUrl(currentApplication?.attributes?.project?.data?.attributes?.geoJson?.data?.attributes?.url)
    const utilityAreasIntersectionsCheckResult = await geometryIntersectionChecker(
      projectsFeature,
      utilityGeometry,
      utilityRange,
      chunkSize,
    )
    if (!!utilityAreasIntersectionsCheckResult?.linesCheckResult?.affectedIds?.length || !!utilityAreasIntersectionsCheckResult?.polygonCheckResult?.affectedIds?.length) {
      BlackNotification(`Utility geometry affected by the current project with buffer size of ${utilityRange} meters`)
      setIsUtilityAffected(true)
      setIsAffected(true)
      form.setFieldValue('isAffected', 'affected')
    } else {
      setIsUtilityAffected(false)
      BlackNotification(`Utility geometry is not affected by the current project with buffer size of ${utilityRange} meters.`)
      form.setFieldValue('isAffected', 'notAffected')
    }
    setIsLoading(false)
  }
  const onGeneralRangeChange = (value: number) => {
    setGeneralRange(value)
  }
  const onUtilityRangeChange = (value: number) => {
    setUtilityRange(value)
  }

  const dataSource: GeometryDataItem[] = [
    {
      id: 'general',
      title: 'General',
      isDisabled: generalGeometry?.length === 0,
      onShowChange: onGeneralShow,
      isCheckDisabled: !isShowGeneralGeometry,
      onRunCheck: () => '',
      onRangeChange: onGeneralRangeChange,
      isLoading: isLoading,
      isAffected: true,
      initialBuffer: generalRange,
    },
    {
      id: 'utility',
      title: 'Utility',
      isDisabled: utilityGeometry?.length === 0,
      onShowChange: onUtilityShow,
      isCheckDisabled: !isShowUtilityGeometry,
      onRunCheck: onUtilityRunCheck,
      onRangeChange: onUtilityRangeChange,
      isLoading: isLoading,
      isAffected: isUtilityAffected,
      initialBuffer: utilityRange,
    },
  ]
  const onApproveChange = (e: CheckboxChangeEvent) => {
    setIsApproved(e?.target?.checked)
    if (e?.target?.checked) {
      lottieRef.current?.animationContainerRef?.current?.classList?.remove('hidden')
      lottieRef.current?.goToAndStop(0, true);
      lottieRef.current?.setDirection(1)
      lottieRef.current?.setSpeed(0.7)
      lottieRef.current?.play()
    } else {
      lottieRef.current?.animationContainerRef?.current?.classList?.add('hidden')
      // lottieRef.current?.setDirection(-1)
      // lottieRef.current?.setSpeed(2)
      // lottieRef.current?.play()
    }
  }
  const approvalBlockClasses = classnames({
    'approval-check-block': true,
    'isChecked': isApproved,
  })
  const approvalClasses = classnames({
    'approval-check-box-form': true,
    'accented': true,
    'isChecked': isApproved,
  })
  const handleGetSnapshot = async () => {
    try {
      setSnapshotLoading(true)
      const snapShotData = mapRef && await getMapSnapshot(mapRef)
      snapShotData?.length && setSnapshotArray((prev) => [...prev, ...snapShotData])
      setSnapshotLoading(false)
    } catch (error) {
      console.log(error)
      message.error('Error getting a map snapshot. Please, try again.')
      setSnapshotLoading(false)
    }
  }
  const handleAddToApprovals = async () => {
    const snapshotsData = snapshotArray?.slice(0, 4).map((it, index) => {
      return ({
        id: it?.id?.toString(),
        name: `map-0${index + 1}`,
        url: it?.url,
      })
    }) as unknown as SnapshotInput[]
    currentApprovalItem?.id &&
    currentApplication?.id &&
    defaultTemplateId &&
    await createApprovalMapDocument({
      variables: {
        input: {
          approvalItemId: currentApprovalItem?.id,
          appId: currentApplication?.id,
          templateId: defaultTemplateId,
          snapshots: snapshotsData,
        },
      },
      onCompleted: (result) => {
        setTemplateMapDocument(result?.approvalTemplateMapData)
        BlackNotification('Document created and added to your approval document set below')
        setSnapshotArray([])
      },
      onError: (error) => {
        message.error(error.message)
      }
    })

    // const addedFile = snapshotArray?.find(it => it?.id === id)
    // const newFileProcessed = {
    //   id: addedFile?.id,
    //   name: addedFile?.name,
    //   size: addedFile?.size,
    //   url: addedFile?.url,
    //   ext: addedFile?.ext,
    // } as ImageItem
    // setUploadedFileList((prev) => [newFileProcessed, ...prev])
    // setSnapshotArray(prev => prev?.filter((it) => it?.id !== id))
  }
  const onRemoveSnapshotItem = async (id: InputMaybe<IdFilterInput>) => {
    setSnapshotArray(prev => prev.filter((it) => it?.id !== id))
  }
  const onAddContact = () => {
    setIsDrawerOpen(true)
    setDrawerType('create-profile')
    setCurrentId(currentApprovalItem?.attributes?.company?.data?.id || '')
  }
const onExpiryDateChange = (e: moment.Moment | null)=>{

  setExpiryDate(e)
  if(e) {
    setIsExpiryDateDefined(true)
  }
}
  return appDataLoading ||
  companyDataLoading ||
  !initialValues ||
  !applicationData

    ?
    (
      <Spin />
    ) : (
      <>
        <div className={'approval-details-block'} ref={wrapperRef}>
          {!currentApprovalItem ? (
            <TablePlaceholder
              title={'Application approvals'}
              text={`No approvals found for the current company (${user?.userProfile?.data?.attributes?.company?.data?.attributes?.name})`} />
          ) : (
            <Form
              layout={'vertical'}
              initialValues={initialValues}
              onFinish={onFinish}
              onValuesChange={onFormChange}
              form={form}
            >
              <FormItemDivider
                title={approvalCategory === 'approval' ? 'Approval form' : 'Approval form (renewal)'}
                marginTop={0}
                subTitle={currentApprovalItem?.attributes?.company?.data?.attributes?.name}
              />
              <div className={'approval-form-content'}>
                {myLevelOfApproval ? (
                  <div style={{ margin: '12px 0 24px' }}>
                    <Text size={'sm'}>{'You have '}</Text>
                    <Text size={'md'} weight={'w700'}>
                      {myLevelOfApproval}
                    </Text>
                    <Text size={'sm'}>{` level of approval in ${myCompanyName}`}</Text>
                    {checkResult?.allowedToApprove ? (
                      <Paragraph size={'sm'}
                                 color={'light-gray'}>{'You are allowed to approve current application.'}</Paragraph>
                    ) : (
                      <Paragraph
                        size={'xs'}
                        color={'light-gray'}>
                        {'You are not allowed to approve current application. Final approval is required from colleagues within your organization.'}
                      </Paragraph>)}
                  </div>
                ) : null}
                {comment && (
                  <Paragraph style={{ marginTop: 32 }} size={'sm'} color={'error'}>
                    {comment}
                  </Paragraph>
                )}
                <ApprovalMap
                  mapRef={mapRef as MutableRefObject<MapRef>}
                  generalGeometry={generalGeometry}
                  utilityGeometry={utilityGeometry}
                  application={currentApplication}
                  isShowGeneralGeometry={isShowGeneralGeometry}
                  isShowUtilityGeometry={isShowUtilityGeometry}
                  handleGetSnapshot={handleGetSnapshot}
                  snapshotLoading={snapshotLoading}
                />
                {/*{snapshotArray?.map((it) => <UploadedFileItem key={it?.id as Key} it={it as UploadDataItem} />)}*/}
                <Paragraph style={{
                  paddingTop: 12,
                }}>{'Create a Map snapshot approval document. You can include in that document no more then 4 map snapshots.'}</Paragraph>
                <Row align={'top'} gutter={12}>
                  <Col span={10}>
                    <Tooltip title={!defaultTemplateId ? 'No template for the map document available for the current organization' : null}>
                      <div>
                        <Button
                          disabled={!snapshotArray?.length || !defaultTemplateId}
                          icon={<SvgIcon type={'pdf-logo'} />}
                          btnType={'ghost'} text={'Create PDF map documents'}
                          onClick={handleAddToApprovals}
                          block
                          loading={creatingApprovalMapDocument}
                        />
                      </div>
                    </Tooltip>
                  </Col>
                  <Col span={14}>
                    {snapshotArray?.length ? (
                      snapshotArray?.map((it) => (
                        <ImageFileCard
                          isBordered
                          marginBottom={8}
                          key={it?.id as Key}
                          itemId={it?.id}
                          name={it?.name as unknown as string}
                          imageUrl={it?.url as unknown as string}
                          ext={'.png'}
                          isShowRemoveButton={true}
                          onRemoveItem={onRemoveSnapshotItem}
                        />
                      ))
                    ): (
                      <FileImagePlaceholder text={'Start with creating a map snapshot'} />
                    )}
                  </Col>
                </Row>
                <FormItemDivider />
                <GeometryTable
                  dataSource={dataSource}
                  loading={settingsLoading}
                />

                <Form.Item style={{ paddingTop: 20 }} name={'isAffected'} label={'Is geometry affected (manual input)'}>
                  <Radio.Group
                    disabled={isFormDisabled}
                    onChange={onChange}
                    value={isAffected}
                    style={{ marginBottom: 24 }}
                  >
                    <Radio value={'notAffected'}>Not affected</Radio>
                    <Radio value={'affected'}>Affected</Radio>
                  </Radio.Group>
                </Form.Item>
                <Form.Item name={'siteVisitRequired'} label={'Site visit required'}>
                  <Checkbox checked={siteVisitChecked} disabled={isFormDisabled} onChange={onSiteVisitChange}>
                    {'Required'}
                  </Checkbox>
                </Form.Item>
                {siteVisitChecked && (
                  <>
                    <FormItem
                      name={'contacts'}
                      type={'multi-tags'}
                      selectOptions={currentCompanyEmployeesOptions as SelectOptionsType[]}
                      label={'Site contacts'}
                      placeholder={'Select on-site contact'}
                      loading={userProfilesDataLoading}
                      helpText={'Specify a contact people if you checked you Site visit is required'}
                    />
                    <Row justify={'end'}>
                      <Button btnType={'table-action'} icon={<SvgIcon type={'plus'} />} text={'Add contact'} onClick={onAddContact} />
                    </Row>
                  </>
                )}
                {/*<FormItem label={'Application title'} name={'title'} type={'input'} placeholder={'Enter description'} required={false} readOnly={true} />*/}
                {mode === 'info' ? (
                  <FormItem
                    label={'Feedback Date'}
                    name={'feedbackDate'}
                    type={'date-picker'}
                    placeholder={'Feedback date'}
                    onInput={onFormChange}
                    readOnly={isFormDisabled}
                  />
                ) : null}
                <FormItem
                  label={'Expiry date of approval'}
                  name={'expiryDate'}
                  type={'date-picker'}
                  placeholder={'Select expire date'}
                  onInput={onFormChange}
                  readOnly={isFormDisabled}
                  onDateChange={onExpiryDateChange}
                />
                <FormItem
                  label={'Description'}
                  name={'description'}
                  type={'textarea'}
                  placeholder={'Enter description'}
                  required={false}
                  readOnly={isFormDisabled}
                />
                {user?.userProfile?.data?.attributes?.levelOfApproval !== 'firstAndOnly' && (
                  <FormItem
                    label={'Next signatory'}
                    name={'nextSignatory'}
                    type={'input'}
                    placeholder={'Select next signatory'}
                    required={false}
                    readOnly={true}
                  />
                )}
                <FormItemDivider label={'Authorization/Licence'} />
                <FormItem
                  label={'Document type'}
                  name={'docType'}
                  type={'select'}
                  selectOptions={approvalDocumentTypesOptions}
                  placeholder={'Select document type'}
                  readOnly={isFormDisabled}
                />
                {mode === 'approval' ? (
                  <>
                    <Form.Item required={false} style={{ borderBottom: 'none' }} validateTrigger={false}>
                      <AddGenerateDocumentBtnBlock
                        onUpload={info => onUpload(info)}
                        fileList={fileList || []}
                        isExpiryDateDefined={isExpiryDateDefined}
                        isTemplatesAvailable={isTemplateAvailable}
                      />
                    </Form.Item>
                    <Row>
                      <Space direction={'vertical'} style={{ width: '100%' }}>
                        {approvalLetterInfo && (
                          <ImageFileCard
                            itemId={approvalLetterInfo?.id as unknown as InputMaybe<IdFilterInput>}
                            isBordered={true}
                            ext={'.pdf'}
                            imageUrl={approvalLetterInfo?.url}
                            name={approvalLetterInfo?.name || ''}
                            onRemoveItem={onRemoveApprovalLetter}
                            isShowRemoveButton={true}
                          />
                        )}
                        {templateMapDocument && (
                          <ImageFileCard
                            itemId={templateMapDocument?.id as unknown as InputMaybe<IdFilterInput>}
                            isBordered={true}
                            ext={'.pdf'}
                            imageUrl={templateMapDocument?.url}
                            name={templateMapDocument?.name || ''}
                            onRemoveItem={onRemoveTemplateMapDocument}
                            isShowRemoveButton={true}
                          />
                        )}
                        {uploadedFileList?.length
                          ? uploadedFileList?.map((it, index) => {
                            return (
                              <ImageFileCard
                                itemId={it?.id as InputMaybe<IdFilterInput>}
                                isBordered={true}
                                ext={it?.ext || ''}
                                key={index}
                                imageUrl={it?.url}
                                name={it?.name || ''}
                                onRemoveItem={onRemoveItem}
                                isShowRemoveButton={true}
                              />
                            )
                          })
                          : null}
                      </Space>
                    </Row>
                    <FormItemDivider />

                    <Row justify={'space-between'} align={'bottom'}>
                      <Col className={approvalBlockClasses} span={4}>
                        <Row align={'middle'} justify={'center'} style={{ width: '100%' }}>
                          {(!isApproved) && <SvgIcon type={'clock-circle'} className={'not-approved-icon'} />}
                            <Lottie
                              lottieRef={lottieRef}
                              animationData={checkSuccess}
                              className={'check-animation-container'}
                              loop={false}
                              autoplay={currentApprovalItem?.attributes?.status === 'approved' || false}
                            />
                          <Form.Item name={'approval'} className={approvalClasses} label={''}>
                            <Checkbox.Group>
                              <Checkbox onChange={onApproveChange} value={'approved'}>{'Approved'}</Checkbox>
                            </Checkbox.Group>
                          </Form.Item>

                        </Row>
                      </Col>
                      <Col span={20} className={'approval-info-block'}>
                        <Paragraph
                          color={'gray'}>{'*You can save your changes without approving the approval item and return to it later, or proceed to approve it by selecting the "Approved" checkbox. If you choose to approve, please ensure that you have uploaded at least one approval document beforehand.'}</Paragraph>
                        <Row justify={'end'}>
                          <Space>
                            <Button text={'Cancel changes'} btnType={'text'} disabled={!isChanges} onClick={onCancel} loading={isSaving} />
                            {(checkResult?.allowedToApprove || role === 'agent' || role === 'manager') ? (
                              <Button
                                icon={isApproved ? <SvgIcon type={'checked-square'} /> : null}
                                text={isApproved ? 'Save and approve' : 'Save changes'}
                                btnType={'primary'}
                                htmlType={'submit'}
                                loading={isSaving}
                                onClick={onSaveClick}
                              />
                            ) : null}
                          </Space>
                        </Row>

                      </Col>
                    </Row>
                  </>
                ) : null}
                {mode === 'info' ? (
                  <div>
                    {spApprovalAttachments?.map(it => (
                      <UploadedFileDocCardOld it={it} key={it?.id as string} mode={'info'} />
                    ))}
                  </div>
                ) : null}
              </div>
            </Form>
          )}
        </div>
      </>
    )
}

export default ApprovalForm
