import { useAuth } from '@/components/context/AuthProvider'
import FormItemDivider from '@/components/form-item/FormItemDivider'
import { globalFetchPolicy } from '@/components/layout/DashboardLayout'
import { useApplicationTypesQuery, useCreateCompanyMutation, useOrganizationsQuery } from '@/graphql'
import { EASETECH_COMPANY } from '@/utilities/global-variables'
import { Form, message, Row, Skeleton, Space, Spin } from 'antd'
import { useForm } from 'antd/es/form/Form'
import { FC, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import BlackNotification from '../../blackNotification/BlackNotification'
import Button from '../../button/Button'
import { useGeneralContext } from '../../context/GeneralContext'
import FormItem, { SelectOptionsType } from '../../form-item/FormItem'
import Typography from '../../typography/Typography'

const { Title } = Typography

const getInitialType = (param: string) => {
  let defaultType
  switch (param) {
    case 'clients':
      defaultType = 'client'
      break
    case 'service-providers':
      defaultType = 'service_provider'
      break
    case 'government':
      defaultType = 'government'
      break
    default:
      defaultType = 'client'
  }
  return defaultType
}
const CreateCompany: FC = () => {
  const {user, organization } = useAuth()
  const [form] = useForm()
  const params = useParams()
  const { setIsModalOpen, setIsDrawerOpen } = useGeneralContext()
  const [currentCompanyType, setCurrentCompanyType] = useState<EnumCompanyCompanytype>()

  const { data: organizationsResponse, loading: organizationsDataLoading } = useOrganizationsQuery({
    fetchPolicy: globalFetchPolicy,
  })
  const organizationsData = organizationsResponse?.organizations?.data

  const organizationsOptions = organizationsData?.map(it => ({
    label: it?.attributes?.name,
    value: it?.id,
  }))

  const [createCompany, {loading: companyCreating}] = useCreateCompanyMutation({
    refetchQueries: 'active',
  })
  const { data, loading } = useApplicationTypesQuery()
  const applicationTypes = data?.applicationTypes?.data

  const initialValues = {
    companyType: params.key ? getInitialType(params.key) : null,
  }
  useEffect(() => {
    if (params.key === 'clients') {
      setCurrentCompanyType('client')
    }
  }, [params.key])
  const onFinish = async (value: Partial<CompanyInput>) => {
    await createCompany({
      variables: {
        input: {
          organization: organization === EASETECH_COMPANY ?  value?.organization : user?.organization?.data?.id,
          companyType: organization === EASETECH_COMPANY ? value?.companyType : 'client',
          applicationType: organization === EASETECH_COMPANY ? value?.applicationType : null,
          name: value?.name,
          companyEmail: value?.companyEmail,
          phone: value?.phone,
          address: value?.address,
          vatNumber: value?.vatNumber,
          registrationNumber: value?.registrationNumber,
        },
      },
      fetchPolicy: globalFetchPolicy,
      onCompleted: () => {
        BlackNotification('Company created successfully')
        setIsDrawerOpen(false)
      },
      onError: (error) => {
        message.error(error.message)
      },
    })
  }

  const onCancel = () => {
    setIsDrawerOpen(false)
    setIsModalOpen(false)
  }
  const applicationTypeOptions = applicationTypes?.map(it => ({
    label: it?.attributes?.title,
    value: it?.id,
  }))
  const selectOptions: { label: string, value: EnumCompanyCompanytype }[] = [
    {
      label: 'Client',
      value: 'client',
    },
    {
      label: 'Service provider',
      value: 'service_provider',
    },
    {
      label: 'Municipal Utility',
      value: 'municipal_utility',
    },
    {
      label: 'Government',
      value: 'government',
    },
    {
      label: 'Agent',
      value: 'agent',
    },
  ]
  const handleTypChange = (selectedType: EnumCompanyCompanytype) => {
    setCurrentCompanyType(selectedType)
  }
  const onCreate = () => {
    form.submit()
  }
  return (
    <>
      <FormItemDivider title={'Create a Company'} marginTop={0} marginBottom={12} subTitle={'After creating a Company, please check the company profile to make all additional setups.'} />
      <div className={'drawer-scroll-container'}>
        {loading ? (
          <Skeleton active={true} paragraph={{rows: 8}} />
        ) : (
          <Form form={form} layout={'vertical'} className={'form-content'} onFinish={onFinish} style={{ paddingTop: 12 }}
                initialValues={initialValues}>
            <FormItem type={'input'} name={'name'} label={'Company Name'} placeholder={'Enter Name'} />
            {organization === EASETECH_COMPANY && (
              <FormItem
                type={'select'}
                selectOptions={organizationsOptions as SelectOptionsType[]}
                placeholder={'Select Company organization'}
                label={'Company organization'}
                name={'organization'}
                loading={organizationsDataLoading}
              />
            )}
            <FormItem type={'input-email'} name={'companyEmail'} label={'Company Email'}
                      placeholder={'example@test.com'} />
            <FormItem type={'input'} name={'phone'} label={'Company Phone'}
                      placeholder={'Company phone number'} />
            <FormItem type={'input'} name={'address'} label={'Company Address'} placeholder={'Enter Company Address'} />
            <FormItem type={'input'} name={'vatNumber'} label={'Company Vat Number'} placeholder={'Enter Vat Number'} required={false} />
            <FormItem
              type={'input'}
              name={'registrationNumber'}
              label={'Company Registration Number'}
              placeholder={'Enter Company Registration Number'}
              required={false}
            />
            {organization === EASETECH_COMPANY && (
              <FormItem
                name={'companyType'}
                type={'select'}
                label={'Company type'}
                placeholder={'Select company type'}
                selectOptions={selectOptions}
                handleSelectChange={handleTypChange as (value: string) => void}
              />
            )}
            {(currentCompanyType === 'service_provider' || currentCompanyType === 'government' || currentCompanyType === 'municipal_utility') && (
              <FormItem
                name={'applicationType'}
                type={'select'}
                label={'Application type'}
                placeholder={'Select company application type'}
                selectOptions={applicationTypeOptions as SelectOptionsType[]}
              />
            )}

          </Form>
        )}
      </div>
      <Row justify={'center'} style={{ paddingTop: 24 }} >
        <Space>
          <Button text={'Cancel'} btnType={'ghost'} onClick={onCancel} loading={companyCreating}/>
          <Button text={'Create'} btnType={'primary'} onClick={onCreate} loading={companyCreating}/>
        </Space>
      </Row>
    </>
  )
}
export default CreateCompany
