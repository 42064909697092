import InfoTextItem from '@/components/info-text-item/InfoTextItem'
import { getInitials } from '@/helpers/getInitials'
import { Col, Row } from 'antd'
import { FC } from 'react'
import Typography from '../typography/Typography'
import './SelectUserItem.less'
import classnames from 'classnames'

const {Text, Paragraph } = Typography
type Props = {
  name: Maybe<string>
  email: Maybe<string>
  avatar?: Maybe<string>
  isBordered?: boolean
  marginBottom?: number
  company?: string
  isBold?: boolean
}
const SelectUserItem:FC<Props> = ({name, email, avatar, isBordered = false, isBold= false, marginBottom, company}) => {
  const initials = getInitials(name || 'UU')
  const cardClasses = classnames({
    'user-select-item': true,
    'is-bordered': isBordered,
    'is-bold': isBold,
  })
  return (
    <Row className={cardClasses} style={{marginBottom}}>
      <Col span={24}>
        <Row align={'middle'}>
            <div className={'user-item-avatar'}>
              {avatar ? <img src={avatar} alt={'avatar'} /> : <Text size={'xs'} weight={'w700'}>{initials}</Text>}
            </div>
        <div className={'user-item-content'}>
              <Paragraph weight={'w700'} style={{margin: 0}}>{name}</Paragraph>
              <Paragraph className={'secondary-text'} size={'xs'} color={'light-gray'} style={{ margin: 0, lineHeight: 1 }}>{email}</Paragraph>
          {/*<Paragraph>{'Company: Eskom company LTD'}</Paragraph>*/}
        </div>

        </Row>

      </Col>
      <div className={'additional-content'}>
        {company && <InfoTextItem textSize={'xs'} label={'Company'} text={company} />}
      </div>
    </Row>
  )
}

export default SelectUserItem
