import { useGeneralContext } from '@/components/context/GeneralContext'
import { SvgIcon } from '@/components/icon'
import { Drawer as CustomDrawer, DrawerProps } from 'antd'
import { FC } from 'react'

interface DrawerCustomProps {
  open?: boolean,
  width?: number
}

const Drawer: FC<DrawerCustomProps & DrawerProps> = ({ open, width, ...restProps }) => {
  const { setIsDrawerOpen } = useGeneralContext()
  const onClose = () => {
    console.log('close')
    setIsDrawerOpen(false)
  }
  return (
    <CustomDrawer
      destroyOnClose={true}
      closeIcon={null}
      width={width}
      open={open}
      onClose={onClose}

      {...restProps}
    />
  )
}

export default Drawer
