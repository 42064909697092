import { FC } from "react";
import DOMPurify from "dompurify";

type Props = {
  emailHtml: string;
};

const EmailRenderer: FC<Props> = ({ emailHtml }) => {
  const sanitizedHtml = DOMPurify.sanitize(emailHtml);

  return (
    <div
      dangerouslySetInnerHTML={{ __html: sanitizedHtml }}
  />
  )
};

export default EmailRenderer;
