import { useAuth } from '@/components/context/AuthProvider'
import { useOrganizationQuery } from '@/graphql'
import { globalFetchPolicy } from '@/components/layout/DashboardLayout'

const useOrgOfficialsUserProfileIds = (): string[] => {
  const { user } = useAuth()
  const userOrgId = user?.organization?.data?.id

  const { data } = useOrganizationQuery({
    fetchPolicy: globalFetchPolicy,
    variables: userOrgId ? { id: userOrgId } : undefined
  });
  const organization = data?.organization?.data as OrganizationEntity

  const orgSpCompanies = organization?.attributes?.companies?.data?.filter(it => it?.attributes?.companyType !== 'client')
  return (
    orgSpCompanies?.reduce<string[]>((accum, it) => {
      const profileIds = it?.attributes?.userProfiles?.data?.map(profile => profile?.id || '') || []
      return accum.concat(profileIds)
    }, []) || []
  )
}

export default useOrgOfficialsUserProfileIds