import { WebsiteLoader } from '@/components/layout/WebsiteLoader'
import { Layout } from 'antd'
import { FC, Suspense } from 'react'
import { Outlet } from 'react-router'

const NoSidebarLayout: FC = () => {
  return (
    <Layout>
      <Suspense fallback={<WebsiteLoader />}>
        <Outlet />
      </Suspense>
    </Layout>
  )
}
export { NoSidebarLayout }
