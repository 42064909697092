import { Row, Skeleton } from 'antd'
import { FC } from 'react'

const WebsiteLoader: FC = () => (
  <Row style={{ padding: 12 }}>
    <Skeleton active round paragraph={{ rows: 2 }}  style={{ marginBottom: 30 }} />
    <Skeleton active round paragraph={{ rows: 8 }} />
  </Row>
)

export { WebsiteLoader }
