import { longDash } from '@/helpers/globalVariables'
import { Col, Row } from 'antd'
import { FC, ReactNode } from 'react'
import Typography, { Size } from '../typography/Typography'
import './InfoTextItem.less'

type Props = {
  label: string,
  text: Maybe<string> | ReactNode
  textSize?: Size
}
const { Text } = Typography
const InfoTextItem: FC<Props> = ({ label, text, textSize = 'sm' }) => {

  return (
    <Row style={{ borderBottom: '1px solid #f3f3f3' }}>
      <Col span={8}>
        <Text size={textSize} color={'light-gray'}>{`${label}:`}</Text>
      </Col>
      <Col span={16}>
        <Text size={textSize} weight={'w700'}>{text || longDash}</Text>
        {/*<Input*/}
        {/*  className={'info-item-input'}*/}
        {/*  bordered={false} value={text || longDash}*/}
        {/*/>*/}
      </Col>
    </Row>
  )
}

export default InfoTextItem
