import Button from '@/components/button/Button'
import { SvgIcon } from '@/components/icon'
import TablePlaceholder from '@/components/table-placeholder/TablePlaceholder'
import Typography from '@/components/typography/Typography'
import { Col, Modal, Radio, Row, Skeleton } from 'antd'
import { FC, SyntheticEvent, useState } from 'react'
import './TemplateItem.less'
import classnames from 'classnames'

type Props = {
  it: LetterTemplateEntity
  isChecked: boolean
  handleTemplateSelect: (id: string) => Promise<void>
}
const { Paragraph } = Typography
const TemplateItem:FC<Props> = ({it, isChecked, handleTemplateSelect}) => {
  const [open, setOpen] = useState(false)
  const onImagePreview = (e: SyntheticEvent) => {
    e.stopPropagation()
    setOpen(true)
  }
  const itemClasses = classnames({
    'template-item': true,
    'selected': isChecked
  })
  return (
    <>
    <Row className={itemClasses} align={'middle'} onClick={()=> handleTemplateSelect(it?.id || '')}>
      <Col span={4}>
        <div className={'template-item-image'} onClick={onImagePreview}>
        {it?.attributes?.preview?.data ? (
          <img src={it?.attributes?.preview?.data?.attributes?.url} alt={'template-image'} />

        ) : (
          <Skeleton.Image active={false} />
        )}
        </div>
      </Col>
      <Col flex={'auto'}>
        <Paragraph size={'md'} style={{marginBottom: 0}}>{it?.attributes?.name}</Paragraph>
        <Paragraph color={'light-gray'}>{it?.attributes?.description}</Paragraph>
      </Col>
      <Radio checked={isChecked} />
    </Row>
      <Modal
        cancelText={'Close'}
        open={open}
        onCancel={()=>setOpen(false)}
        closeIcon={<SvgIcon type={'close-modal'}/>}
        footer={null}
        width={340}
        mask={false}
      >
        {it?.attributes?.preview?.data ? (
        <img style={{width: 300}} src={it?.attributes?.preview?.data?.attributes?.url} alt={'template-image'} />

        ) : (
          <TablePlaceholder text={'No template preview file available.'} imageSize={'small'} />
        )}
      </Modal>
    </>
  )
}

export default TemplateItem
