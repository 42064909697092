import FormItem from '@/components/form-item/FormItem'
import FormItemDivider from '@/components/form-item/FormItemDivider'
import { globalFetchPolicy } from '@/components/layout/DashboardLayout'
import { WebsiteLoader } from '@/components/layout/WebsiteLoader'
import SelectUserItem from '@/components/select-user-item/SelectUserItem'
import { useApplicationsQuery, useSendAgentAssignNotificationMutation, useUserProfileQuery } from '@/graphql'
import { Form, message, Row } from 'antd'
import { FC } from 'react'
import { useSearchParams } from 'react-router-dom'
import BlackNotification from '../../blackNotification/BlackNotification'
import Button from '../../button/Button'
import { useGeneralContext } from '../../context/GeneralContext'
import Typography from '../../typography/Typography'

const { Text, Paragraph } = Typography
const AgentAssignNotification: FC = () => {
  const [searchParams] = useSearchParams()
  const uuid = searchParams.get('id') as string
  const { data, loading: applicationLoading } = useApplicationsQuery({
    fetchPolicy: globalFetchPolicy,
    variables: { filter: { uuid: { eq: uuid } } },
  })
  const { setIsModalOpen, currentId } = useGeneralContext()

  const { data: userData, loading: userDataLoading } = useUserProfileQuery({
    fetchPolicy: globalFetchPolicy,
    variables: { id: currentId },
  })
  const currentAgent = userData?.userProfile?.data as UserProfileEntity
  const fullName = currentAgent?.attributes?.fullName
  const currentApplication = data?.applications?.data?.[0]
  const [sendNotification, { loading }] = useSendAgentAssignNotificationMutation()
  const onCancel = () => {
    setIsModalOpen(false)
  }
  const onSend = async (values: { message: string }) => {
    currentApplication?.id && currentAgent?.id && await sendNotification({
      fetchPolicy: globalFetchPolicy,
      variables: {
        input: {
          applicationId: currentApplication?.id,
          agentId: currentAgent?.id,
          message: values.message,
        },
      },
      onError: (error) => {
        message.error(error.message)
      },
      onCompleted: () => {
        BlackNotification('Email notification sent successfully')
        setIsModalOpen(false)
      },
      refetchQueries: 'active'
    })
  }
  const initialValues = {
    to: currentAgent?.attributes?.primaryEmail,
    default_message: `Hello ${fullName}! You have been assigned to the application No ${currentApplication?.attributes?.title}. Please, confirm by changing the agent assign status.`,
  }
  if (userDataLoading || applicationLoading) return <WebsiteLoader />
  return (
    <>
      <FormItemDivider marginBottom={24} title={'Agent Email Notification'} subTitle={'You are about to send an email notification to the following contact:'} />

      <SelectUserItem isBordered={true} name={fullName} email={currentAgent?.attributes?.primaryEmail} avatar={currentAgent?.attributes?.avatar?.data?.attributes?.url} />
      <Form layout={'vertical'} initialValues={initialValues} onFinish={onSend} style={{paddingTop: 24}}>
        <FormItem name={'to'} type={'input'} label={'Recipient email'} placeholder={'Email'} isDisabled={true} />
        <FormItem name={'default_message'} type={'textarea'} label={'Default message'} placeholder={'Message'} isDisabled={true} />
        <FormItem name={'message'} type={'textarea'} label={'Message (optional)'} placeholder={'Message'} required={false} />
        <Row justify={'end'}>
          <Button text={'Cancel'} btnType={'text'} onClick={onCancel} loading={loading} />
          <Button htmlType={'submit'} text={'Send notification'} btnType={'primary'} loading={loading} />
        </Row>
      </Form>
    </>
  )
}
export default AgentAssignNotification
