import BlackNotification from '@/components/blackNotification/BlackNotification'
import { useAuth } from '@/components/context/AuthProvider'
import FormItem from '@/components/form-item/FormItem'
import InfoTextItem from '@/components/info-text-item/InfoTextItem'
import { globalFetchPolicy } from '@/components/layout/DashboardLayout'
import { useUpdateUserProfileMutation, useUserProfileQuery } from '@/graphql'
import { getUserProfileRoleOptions } from '@/helpers/getUserProfileRoleOptions'
import { longDash } from '@/helpers/globalVariables'
import useCompanyOptions from '@/hooks/useCompaniesOptions'
import useOrganizationsOptions from '@/hooks/useOrganizationsOptions'
import { Col, Form, Radio, RadioChangeEvent, Row, Space, Spin, Tooltip } from 'antd'
import { useForm } from 'antd/es/form/Form'
import { FC, useEffect, useState } from 'react'
import Button from '../../button/Button'
import { useGeneralContext } from '../../context/GeneralContext'
import Typography from '../../typography/Typography'
import avatarPlaceholder from './images/avatar_user_placeholder.jpg'
import FormItemDivider from '@/components/form-item/FormItemDivider'

const { Text, Paragraph } = Typography
const UserProfileReview: FC = () => {
  const { role } = useAuth()
  const [form] = useForm()
  const { setIsDrawerOpen, currentId } = useGeneralContext()
  const [updateUserProfile, { loading: isProfileUpdating }] = useUpdateUserProfileMutation()
  const [statusValue, setStatusValue] = useState('pending')
  const [isEditable, setIsEditable] = useState(false)
  const [selectedRole, setSelectedRole] = useState<EnumUserprofileProfiletype | null>(null)

  const { companyOptions, loading: companiesDataLoading } = useCompanyOptions('all')
  const {orgOptions, loading: orgDataLoading} = useOrganizationsOptions('all')

  useEffect(() => {
    if (role === 'manager') {
      setIsEditable(true)
    }
  }, [role])

  const { data, loading } = useUserProfileQuery({
    fetchPolicy: globalFetchPolicy,
    variables: { id: currentId },
  })
  const userData = data?.userProfile?.data as UserProfileEntity

  useEffect(() => {
    if (!loading) {
      setStatusValue(userData?.attributes?.status as string)
      setSelectedRole(userData?.attributes?.profileType as EnumUserprofileProfiletype)
    }
  }, [loading, role, userData?.attributes?.profileType, userData?.attributes?.status])

  const onFinish = (values: UserProfileInput) => {
    userData?.id && updateUserProfile({
      fetchPolicy: globalFetchPolicy,
      variables: {
        id: userData?.id,
        data: {
          organization: values?.organization,
          company: values?.company,
          status: statusValue as InputMaybe<EnumUserprofileStatus>,
          profileType: values?.profileType,
          isDefaultAgent: values?.isDefaultAgent,
          consultingCompanies: values?.consultingCompanies ?? null,
        },
      },
      onCompleted: () => {
        BlackNotification('User profile data updated successfully')
        setIsDrawerOpen(false)
      },
      onError: (e) => {
        BlackNotification(e.message)
      },
      refetchQueries: 'active',
    })
  }

  const onCancel = () => {
    setIsDrawerOpen(false)
  }
  const onChange = (e: RadioChangeEvent) => {
    setStatusValue(e.target.value)
  }

  const initialValues = {
    organization: userData?.attributes?.organization?.data?.id,
    profileType: userData?.attributes?.profileType,
    company: userData?.attributes?.company?.data?.id,
    isDefaultAgent: userData?.attributes?.isDefaultAgent,
    consultingCompanies: userData?.attributes?.consultingCompanies?.data?.map(it => it?.id)
  }
  const roleOptions = getUserProfileRoleOptions()
  const onRoleChange = (value: string) => {
    setSelectedRole(value as EnumUserprofileProfiletype)
  }
  const onSave = () => {
    form.submit()
  }
  return loading ? (
    <Spin />
  ) : (
    <>
      <FormItemDivider title={'User profile review'} marginTop={0} marginBottom={24} subTitle={'Review and edit current profile here'} />
      <div className={'drawer-scroll-container'}>
        <Row justify={'space-between'} style={{ marginBottom: 20 }}>
          <Col span={18}>
            <div className={'additional-info'}>
              {/*<Paragraph size={'xl'} weight={'w700'} style={{marginBottom: 12}}>{userData?.attributes?.fullName}</Paragraph>*/}
              <InfoTextItem label={'Full Name'} text={userData?.attributes?.fullName} />
              <InfoTextItem label={'Email'} text={userData?.attributes?.primaryEmail} />
              <InfoTextItem label={'Secondary Email'} text={userData?.attributes?.secondaryEmail} />
              {userData?.attributes?.secondaryEmail && <InfoTextItem label={'Secondary email'} text={userData?.attributes?.secondaryEmail} />}
              <InfoTextItem label={'Phone'} text={userData?.attributes?.primaryPhone} />
              {<InfoTextItem label={'Secondary phone'} text={userData?.attributes?.secondaryPhone} />}
              {<InfoTextItem label={'Address'} text={userData?.attributes?.address} />}
            </div>
          </Col>
          <Col span={5}>
            <div className={'avatar-section'}>
              <img
                src={userData?.attributes?.avatar?.data?.attributes?.url ?? avatarPlaceholder}
                alt={'avatar'}
              />
            </div>
          </Col>
        </Row>

        <Form form={form} layout={'vertical'} className={'form-content'} onFinish={onFinish} initialValues={initialValues}>
          <FormItem
            label={'Organization'}
            name={'organization'}
            type={'select'}
            selectOptions={orgOptions}
            loading={orgDataLoading}
            isDisabled={!isEditable}
          />
          <FormItem
            label={'User Company'}
            name={'company'}
            type={'select'}
            selectOptions={companyOptions}
            loading={companiesDataLoading}
            isDisabled={!isEditable}
          />

          <FormItem
            isDisabled={!isEditable}
            label={'Profile type'}
            name={'profileType'}
            type={'select'}
            selectOptions={roleOptions}
            handleSelectChange={onRoleChange}
            placeholder={'Select user profile role'}

          />
          {selectedRole === 'external_agent' && (
            <FormItem
              isDisabled={!isEditable}
              label={'External Agent consulting companies'}
              name={'consultingCompanies'}
              type={'multi-select-search'}
              selectOptions={companyOptions}
              // handleSelectChange={onRoleChange}
              placeholder={'Select consultant company'}
            />
          )}
          {selectedRole === 'agent' && (
            <Tooltip
              title={'\'Default Agent\' means all new applications will be automatically allocated to that user.'}
              placement={'bottomLeft'}
            >
              <div>
                <FormItem
                  name={'isDefaultAgent'}
                  type={'switch'}
                  label={'Is default agent'}
                  isDisabled={!isEditable}
                  defaultValue={userData?.attributes?.isDefaultAgent}
                />
              </div>
            </Tooltip>
          )}

          <FormItemDivider label={'User account status:'} marginTop={0} />
          <Radio.Group
            onChange={onChange}
            value={statusValue}
            style={{ display: 'flex', flexDirection: 'column', marginBottom: 20 }}
            disabled={!isEditable}
          >
            <Radio value={'pending'} style={{ marginBottom: 10 }} className={'user-radio-item'}>
              <div className={'user-radio-item-content'}>
                <Text size={'md'} className={'radio-item-name'}>
                  {'Pending'}
                </Text>
                <Tooltip
                  overlayClassName={'main-tooltip'}
                  placement={'bottomRight'}
                  title={
                    'Default status that indicates that user\'s registration information is needed to be validated by Manager '
                  }
                >
                  <div className={'tool-tip-info'}>{'i'}</div>
                </Tooltip>
              </div>
            </Radio>
            <Radio value={'active'} style={{ marginBottom: 10 }} className={'user-radio-item'}>
              <div className={'user-radio-item-content'}>
                <Text size={'md'} className={'radio-item-name'}>
                  {'Active'}
                </Text>
                <Tooltip
                  overlayClassName={'main-tooltip'}
                  placement={'bottomRight'}
                  title={'User allowed to access all the application functionality according their user Role'}
                >
                  <div className={'tool-tip-info'}>{'i'}</div>
                </Tooltip>
              </div>
            </Radio>
            <Radio value={'inactive'} style={{ marginBottom: 10 }} className={'user-radio-item'}>
              <div className={'user-radio-item-content'}>
                <Text size={'md'} className={'radio-item-name'}>
                  {'Inactive'}
                </Text>
                <Tooltip
                  overlayClassName={'main-tooltip'}
                  placement={'bottomRight'}
                  title={'Inactive users can see their dashboards but not allowed to create any content'}
                >
                  <div className={'tool-tip-info'}>{'i'}</div>
                </Tooltip>
              </div>
            </Radio>
            <Radio value={'blocked'} style={{ marginBottom: 10 }} className={'user-radio-item'}>
              <div className={'user-radio-item-content'}>
                <Text size={'md'} className={'radio-item-name'}>
                  {'Blocked'}
                </Text>
                <Tooltip
                  overlayClassName={'main-tooltip'}
                  placement={'bottomRight'}
                  title={'Blocked users are not allowed to log in into platform'}
                >
                  <div className={'tool-tip-info'}>{'i'}</div>
                </Tooltip>
              </div>
            </Radio>
          </Radio.Group>


        </Form>
      </div>
        <Row justify={'end'}>
          <Space>
            <Button text={'Cancel'} btnType={'ghost'} onClick={onCancel} />
            <Button text={'Save changes'} btnType={'primary'} loading={isProfileUpdating} onClick={onSave} />
          </Space>
        </Row>
    </>
  )
}
export default UserProfileReview
