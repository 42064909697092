import { useMemo } from 'react'
import { useAuth } from '@/components/context/AuthProvider'

const useUserCompanyProfileIds = (): string[] => {
  const { user } = useAuth()
  const userCompany = user?.userProfile?.data?.attributes?.company?.data

  return useMemo(() => {
    return userCompany?.attributes?.userProfiles?.data?.map(it => it?.id || '') || []
  }, [])
}

export default useUserCompanyProfileIds