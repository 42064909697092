import { useGeneralContext } from '@/components/context/GeneralContext'
import EmailRenderer from '@/components/email-renderer/EmailRenderer'
import FormItemDivider from '@/components/form-item/FormItemDivider'
import { SvgIcon } from '@/components/icon'
import ImageFileCard from '@/components/image-file-card/ImageFileCard'
import InfoTextItem from '@/components/info-text-item/InfoTextItem'
import LongDashText from '@/components/long-dash-text-item/LongDashText'
import Status, { StatusType } from '@/components/status/Status'
import { getDateAndTime } from '@/utilities/utilities'
import { Col, Row } from 'antd'
import { Dispatch, FC, SetStateAction } from 'react'
import Typography from '../typography/Typography'
import './EmailMessage.less'
import Button from '../button/Button'

const { Text } = Typography
type Props = {
  email: EmailMessageEntity
  setIsReplyComposeActive: Dispatch<SetStateAction<boolean>>
  setReplyEmail: Dispatch<SetStateAction<Maybe<EmailMessageEntity>>>
}
const EmailMessage:FC<Props> = ({email, setIsReplyComposeActive, setReplyEmail}) => {
  // const {setCurrentId} = useGeneralContext()
  const deliveryDate = getDateAndTime(email?.attributes?.deliveredAt)
const onReply = () => {
  setIsReplyComposeActive(true)
  // setCurrentId(email?.id || '')
  setReplyEmail(email)
}
  return (
    <Col span={24} className={'email-message-wrapper'}>

      <Row justify={'space-between'}>
        <Text size={'lg'} weight={'w700'}>{email?.attributes?.subject}</Text>
        <Status status={email?.attributes?.status as StatusType} />
      </Row>
      <FormItemDivider marginTop={0} />
      <Row style={{marginBottom: 12}}>
        <Col span={24}>
          {/*<InfoTextItem label={'Id'} text={email?.attributes?.messageId} />*/}
          <InfoTextItem label={'From'} text={email?.attributes?.from} />
          <InfoTextItem label={'To'} text={email?.attributes?.to} />
          <InfoTextItem label={'Delivery date'} text={deliveryDate?.date ? `${deliveryDate?.date}, ${deliveryDate?.time}` : <LongDashText />} />
          <InfoTextItem label={'Delivery status message'} text={email?.attributes?.deliveryMessage} />
        </Col>
      </Row>

      <EmailRenderer emailHtml={email?.attributes?.html || ''} />
      <FormItemDivider label={'Attachments'} />
      {email?.attributes?.attachments?.data?.map(it => (
        <ImageFileCard
          name={it?.attributes?.name || 'File name not found'}
          size={it?.attributes?.size}
          imageUrl={it?.attributes?.url}
          key={it?.id}
          isBordered={true}
          marginBottom={8}
        />
      ))}
      <FormItemDivider />
      <Row justify={'end'}>
        <Button btnType={'table-action'} text={'Reply'} icon={<SvgIcon type={'arrow-up-90'} />} onClick={onReply} />
      </Row>
    </Col>
  )
}

export default EmailMessage
