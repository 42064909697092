import FormItemDivider from '@/components/form-item/FormItemDivider'
import { globalFetchPolicy } from '@/components/layout/DashboardLayout'
import TitleTag from '@/components/title-tag/TitleTag'
import { useArchiveProjectMutation, useProjectQuery } from '@/graphql'
import { message, Row, Skeleton } from 'antd'
import { FC } from 'react'
import BlackNotification from '../../blackNotification/BlackNotification'
import Button from '../../button/Button'
import { useGeneralContext } from '../../context/GeneralContext'
import Typography from '../../typography/Typography'

const { Text, Paragraph } = Typography
const ArchiveProject: FC = () => {
  const { setIsModalOpen, currentId } = useGeneralContext()
  const [archiveProject, { loading }] = useArchiveProjectMutation()
  const { data, loading: projectDataLoading } = useProjectQuery({
    fetchPolicy: globalFetchPolicy,
    variables: { id: currentId },
  })
  const project = data?.project?.data as ProjectEntity

  const onCancel = () => {
    setIsModalOpen(false)
  }
  const onConfirm = async () => {
    await archiveProject({
      fetchPolicy: globalFetchPolicy,
      variables: {
        input: {
          projectId: currentId,
        },
      },
      onError: (error) => {
        message.error(error.message)
      },
      onCompleted: () => {
        BlackNotification('Project archived successfully. All applications inside selected project archived successfully.')
        setIsModalOpen(false)
      },
      refetchQueries: 'active',
    })
  }
  return projectDataLoading ? (
    <>
      <Skeleton active={true} paragraph={{ rows: 1, width: '100%' }} />
      <Skeleton active={true} paragraph={{ rows: 5 }} />
    </>
  ) : (
    <>
      <FormItemDivider title={'Archive project'} />
      <Text style={{ marginTop: 32 }} size={'sm'}>
        {`You are going to archive the project with title: `}
      </Text>
      <Text size={'md'} weight={'w700'}>
        {project?.attributes?.title}
      </Text>
      <Paragraph style={{ margin: '20px 0 30px' }} size={'sm'}>
        {'Please keep in mind that archiving the current project will automatically trigger the archiving of all applications inside the project.'}
      </Paragraph>
      <Paragraph size={'sm'} color={'light-gray'}>
        {'You can restore your project at your convenience.'}
      </Paragraph>
      <Row>
        {project?.attributes?.applications?.data?.map(it => (
          <TitleTag key={it?.id} text={it?.attributes?.title} />
        ))}
      </Row>
      <FormItemDivider />
      <Row justify={'end'}>
        <Button text={'Cancel'} btnType={'text'} onClick={onCancel} loading={loading} />
        <Button text={'Archive project'} btnType={'primary'} onClick={onConfirm} loading={loading} />
      </Row>
    </>
  )
}
export default ArchiveProject
