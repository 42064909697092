import FormItemDivider from '@/components/form-item/FormItemDivider'
import InfoTextItem from '@/components/info-text-item/InfoTextItem'
import { globalFetchPolicy } from '@/components/layout/DashboardLayout'
import TitleTag from '@/components/title-tag/TitleTag'
import { Divider, message, Row, Skeleton } from 'antd'
import { FC, useState } from 'react'
import { useApplicationQuery, useArchiveProjectMutation, useDeleteProjectMutation, useProjectQuery, useUpdateApplicationMutation } from '@/graphql'
import BlackNotification from '../../blackNotification/BlackNotification'
import Button from '../../button/Button'
import { useGeneralContext } from '../../context/GeneralContext'
import Typography from '../../typography/Typography'

const { Text, Paragraph } = Typography
const ArchiveProject:FC = () => {
  const { setIsModalOpen, currentId } = useGeneralContext()
  const [archiveApplication, {loading}] = useUpdateApplicationMutation()
  const {data, loading: projectDataLoading} = useApplicationQuery({
    fetchPolicy: globalFetchPolicy,
    variables: {id: currentId},
  })
const application = data?.application?.data as ApplicationEntity

  const onCancel = () => {
    setIsModalOpen(false)
  }
  const onConfirm = async () => {
    await archiveApplication({
      fetchPolicy: globalFetchPolicy,
      variables: {
        id: currentId,
        data: {
          isArchived: true
        }
      },
      onError: (error) => {
        message.error(error.message)
      },
      onCompleted: () => {
        BlackNotification('Application archived successfully.')
        setIsModalOpen(false)
      },
      refetchQueries: 'active',
    })
  }
  return projectDataLoading ? (
    <>
    <Skeleton active={true} paragraph={{rows: 1, width: '100%'}}/>
    <Skeleton active={true} paragraph={{rows: 5}} />
    </>
  ) : (
    <>
      <FormItemDivider title={'Archive application'} />
      <Text style={{ marginTop: 32 }} size={'sm'}>
        {`You are going to archive the application with title: `}
      </Text>
      <Paragraph size={'md'} weight={'w700'}>
        {application?.attributes?.title}
      </Paragraph>

      <Paragraph style={{paddingTop: 24}} size={'sm'} color={'light-gray'}>
        {'You can restore your application at your convenience.'}
      </Paragraph>

<FormItemDivider />
      <Row justify={'end'}>
        <Button text={'Cancel'} btnType={'text'} onClick={onCancel} loading={loading} />
        <Button text={'Archive application'} btnType={'primary'} onClick={onConfirm} loading={loading} />
      </Row>
    </>
  )
}
export default ArchiveProject
