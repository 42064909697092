import Button from '@/components/button/Button'
import { useBreakpoint } from '@/hooks/useBreakpoint'
import { FC } from "react"
import "./EscapeButton.less"

import { useNavigate } from "react-router"

import { SvgIcon } from "../icon"

type Props = {
  escLink?: string
  onClick?: () => void
}
const EscapeButton: FC<Props> = ({ escLink, onClick }) => {
  const navigate = useNavigate()
  const onEscape = () => {
    onClick ? onClick() : null
    if (escLink) {
      navigate(escLink)
    }
    if (!escLink && !onClick) {
      navigate(-1)
    }
  }

  const {xxl, xl, lg} = useBreakpoint()

  return (
    <Button
      className={'auth-escape-button'}
      btnType={'icon'}
      onClick={onEscape}
      bordered={xxl || xl || lg}
      icon={
        <SvgIcon
          type={"close-modal"}
          color={xxl || xl || lg ? 'gray' : 'white'}
        />}
    />
  )
}

export default EscapeButton
