import { DateFormat } from '@/helpers/globalVariables'
import {
  // Button as WrappedBtn,
  Checkbox,
  Col,
  DatePicker,
  DatePickerProps,
  Form,
  Input,
  InputNumber,
  RadioChangeEvent,
  Row,
  Select,
  Switch,
  TimePicker,
} from 'antd'
import { CheckboxValueType } from 'antd/es/checkbox/Group'
import { RangePickerProps } from 'antd/es/date-picker'
import { NamePath } from 'antd/lib/form/interface'
import moment from 'moment'
import React, { BaseSyntheticEvent, FC, ReactNode } from 'react'

import { SvgIcon } from '../icon'
import NotFoundContent from '../not-found-content/NotFoundContent'
import './FormItemDivider.less'
import { FormItemProps, Rule } from 'antd/lib/form'
import { IMaskInput } from "react-imask";

export type SelectOptionsType = {
  label: string | ReactNode
  value: string
  key?: string
}
export type CustomClassnames = 'user-select' | 'bordered'
export type FormElementsType = 'time-picker'
  | 'input'
  | 'input-number'
  | 'select'
  | 'multi-select'
  | 'multi-tags'
  | 'textarea'
  | 'date-picker'
  | 'date-time-range-picker'
  | 'password'
  | 'input-email'
  | 'custom-multi-select'
  | 'multi-select-search'
  | 'checkbox-group'
  | 'switch'
  | 'input-phone-mask'

const { RangePicker } = DatePicker
type CustomProps = {
  inputNumberSuffix?: 'R' | '$' | '%' | 'D'
  defaultValue?: string | boolean | null | undefined
  defaultSelectValues?: string[] | null | undefined
  handleChange?: (value: string | string[] | CheckboxValueType[] | RadioChangeEvent) => void
  handleRadioChange?: (e: RadioChangeEvent) => void
  handleSelectChange?: ((value: string, option: SelectOptionsType | SelectOptionsType[]) => void) | undefined
  handleMultiChange?: ((value: string[], option: SelectOptionsType | SelectOptionsType[]) => void) | undefined
  label?: string
  name: NamePath
  onRangeTimeChange?: (
    value: DatePickerProps['value'] | RangePickerProps['value'],
    dateString: [string, string] | string,
  ) => void
  onRangeTimeOk?: (value: DatePickerProps['value'] | RangePickerProps['value']) => void
  placeholder?: string
  readOnly?: boolean
  required?: boolean
  rules?: Rule[]
  selectOptions?: SelectOptionsType[] | undefined
  type: FormElementsType
  onInput?: (e: BaseSyntheticEvent) => void
  onOpenChange?: () => void
  disabledDate?: DatePickerProps['disabledDate']
  isRemoveItemAllowed?: boolean
  isDisabled?: boolean
  isShowDisabledDates?: boolean
  loading?: boolean
  notFoundText?: string
  onDateChange?: (value: moment.Moment | null) => void
  currentDateToDisabled?: moment.Moment | null
  helpText?: string
  // handleSearch?: (searchText: string) => Promise<void>
  onSelectSearch?: (value: string) => void
  rows?: number
  checkboxColSpan?: number
  onChange?: (e: BaseSyntheticEvent) => void
  showSearch?: boolean
  onPopupScroll?: (e: React.UIEvent<HTMLDivElement>) => void
  customClassname?: CustomClassnames

}
// interface Props = CustomProps & FormItemProps
// type BtnProps = typeof WrappedBtn.defaultProps
const icons = {
  close: <SvgIcon type={'close-notification'} />,
  search: <SvgIcon type={'magnifying-glass'} />,
}
export const theme = {
  variables: {
    unit: '14px',
    padding: '0.5em',
    borderRadius: '0',
    border: '1px solid',
    boxShadow: 'unset',
  },
  // icons: icons,
}

const FormItem: FC<CustomProps & FormItemProps> = ({
                                                     handleMultiChange,
                                                     required = true,
                                                     readOnly = false,
                                                     selectOptions,
                                                     name,
                                                     label,
                                                     type,
                                                     placeholder,
                                                     defaultValue,
                                                     defaultSelectValues,
                                                     rules,
                                                     onRangeTimeChange,
                                                     onRangeTimeOk,
                                                     onInput,
                                                     onOpenChange,
                                                     isRemoveItemAllowed = false,
                                                     isDisabled = false,
                                                     notFoundText,
                                                     handleSelectChange,
                                                     onDateChange,
                                                     currentDateToDisabled,
                                                     helpText,
                                                     rows = 3,
                                                     checkboxColSpan = 6,
                                                     showSearch = false,
                                                     handleChange,
                                                     loading,
                                                     onPopupScroll,
                                                     inputNumberSuffix = 'R',
                                                     customClassname,
                                                     isShowDisabledDates = true,
                                                     ...restProps
                                                   }) => {

  const disabledDates: DatePickerProps['disabledDate'] = (current) => {
    if (currentDateToDisabled) {
      return current < currentDateToDisabled.endOf('day')
    } else {
      return current && current < moment().endOf('day').subtract(1, 'day')
    }
  }

  return (
    <>
      {type === 'input' && (
        <Form.Item label={label} name={name} rules={rules ? rules : [{ required, message: 'This field is required' }]} help={helpText}>
          <Input placeholder={placeholder} readOnly={readOnly} disabled={isDisabled} onInput={onInput} />
        </Form.Item>
      )}
      {type === 'input-number' && (
        <Form.Item label={label} name={name} rules={rules ? rules : [{ required, message: 'This field is required' }]}>
          <InputNumber
            placeholder={placeholder}
            disabled={isDisabled}
            defaultValue={100}
            formatter={value =>
              `${inputNumberSuffix} ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            }
            parser={value => value!.replace(new RegExp(`\\${inputNumberSuffix}\\s?|,`, 'g'), '')}
          />
        </Form.Item>
      )}
      {type === 'input-phone-mask' && (
        <Form.Item label={label} name={name} rules={[
          { required, message: 'This field is required' },
          {
            pattern: /^\+27 \(\d{2}\) \d{3}-\d{4}$/,
            message: 'Please enter a valid phone number'
          }
        ]}>
          <IMaskInput
            className={'ant-input'}
            mask={'+27 (00) 000-0000'}
            placeholder={'+27 (00) 000-0000'}
          />
        </Form.Item>
      )}

      {type === 'input-email' && (
        <Form.Item
          label={label}
          name={name}
          required={required}
          help={helpText}
          rules={[
            { required, message: 'This field is required' },
            { type: 'email', message: 'Enter a correct email' },
          ]}
        >
          <Input placeholder={placeholder} readOnly={readOnly} />
        </Form.Item>
      )}
      {type === 'password' && (
        <Form.Item label={label} name={name} rules={rules} help={helpText}>
          <Input.Password placeholder={placeholder} readOnly={readOnly} onInput={onInput} disabled={isDisabled} />
        </Form.Item>
      )}
      {type === 'textarea' && (
        <Form.Item label={label} name={name} rules={[{ required, message: 'This field is required' }]} help={helpText} {...restProps}>
          <Input.TextArea
            readOnly={readOnly}
            placeholder={placeholder}
            rows={rows}
            autoSize={true}
            disabled={isDisabled}
          />
        </Form.Item>
      )}
      {type === 'switch' && (
        <Form.Item label={label} name={name} rules={rules}>
          <Switch defaultChecked={defaultValue as boolean} />
        </Form.Item>
      )}
      {type === 'select' && selectOptions && (
        <Form.Item label={label} name={name} rules={[{ required, message: 'This field is required' }]} help={helpText} {...restProps}>
          <Select
            className={customClassname ?? ''}
            placeholder={placeholder}
            options={selectOptions}
            suffixIcon={<SvgIcon type={'down'} />}
            aria-readonly={readOnly}
            disabled={readOnly || isDisabled}
            notFoundContent={<NotFoundContent text={notFoundText ? notFoundText : ''} />}
            onChange={handleSelectChange}
            loading={loading}
            showSearch={showSearch}
            filterOption={(input, option) =>
              (option?.label?.toString() ?? '').toLowerCase().includes(input.toLowerCase())
            }
            onPopupScroll={onPopupScroll}
            {...restProps}
          />
        </Form.Item>
      )}

      {type === 'multi-select-search' && selectOptions && (
        <Form.Item label={label} name={name} rules={[{ required, message: 'This field is required' }]} help={helpText}>
          <Select
            suffixIcon={<SvgIcon type={'magnifying-glass-gray'} />}
            className={'street-multiselect'}
            mode={'multiple'}
            placeholder={placeholder}
            notFoundContent={null}
            showSearch={true}
            disabled={readOnly}
            options={selectOptions}
            removeIcon={<SvgIcon type={'close-notification'} height={12} />}
            optionFilterProp={'children'}
            filterOption={(input, option) => {
              const optToLowerCase = option?.label?.toString().toLowerCase()
              if (optToLowerCase) {
                return optToLowerCase.includes(input.toLowerCase())
              } else {
                return false
              }
            }
            }
            showArrow={true}
          />
        </Form.Item>
      )}
      {type === 'multi-select' && selectOptions && (
        <Form.Item label={label} name={name} rules={[{ required, message: 'This field is required' }]} help={helpText}>
          <Select
            mode={'multiple'}
            placeholder={placeholder}
            suffixIcon={<SvgIcon type={'down'} />}
            showArrow={true}
            aria-readonly={readOnly}
            disabled={readOnly}
            options={selectOptions}
            onChange={handleMultiChange}
            allowClear={true}
            notFoundContent={<NotFoundContent />}
            removeIcon={<SvgIcon type={'close-notification'} height={12} />}
          />
        </Form.Item>
      )}
      {type === 'multi-tags' && selectOptions && (
        <Form.Item label={label} name={name} rules={[{ required, message: 'This field is required' }]} help={helpText}>
          <Select
            mode={'tags'}
            placeholder={placeholder}
            suffixIcon={<SvgIcon type={'download'} />}
            aria-readonly={readOnly}
            disabled={readOnly}
            options={selectOptions}
            onChange={handleMultiChange}
            allowClear={false}
            notFoundContent={<NotFoundContent text={'Enter New Name'} />}
            removeIcon={<SvgIcon type={'close-notification'} height={12} />}
          />
        </Form.Item>
      )}
      {type === 'date-picker' && (
        <Form.Item label={label} name={name} rules={[{ required, message: 'This field is required' }]}>
          <DatePicker
            suffixIcon={<SvgIcon type={'calendar'} />}
            placeholder={placeholder ? placeholder : 'Select Date'}
            placement={'bottomLeft'}
            showToday={false}
            popupClassName={'picker-custom'}
            disabled={readOnly}
            onOpenChange={onOpenChange}
            disabledDate={isShowDisabledDates ? disabledDates : undefined}
            format={DateFormat}
            onChange={onDateChange}
          />
        </Form.Item>
      )}
      {type === 'time-picker' && (
        <Form.Item label={label} name={name} rules={[{ required, message: 'This field is required' }]}>
          <TimePicker
            placeholder={placeholder ? placeholder : 'Select Time'}
            placement={'bottomRight'}
            // popupClassName={'picker-custom'}
            disabled={readOnly}
            onOpenChange={onOpenChange}
            disabledDate={disabledDates}
            format={'HH:mm'}
            onChange={onDateChange}
          />
        </Form.Item>
      )}
      {type === 'date-time-range-picker' && (
        <Form.Item label={label} name={name} rules={[{ required, message: 'This field is required' }]}>
          <RangePicker
            showTime={{ format: 'HH:mm' }}
            format={'DD MMM YYYY HH:mm'}
            onChange={onRangeTimeChange ? onRangeTimeChange : () => null}
            onOk={onRangeTimeOk ? onRangeTimeOk : () => null}
            disabled={readOnly}
            popupClassName={'picker-custom'}
            placement={'bottomRight'}
            placeholder={['Select start date', 'Select end date']}
            suffixIcon={<SvgIcon type={'calendar'} />}
          />
        </Form.Item>
      )}
      {type === 'checkbox-group' && (
        <Form.Item label={label} name={name} rules={[{ required, message: 'This field is required' }]} {...restProps}>
          <Checkbox.Group style={{ width: '100%' }} onChange={handleChange}>
            <Row gutter={[5, 12]} style={{ paddingBottom: 8 }}>
              {selectOptions?.map(it => (
                <Col span={checkboxColSpan} key={it?.value}>
                  <Checkbox value={it?.value}>{it?.label}</Checkbox>
                </Col>
              ))}
            </Row>
          </Checkbox.Group>
        </Form.Item>
      )}
    </>
  )
}

export default FormItem
