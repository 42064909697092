import './WebsiteTypography.less'

import { Typography as Wrapped } from 'antd'
import { ParagraphProps } from 'antd/es/typography/Paragraph'
import { TextProps } from 'antd/es/typography/Text'
import { TitleProps } from 'antd/es/typography/Title'
import classnames from 'classnames'
import { FC } from 'react'

export type Size = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl'

type LineHeight = 'lh-sm' | 'lh-md' | 'lh-lg'

export type TextColor = 'primary' | 'black' | 'gray' | 'light-gray' | 'white' | 'error' | 'website-secondary' | 'orange'

type EnhancedTypographyProps = {
  centered: boolean
  color: TextColor
  lh: LineHeight
  weight: 'w300' | 'w400' | 'w500' | 'w600' | 'w700' | 'w900'
}

type EnhancedTitleProps = TitleProps & EnhancedTypographyProps

type EnhancedParagraphProps = ParagraphProps & EnhancedTypographyProps & { font: 'regular' | 'primary'; size: Size }

type EnhancedTextProps = TextProps & EnhancedTypographyProps & { font: 'regular' | 'primary'; size: Size }

const Title: FC<Partial<EnhancedTitleProps>> = ({
                                                  className,
                                                  centered = false,

                                                  lh = 'lh-sm',
                                                  weight,
                                                  color = 'white',
                                                  ...baseProps

                                                }) => (
  <Wrapped.Title
    className={classnames(className, weight, color, lh, { centered: centered })}
    prefixCls={'website-typography'}
    {...baseProps} />
)

const Paragraph: FC<Partial<EnhancedParagraphProps>> = ({
                                                          className,
                                                          lh = 'lh-md',
                                                          centered = false,
                                                          size = 'md',
                                                          weight,
                                                          color = 'white',
                                                          font = 'primary',
                                                          ...baseProps
                                                        }) => (
  <Wrapped.Paragraph
    className={classnames(className, weight, color, size, lh, {
      'centered': centered,
      'font-regular': font === 'regular',
    })}
    prefixCls={'website-typography'}
    {...baseProps}
  />
)

const Text: FC<Partial<EnhancedTextProps>> = ({
                                                className,
                                                centered = false,
                                                lh = 'lh-md',
                                                size = 'sm',
                                                weight,
                                                color = 'white',
                                                font = 'primary',
                                                ...baseProps
                                              }) => (
  <Wrapped.Text
    className={classnames(className, weight, color, size, lh, {
      'centered': centered,
      'font-regular': font === 'regular',
    })}
    prefixCls={'website-typography'}
    {...baseProps}
  />
)

const WebsiteTypography = { Title, Paragraph, Text }

export default WebsiteTypography
