import { AuthLayout } from '@/components/layout/AuthLayout'
import { NoSidebarLayout } from '@/components/layout/NoSidebarLayout'
import ErrorPage from '@/pages/error-page/ErrorPage'
import { NotFoundProps } from '@/pages/not-found/NotFound'
import CheckOrgExistence from '@/pages/CheckOrgExistence'
import { ROUTES } from '@/router/routes'
import { FC, lazy, memo } from 'react'
import type { RouteObject } from 'react-router-dom'
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom'
import { PlatformLayout, WebsiteLayout } from 'src/components/layout'
import { AppPageProps } from './application/content/Details'
import { TasksPageProps } from './tasks/Tasks'
import { DashboardLayout } from '@/components/layout/DashboardLayout'

// import { ErrorBoundary } from '@/components/app/ErrorBoundary'

const NotFound = lazy<React.FC<NotFoundProps>>(() => import('./not-found/NotFound'))
const Overview = lazy<FC>(() => import('./overview/Overview'))
const Project = lazy<FC>(() => import('@/pages/project/Project'))
const ProjectsPage = lazy<FC>(() => import('./projects-page/ProjectsPage'))
const Companies = lazy<FC>(() => import('./companies/Companies'))
const EmailLogs = lazy<FC>(() => import('./email-logs/EmailLogs'))
const Company = lazy<FC>(() => import('./company/Company'))
const ServiceApplications = lazy<FC>(() => import('./service-applications/ServiceApplications'))
const GovernmentWork = lazy<FC>(() => import('./government-work'))
const CreateApplication = lazy<FC>(() => import('./create-application/CreateApplication'))
const ApplicationDetails = lazy<FC>(() => import('./create-application/form-content/ApplicationDetails'))
const LocationConfirmation = lazy<FC>(() => import('./create-application/form-content/LocationConfirmation'))
const UploadDocumentsPage = lazy<FC<AppPageProps>>(() => import('./create-application/form-content/UploadDocuments'))
const Application = lazy<FC>(() => import('./application/Application'))
const Auth = lazy<FC>(() => import('@/pages/auth/Auth'))
const PrivacyPolicy = lazy<FC>(() => import('./privacy-policy/PrivacyPolicyTerms'))
const Home = lazy<FC>(() => import('@/pages/website-landing/home/index'))
const Industries = lazy<FC>(() => import('@/pages/website-landing/industries/index'))
const OurServices = lazy<FC>(() => import('@/pages/website-landing/our-services/index'))
const Consulting = lazy<FC> (()=>import('@/pages/website-landing/consulting/index'))
const Blog = lazy<FC> (()=>import('@/pages/website-landing/blog/index'))
const ServiceProviderOverview = lazy<FC>(() => import('./service-overview/ServiceOverview'))
const Profile = lazy<FC>(() => import('./profile/Profile'))
const ProjectApplications = lazy<FC>(() => import('./applications/Applications'))
const TemplateSubjects = lazy<FC>(() => import('./template-subjects/TemplateSubjects'))
const TemplateSubject = lazy<FC>(() => import('./template-subject/TemplateSubject'))
const TemplateDocument = lazy<FC>(() => import('./template-document/TemplateDocument'))
const CreateNote = lazy<FC>(() => import('./create-note/CreateNote'))
const Tasks = lazy<FC<TasksPageProps>>(() => import('./tasks/Tasks'))
const CreateTask = lazy<FC>(() => import('./create-task/CreateTask'))
const Customers = lazy<FC>(() => import('./customers/Customers'))
const Users = lazy<FC>(() => import('./users/Users'))
const TemplatesGroups = lazy<FC>(() => import('./template-groups/TemplateGroups'))
const Reports = lazy<FC>(() => import('./reports/Reports'))
const PdfDocumentPreview = lazy<FC>(() => import('./pdf-documents-preview/AppLetter'))
const SplitApplication = lazy<FC>(() => import('./create-application/split-application/SplitApplication'))
const ReviewApplicationsCards = lazy<FC>(() => import('./create-application/split-application/ReviewApplicationCards'))
const ManagerMap = lazy<FC>(() => import('./manager-map/ManagerMap'))
const Quote = lazy<FC>(() => import('@/pages/website-landing/quote/index'))
const PlatformSettings = lazy<FC>(() => import('@/pages/platform-settings/PlatformSettings'))
const RedirectToHome = lazy<FC>(() => import('@/pages/RedirectToHome'))


const {
  dashboard,
  website,
} = ROUTES

const routes: RouteObject[] = [
  {
    path: '',
    element: <WebsiteLayout />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <Navigate to={`/easetech/${website.home}`} />,
      },
      {
        element: (
          <CheckOrgExistence>
            <Home />
          </CheckOrgExistence>
        ),
        path: `/:org/${website.home}`,
      },
      {
        path: `/:org`,
        element: <RedirectToHome />,
      },
      {
        element: (
          <CheckOrgExistence>
            <Industries />
          </CheckOrgExistence>
        ),
        path: `/:org/${website.industries}`,
      },
      {
        element: (
          <CheckOrgExistence>
            <OurServices />
          </CheckOrgExistence>
        ),
        path: `/:org/${website.ourServices}`,
      },
      {
        element: (
          <CheckOrgExistence>
            <Consulting />
          </CheckOrgExistence>
        ),
        path: `/:org/${website.consulting}`,
      },
      {
        element: (
          <CheckOrgExistence>
            <Blog />
          </CheckOrgExistence>
        ),
        path: `/:org/${website.blog}`,
      },
      {
        element: (
          <CheckOrgExistence>
            <Quote />
          </CheckOrgExistence>
        ),
        path: `/:org/${website.quote}`,
      },
      {
        element: <PrivacyPolicy />,
        path: website.privacyPolicy,
      },
      {
        element: <PrivacyPolicy />,
        path: website.termsAndConditions,
      },
      {
        element: (
          <CheckOrgExistence>
            <NotFound mode={'page'} />
          </CheckOrgExistence>
        ),
        path: '/:org/*',
      }, {
        element: <NotFound mode={'org'} />,
        path: '*',
      },
    ],
  },
  {
    element: <AuthLayout />,
    errorElement: <ErrorPage />,
    children: [
      {
        element: <Auth />,
        path: `/:org/${website.auth}/:mode`,
      },
    ],
  },
  {
    path: dashboard.index,
    element: <PlatformLayout />,
    errorElement: <ErrorPage />,
    children: [
      {
        element: <DashboardLayout />,
        children: [
          {
            path: dashboard.overview,
            Component: Overview,
          },
          {
            path: `${dashboard.projects}/:key`,
            Component: ProjectsPage,
          },
          {
            path: dashboard.applications,
            Component: ProjectApplications,
          },
          {
            path: `${dashboard.companies}/:key`,
            Component: Companies,
          },
          {
            path: `${dashboard.emailLogs}/:key`,
            Component: EmailLogs,
          },
          {
            path: `${dashboard.company}/:menu`,
            Component: Company,
          },
          {
            element: <Customers />,
            path: dashboard.customers,
          },
          {
            element: <Users />,
            path: dashboard.users,
          },
          {
            element: <Tasks mode={'default'} />,
            path: dashboard.tasks,
          },
          {
            element: <PlatformSettings />,
            path: `${dashboard.platformSettings}/:key`,
          },
          {
            element: <Project />,
            path: `${dashboard.project}/:category/:key`,
          },
          {
            element: <Tasks mode={'project'} />,
            path: dashboard.projectTasks,
          },
          {
            element: <Tasks mode={'readonly'} />,
            path: dashboard.agentProfessionalsTasks,
          },
          {
            element: <CreateTask />,
            path: dashboard.task,
          },
          {
            element: <TemplateSubjects />,
            path: dashboard.templateSubjects,
          },
          {
            element: <Reports />,
            path: dashboard.reports,
          },
          {
            element: <Tasks mode={'default'} />,
            path: dashboard.tasks,
          },
          {
            element: <ServiceProviderOverview />,
            path: dashboard.overview,
          },
          {
            element: <ProjectApplications />,
            path: dashboard.projectApplications,
          },

          {
            element: <ServiceApplications />,
            path: dashboard.serviceProviderApplications,
          },
          {
            element: <GovernmentWork />,
            path: dashboard.governmentWorks,
          },
          {
            element: <Application />,
            path: `${dashboard.application}/:url`,
          },
          {
            element: <CreateApplication />,
            path: dashboard.createApplication,
            children: [
              {
                element: <ApplicationDetails />,
                path: 'application-details/:step',
              },
              {
                element: <LocationConfirmation />,
                path: 'location-confirmation/:step',
              },
              {
                element: <UploadDocumentsPage isEditMode={false} setIsEditActive={() => null} />,
                path: 'upload-documents/:step',
              },
            ],
          },
          {
            element: <Profile />,
            path: `${dashboard.profile}/:step`,
          },
          {
            element: <TemplatesGroups />,
            path: dashboard.templateGroups,
          },
          {
            element: <TemplateSubject />,
            path: dashboard.templateSubject,
          },
          {
            element: <TemplateDocument />,
            path: dashboard.templateDocument,
          },
          {
            element: <PdfDocumentPreview />,
            path: 'pdf-document-preview',
          },

          {
            element: <ManagerMap />,
            path: dashboard.managerMap,
          },

        ],
      },
      {
        element: <NoSidebarLayout />,
        children: [
          {
            element: <CreateTask />,
            path: dashboard.createTask,
          },
          {
            element: <CreateNote />,
            path: dashboard.createNote,
          },
          {
            element: <SplitApplication />,
            path: dashboard.splitApplications,
          },
          {
            element: <ReviewApplicationsCards />,
            path: dashboard.reviewApplicationsCards,
          },
        ],
      },
    ],
  },
]

const Router = () => <RouterProvider router={createBrowserRouter(routes)} />
export default memo(Router)
