import BlackNotification from '@/components/blackNotification/BlackNotification'
import { useAuth } from '@/components/context/AuthProvider'
import CustomUpload from '@/components/custom-upload/CustomUpload'
import FormItem, { SelectOptionsType } from '@/components/form-item/FormItem'
import FormItemDivider from '@/components/form-item/FormItemDivider'
import RichText from '@/components/form-item/rich-text/RichText'
import { getGeoJsonFromUrl } from '@/components/google-map/helpers/getGeoJsonFromUrl'
import GeoJsonRender from '@/components/google-map/helpers/MapFeatureRenderes/GeoJsonRender'
import { mapFitBounds } from '@/components/google-map/helpers/mapFitBounds'
import MapBoxMap from '@/components/google-map/map-box-map/MapBoxMap'
import MarkerCircle from '@/components/google-map/marker-circle/MarkerCircle'
import { SvgIcon } from '@/components/icon'
import { globalFetchPolicy } from '@/components/layout/DashboardLayout'
import useSaveCertificate from '@/components/modal/modal-content/hooks/useSaveCertificate'
import Status, { StatusType } from '@/components/status/Status'
import {
  useApplicationsQuery,
  useCompletionCertificateQuery,
  useDiscussionTopicsQuery,
  useSendRecordMutation,
  useUpdateKickoffRecordMutation,
} from '@/graphql'
import useApplicationByUuid from '@/hooks/useApplicationByUuid'
import useApprovalSetUserProfiles from '@/hooks/useApprovalSetUserProfileIds'
import useParticipantsOptions from '@/hooks/useParticipantsOptions'
import { DefaultFileListItem } from '@/types/upload'
import { Col, Form, Popconfirm, Row, Skeleton, Space } from 'antd'
import { Feature, FeatureCollection, Point } from 'geojson'
import moment from 'moment'
import { FC, MutableRefObject, Ref, useEffect, useRef, useState } from 'react'
import { LngLatLike, MapRef, Marker, MarkerDragEvent } from 'react-map-gl'
import Button from '../../button/Button'
import { useGeneralContext } from '../../context/GeneralContext'
import Typography from '../../typography/Typography'


const { Text, Paragraph } = Typography
const CreateCertificate: FC = () => {
  const { user, role } = useAuth()
  const mapRef = useRef<MapRef>()
  const [form] = Form.useForm()
  const readOnly = role === 'client'

  const { setIsModalOpen, currentId, setModalWidth, setCurrentId, setIsDrawerOpen, setDrawerType} = useGeneralContext()


  const [updateRecord, { loading: recordUpdating }] = useUpdateKickoffRecordMutation()
  const [sendRecord, { loading: sendingRecord }] = useSendRecordMutation()


  const [location, setLocation] = useState<Point | null>(null)
  const [uploadFilesIds, setUploadFilesIds] = useState<Maybe<string>[] | undefined>([])
  const [uploadApprovalFilesIds, setUploadApprovalFilesIds] = useState<Maybe<string>[] | undefined>([])
  const [defaultFileList, setDefaultFileList] = useState<DefaultFileListItem[]>([])
  const [defaultApprovalFileList, setDefaultApprovalFileList] = useState<DefaultFileListItem[]>([])
  const [selectedParticipantsIds, setSelectedParticipantsIds] = useState<Maybe<string>[]>([])
  const [isCurrentCertificateDisabled, setIsCurrentCertificateDisabled] = useState(false)
  const [projectGeoJsonFeature, setProjectGeoJsonFeature] = useState<FeatureCollection | null>(null)
  const [mapRefState, setMapRefState] = useState<Ref<MapRef>>(null)
  
  
  const {data, loading: applicationLoading} = useApplicationByUuid()
  
  const { data: currentCertificateData, loading: currentCertificateLoading } = useCompletionCertificateQuery({
    fetchPolicy: globalFetchPolicy,
    variables: { id: currentId },
  })

  const currentCertificate = currentCertificateData?.completionCertificate?.data as CompletionCertificateEntity
  const appData = data?.applications?.data?.[0] as ApplicationEntity
  const applicationProject = appData?.attributes?.project?.data as ProjectEntity
  const itemId = currentCertificate?.id
  const applicationCenterFeature = appData?.attributes?.project?.data?.attributes?.projectCenter as Feature
  const applicationCenter = applicationCenterFeature?.geometry as Point

  useEffect(() => {
    setIsCurrentCertificateDisabled(currentCertificate?.attributes?.status === 'issued')
  }, [currentCertificate?.attributes?.status])

  useEffect(() => {
    if (currentCertificate) {
      const defaultFiles = currentCertificate?.attributes?.photos?.data?.map(it => ({
        uid: it?.id,
        name: it?.attributes?.name,
        status: 'done',
        url: it?.attributes?.url,
        thumbUrl: it?.attributes?.url,
      } as DefaultFileListItem)) || []

      const defaultApprovalFiles = currentCertificate?.attributes?.documents?.data?.map(it => ({
        uid: it?.id,
        name: it?.attributes?.name,
        status: 'done',
        url: it?.attributes?.url,
        thumbUrl: it?.attributes?.url,
      } as DefaultFileListItem)) || []

      setUploadFilesIds(currentCertificate?.attributes?.photos?.data?.map(it => it?.id))
      setUploadApprovalFilesIds(currentCertificate?.attributes?.documents?.data?.map(it => it?.id))

      setDefaultFileList([...defaultFiles])
      setDefaultApprovalFileList([...defaultApprovalFiles])
    }
  }, [currentCertificate])

  const { data: discussionTopicsData, loading: topicsLoading } = useDiscussionTopicsQuery({
    fetchPolicy: globalFetchPolicy,
    variables: {
      filter: {category: {eq: 'certificate'}}
    }
  })

  const discussionTopics = discussionTopicsData?.discussionTopics?.data
  const topicsOptions: SelectOptionsType[] = discussionTopics?.map(it => ({
    label: it?.attributes?.title || '',
    value: it?.id || '',
  })) || []

  useEffect(() => {
    if (!applicationLoading && applicationCenter && !currentCertificateLoading && !currentCertificate) {
      setLocation(applicationCenter)
    }
    if (!currentCertificateLoading && currentCertificate) {
      setLocation(currentCertificate?.attributes?.location as Point)
    }
  }, [applicationCenter, applicationLoading, currentCertificate, currentCertificateLoading])

  const { onSaveAsDraft, loading } = useSaveCertificate({
    mapRef,
    appData,
    location,
  })
  const approvalSetUserProfilesId = useApprovalSetUserProfiles(appData)
  const { approvalItemUserProfiles, options: participantsOptions, loading: userProfilesDataLoading } = useParticipantsOptions(approvalSetUserProfilesId)
  const recipientsEmails = approvalItemUserProfiles?.filter(it => it?.id && selectedParticipantsIds?.includes(it?.id))?.map(it => it?.attributes?.primaryEmail)?.toString()?.split(',')?.join(', ')

  useEffect(() => {
    if (mapRefState && applicationProject?.attributes?.geoJson?.data?.attributes?.url) {
      // setProjectGeoJsonFileUrl(applicationProject?.attributes?.geoJson?.data?.attributes?.url)
      const getGeoJsonProjectInfo = async () => {
        const projectsFeature = await getGeoJsonFromUrl(applicationProject?.attributes?.geoJson?.data?.attributes?.url)
        setProjectGeoJsonFeature(projectsFeature as unknown as FeatureCollection)
        projectsFeature && mapFitBounds(mapRef as MutableRefObject<MapRef>, projectsFeature)
      }
      getGeoJsonProjectInfo().then()
    }
    
  }, [applicationProject?.attributes?.geoJson?.data?.attributes?.url, mapRefState])

  const onMarkerDrag = (e: MarkerDragEvent) => {
    setLocation({
      type: 'Point',
      coordinates: [e.lngLat.lng, e.lngLat.lat],
    })
  }
  const onLoad = () => {
    mapRef?.current?.setCenter(applicationCenter?.coordinates as LngLatLike)
    mapRef?.current?.easeTo({ zoom: 14 })
  }
  const onSaveAndSubmit = async () => {
    const values = await form.validateFields()
    const updatedRecordId = await onSaveAsDraft(values, uploadFilesIds, uploadApprovalFilesIds, itemId, selectedParticipantsIds, 'issued')

    if (updatedRecordId) {
      await sendRecord({
        fetchPolicy: globalFetchPolicy,
        variables: {
          input: {
            recordId: updatedRecordId as string,
            organizationId: user?.organization?.data?.id || '',
          },
        },
        onCompleted: () => {
          BlackNotification('Completion Certificate sent successfully')
          setIsModalOpen(false)
          setModalWidth(null)

        },
        onError: (error) => {
          BlackNotification(error.message)
          setIsModalOpen(false)
        },
      })
    }
  }

  const handleSaveAsDraft = async (values: KickoffRecordInput) => {
    await onSaveAsDraft(values, uploadFilesIds, uploadApprovalFilesIds, itemId, selectedParticipantsIds, 'draft')
    setIsModalOpen(false)
    setModalWidth(null)
  }

  useEffect(() => {
    setSelectedParticipantsIds(currentCertificate ? currentCertificate?.attributes?.participants?.data?.map(it => it?.id) || [] : approvalSetUserProfilesId)
  }, [approvalSetUserProfilesId, currentCertificate])

  const initialValues = {
    date: currentCertificate ? moment(currentCertificate?.attributes?.date, 'YYYY-MM-DD') : null,
    description: currentCertificate ? currentCertificate?.attributes?.description : null,
    participants: currentCertificate ? currentCertificate?.attributes?.participants?.data?.map(it => it?.id) : approvalSetUserProfilesId,
    discussionTopics: currentCertificate ? currentCertificate?.attributes?.discussionTopics?.data?.map(it => it?.id) : [],
  }

  const onConfirm = async () => {
    currentCertificate?.id && await updateRecord({
      fetchPolicy: globalFetchPolicy,
      variables: {
        id: currentCertificate?.id,
        data: {
          status: 'accepted',
        },
      },
      onCompleted: () => {
        BlackNotification('Completion Certificate has been successfully updated.')
        setIsModalOpen(false)
      },
      onError: (error) => {
        BlackNotification(`Error occurred. ${error.message}`)
        setIsModalOpen(false)
      },
      refetchQueries: 'active',
    })
  }
  const onParticipantsChange = (idArray: string[]) => {
    setSelectedParticipantsIds(idArray)
  }

  const onAddContact = () => {
    setIsDrawerOpen(true)
    setDrawerType('create-profile')
    setCurrentId(user?.userProfile?.data?.attributes?.company?.data?.id || '')
  }
  const locationPoint = {
    'type': 'Feature',
    'geometry': location,
  } as Feature<Point>
  return applicationLoading || currentCertificateLoading || topicsLoading ? (
    <Skeleton paragraph={{ rows: 10 }} />
  ) : (
    <>
      <FormItemDivider
        title={currentCertificate ? 'Edit Completion Certificate' : 'Create Completion Certificate'}
        subTitle={`${currentCertificate ? 'Edit' : 'Create'} you Completion Certificate and save it under the current application.`}
        marginTop={0}
        marginBottom={10}
        extra={<Status status={(currentCertificate?.attributes?.status ?? 'draft') as StatusType} accented={true} />}
      />
      <Space direction={'vertical'} style={{ width: '100%', paddingTop: 8 }} size={'middle'} className={'big-modal-with-scroll-container'}>
        <Form
          layout={'vertical'}
          size={'middle'}
          onFinish={handleSaveAsDraft}
          form={form}
          initialValues={initialValues}
        >
          <Row>
            <Col span={24}>
              <MapBoxMap
                mapRef={mapRef as Ref<MapRef>}
                isDrawTools={false}
                isGeometryVisibilityToggle={false}
                mapContainerHeight={300}
                onLoad={onLoad}
                setMapRef={setMapRefState}
                isSettingsPanelVisible={false}
                // isGetSnapshotShow={true}
                // handleGetSnapshot={handleGetSnapshot}

              >

                {projectGeoJsonFeature && <GeoJsonRender project={projectGeoJsonFeature} isSimplify={true} color={'#1d48a9'} fillColor={'#216d9b'} />}
                {location && (
                  <>
                    <Marker
                      key={'center'}
                      longitude={location?.coordinates?.[0]}
                      latitude={location?.coordinates?.[1]}
                      draggable={true}
                      onDrag={onMarkerDrag}
                      style={{ zIndex: 3, opacity: 0 }} // to be able to drag marker but MarkerCircle will be rendered
                    />
                    <MarkerCircle locationPoint={locationPoint} />
                  </>
                )}
              </MapBoxMap>
              {!readOnly && <Text>{'Drag marker if needed to specify the exact place of meeting.'}</Text>}
            </Col>
          </Row>

          <Row style={{ paddingTop: 24 }}>
            <Col span={24}>
              <FormItem name={'date'} type={'date-picker'} label={'Date of issue'} readOnly={readOnly} />
            </Col>
          </Row>
          <FormItem
            name={'discussionTopics'}
            type={'multi-select'}
            placeholder={'Select key discussions'}
            label={'Key discussions'}
            selectOptions={topicsOptions}
            readOnly={readOnly}
          />
          <FormItem
            name={'description'}
            type={'textarea'}
            placeholder={'Enter your comments'}
            rows={5}
            label={'Comments'}
            readOnly={readOnly}
          />
          {/*<RichText />*/}
          <FormItem
            name={'participants'}
            type={'multi-select'}
            placeholder={'Select/ create approved by profiles'}
            label={'Approved by:'}
            selectOptions={participantsOptions}
            handleMultiChange={onParticipantsChange}
            loading={userProfilesDataLoading}
            helpText={'Inside recipients options you can find people selected as a "Site contacts". Create a new one if needed.'}
          />
          <Row style={{marginBottom: 24}} justify={'end'}>
            <Button btnType={'table-action'} icon={<SvgIcon type={'plus'} />} text={'Add contact'} onClick={onAddContact} />
          </Row>
          <Row>
            <Col span={24}>
              <CustomUpload
                defaultFileList={defaultFileList}
                isUploadItemDisabled={role === 'client' || isCurrentCertificateDisabled}
                setUploadFilesIds={setUploadFilesIds}
                isUploadDisabled={role === 'client'}
                uploadButtonText={'Upload photos'}
              />
            </Col>
          </Row>

          {/*<FormItemDivider />*/}
          {/*<Button disabled loading={loading} text={'Generate kickoff letter'} btnType={'ghost'} icon={<SvgIcon type={'envelop'}  />} block={true} style={{marginTop: 20}} onClick={onGenerateKickoffLetter}/>*/}
          <FormItemDivider />
          <CustomUpload
            defaultFileList={defaultApprovalFileList}
            isUploadItemDisabled={role === 'client' || isCurrentCertificateDisabled}
            setUploadFilesIds={setUploadApprovalFilesIds}
            isUploadDisabled={role === 'client'}
            uploadButtonText={'Upload Certificate documents'}
          />
          {readOnly ? (
            <Row justify={'end'} style={{ paddingTop: 24 }}>
              <Button text={'Confirm & Accept'} btnType={'primary'} loading={recordUpdating} onClick={onConfirm}
                      disabled={isCurrentCertificateDisabled} />
            </Row>
          ) : (
            <Row justify={'end'} style={{ paddingTop: 24 }}>
              <Button
                text={itemId ? 'Update Certificate' : 'Save as draft'}
                btnType={'text'}
                htmlType={'submit'}
                loading={loading}
                disabled={isCurrentCertificateDisabled}
              />
              {/*<Popconfirm*/}
              {/*  placement={'topRight'}*/}
              {/*  title={<Col style={{ maxWidth: '300px' }}>*/}
              {/*    <Paragraph style={{ margin: 0 }}>{`Email with the Certificate details will be sent to the following email address: `}</Paragraph>*/}
              {/*    <Text weight={'w700'}>{recipientsEmails}</Text>*/}
              {/*  </Col>}*/}
              {/*  onConfirm={onSaveAndSubmit}*/}
              {/*  okText={'Send'}*/}
              {/*  cancelText={'Cancel'}*/}
              {/*  cancelButtonProps={{ loading: sendingRecord }}*/}
              {/*  okButtonProps={{ loading: sendingRecord }}*/}
              {/*>*/}
                <Button
                  icon={<SvgIcon type={'checked-square'}/> }
                  text={'Issue Certificate'}
                  btnType={'primary'}
                  loading={loading}
                  disabled={isCurrentCertificateDisabled}
                  onClick={onSaveAndSubmit}
                />
              {/*</Popconfirm>*/}
            </Row>
          )}
        </Form>
      </Space>
    </>
  )
}
export default CreateCertificate
