import UserAvatar from '@/components/user-avatar/UserAvatar'
import { getInitials } from '@/helpers/getInitials'
import { longDash } from '@/helpers/globalVariables'
import classnames from 'classnames'
import React, { FC } from 'react'
import DoubleDotsMenu from '../double-dots-menu/DoubleDotsMenu'
import Typography, { Size } from '../typography/Typography'
import './UserContactCard.less'
import { Col, Row, Space } from 'antd'

const { Text, Paragraph } = Typography

const menu = [
  {
    label: 'Add to task',
    link: '#',
  },
]
type Props = {
  user: UserProfileEntity
  size?: 'regular' | 'small'
}
const UserContactCard: FC<Props> = ({ user, size = 'regular' }) => {

  const avatarImage = user?.attributes?.avatar?.data?.attributes?.url
  const userFullName = user?.attributes?.fullName || ''

  const classes = classnames(size,{
    'user-contact-card': true,
  })
  return (
    <Row className={classes}>
      <Space>
        <UserAvatar avatarImageUrl={avatarImage} userFullName={userFullName || ''} />
            <Col>
              <Text size={size === 'regular' ? 'md' : 'sm'} weight={'w700'}>
                {user?.attributes?.fullName}
              </Text>
              <Paragraph size={size === 'regular'? 'sm': 'xs'} style={{marginBottom: 2}} color={'light-gray'}>{user?.attributes?.primaryEmail}</Paragraph>
              <Paragraph size={size === 'regular'? 'sm': 'xs'} style={{marginBottom: 2}} color={'light-gray'}>{user?.attributes?.primaryPhone ?? longDash}</Paragraph>
            </Col>
      </Space>
    </Row>
  )
}

export default UserContactCard
