import { Layout } from 'antd'
import { FC, Suspense } from 'react'
import { Outlet } from 'react-router'
import { WebsiteLoader } from '@/components/layout/WebsiteLoader'
import '../../styles/tooltip.less'
import './index.less'

const AuthLayout: FC = () => {
  return (
    <Layout>
      <Suspense fallback={<WebsiteLoader />}>
        <Outlet />
      </Suspense>
    </Layout>
  )
}

export { AuthLayout }
