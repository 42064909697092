import { longDash } from '@/helpers/globalVariables'
import { FC } from 'react'
import Typography, { EnhancedTitleProps } from '../typography/Typography'

const { Text } = Typography

const LongDashText:FC<Partial<EnhancedTitleProps>> = ({...rest}) => {
  return (
    <Text weight={'w500'} size={'sm'} font={'regular'} {...rest}>{longDash}</Text>
  )
}

export default LongDashText
