import { useAuth } from '@/components/context/AuthProvider'
import { globalFetchPolicy } from '@/components/layout/DashboardLayout'
import { useOrganizationsIdsQuery } from '@/graphql'
import { USER_TOKEN } from '@/helpers/auth'
import BackToTopButton from '@/pages/website-landing/components/back-to-top-button/BackToTopButton'
import Footer from '@/pages/website-landing/components/footer/Footer'
import Header from '@/pages/website-landing/components/header/Header'
import WebsiteLandingLoader from '@/pages/website-landing/components/website-landing-loader/WebsiteLandingLoader'
import { EASETECH_COMPANY } from '@/utilities/global-variables'
import { Layout } from 'antd'
import { FC, Suspense } from 'react'
import { Outlet, useParams } from 'react-router'
import '../../styles/tooltip.less'

import './index.less'

const WebsiteLayout: FC = () => {
  const { org } = useParams()

  const { data } = useOrganizationsIdsQuery({
    fetchPolicy: globalFetchPolicy,
    variables: {
      filter: { slug: { eqi: org } },
    },
  })

  const isOrg = !!data?.organizations?.data?.length

  const { user } = useAuth()
  if (!user) {
    localStorage.removeItem(USER_TOKEN)
  }
  return (
    <Layout>
      <BackToTopButton />

      {isOrg &&  <Header />}

      <Suspense fallback={< WebsiteLandingLoader/>}>
        <Outlet />
      </Suspense>
      {org === EASETECH_COMPANY && (
        <Footer />
      )}
    </Layout>
  )
}

export { WebsiteLayout }
