import FormItemDivider from '@/components/form-item/FormItemDivider'
import ImageFileCard from '@/components/image-file-card/ImageFileCard'
import LongDashText from '@/components/long-dash-text-item/LongDashText'
import CheckBoxInfoItem from '@/components/modal/modal-content/components/CheckBoxInfoItem'
import UniversalInfoItem from '@/components/modal/modal-content/components/UniversalInfoItem'
import SelectUserItem from '@/components/select-user-item/SelectUserItem'
import Status, { StatusType } from '@/components/status/Status'
import { useApprovalQuery } from '@/graphql'
import useApplicationByUuid from '@/hooks/useApplicationByUuid'
import { getDateAndTime } from '@/utilities/utilities'
import { Col, Row, Skeleton } from 'antd'
import moment from 'moment'
import { FC } from 'react'
import Button from '../../button/Button'
import { useGeneralContext } from '../../context/GeneralContext'
import Typography from '../../typography/Typography'

const { Paragraph } = Typography
const ApprovalReview: FC = () => {
  const { setIsDrawerOpen, currentApprovalItemId } = useGeneralContext()
  const { data, loading: appDataLoading } = useApplicationByUuid()

  const application = data?.applications?.data?.[0] as ApplicationEntity

  const { data: approvalData, loading: approvalDataLoading } = useApprovalQuery({
    variables: { id: currentApprovalItemId },
  })

  const currentApproveItem = approvalData?.approval?.data as ApprovalEntity
  const approvalLetter = currentApproveItem?.attributes?.approvalLetter?.data
  const approvalMapLetter = currentApproveItem?.attributes?.approvalMapLetter?.data

  const onCancel = () => {
    setIsDrawerOpen(false)
  }
  const feedback = getDateAndTime(currentApproveItem?.attributes?.feedback)
  const expiry = getDateAndTime(moment(currentApproveItem?.attributes?.expiryDate).toDate())
  return (
    <>
      <FormItemDivider
        extra={<Status status={currentApproveItem?.attributes?.status as StatusType} accented />}
        marginBottom={24}
        title={'Approval review'}
        subTitle={`Review approval details for application ${application?.attributes?.title}`}
      />

      <div className={'drawer-scroll-container'}>
        <Row>
          <Col span={24}>
            {approvalDataLoading ? <Skeleton active paragraph={{ rows: 10 }} /> : (
              <>
                <CheckBoxInfoItem name={'Approved'} checked={currentApproveItem?.attributes?.status === 'approved'} />
                <CheckBoxInfoItem name={'Affected'} checked={currentApproveItem?.attributes?.isAffected} />
                <CheckBoxInfoItem name={'Site visit required'} checked={currentApproveItem?.attributes?.isSiteVisitRequired} />
                <CheckBoxInfoItem name={'Is renewal applied'} checked={currentApproveItem?.attributes?.isRenewalApplied} />
                <FormItemDivider />
                <UniversalInfoItem name={'Description:'}>
                  <Paragraph>{currentApproveItem?.attributes?.description}</Paragraph>
                </UniversalInfoItem>

                <UniversalInfoItem name={'Feedback date:'}>
                  <Paragraph>{feedback?.date}</Paragraph>
                </UniversalInfoItem>
                <UniversalInfoItem name={'Expiry date:'}>
                  <Paragraph>{expiry?.date}</Paragraph>
                </UniversalInfoItem>
                <UniversalInfoItem name={'Company:'}>
                  <Paragraph>{currentApproveItem?.attributes?.company?.data?.attributes?.name}</Paragraph>
                </UniversalInfoItem>
                <UniversalInfoItem name={'Contacts:'}>
                  <>
                    {currentApproveItem?.attributes?.contacts?.data?.length ? currentApproveItem?.attributes?.contacts?.data?.map(it => (
                      <SelectUserItem key={it?.id} isBordered name={it?.attributes?.fullName} email={it?.attributes?.primaryEmail} />
                    )) : <LongDashText />}
                  </>
                </UniversalInfoItem>
                <UniversalInfoItem name={'Approval letter:'}>
                  <>
                    {approvalLetter ? (
                      <ImageFileCard marginBottom={8} name={approvalLetter?.attributes?.name || ''} isBordered imageUrl={approvalLetter?.attributes?.url} />
                    ) : <LongDashText />}
                  </>
                </UniversalInfoItem>
                <UniversalInfoItem name={'Approval map letter:'}>
                  <>
                    {approvalMapLetter ? (
                      <ImageFileCard marginBottom={8} name={approvalMapLetter?.attributes?.name || ''} isBordered
                                     imageUrl={approvalMapLetter?.attributes?.url} />
                    ) : <LongDashText />}
                  </>
                </UniversalInfoItem>
                <UniversalInfoItem name={'Attachments:'}>
                  <>
                    {currentApproveItem?.attributes?.attachments?.data?.length ? currentApproveItem?.attributes?.attachments?.data?.map(it => (
                      <ImageFileCard marginBottom={8} key={it?.id} name={it?.attributes?.name ?? 'image'} isBordered imageUrl={it?.attributes?.url} />
                    )) : <LongDashText />}
                  </>
                </UniversalInfoItem>
              </>
            )}
          </Col>
        </Row>
      </div>

      <Row style={{ paddingTop: 30 }} justify={'end'}>
        <Button
          text={'Close'}
          btnType={'primary'}
          onClick={onCancel}
          loading={appDataLoading}
        />
      </Row>
    </>
  )
}
export default ApprovalReview
