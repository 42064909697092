import { useAuth } from '@/components/context/AuthProvider'
import FormItemDivider from '@/components/form-item/FormItemDivider'
import { globalFetchPolicy } from '@/components/layout/DashboardLayout'
import SelectUserItem from '@/components/select-user-item/SelectUserItem'
import { Form, message, Row, Space } from 'antd'
import { FC } from 'react'
import { useSearchParams } from 'react-router-dom'

import { useApplicationsQuery, useCreateNotificationMessageMutation, useSendRequestInfoNotificationMutation } from '@/graphql'
import BlackNotification from '../../blackNotification/BlackNotification'
import Button from '../../button/Button'
import { useGeneralContext } from '../../context/GeneralContext'
import FormItem from '../../form-item/FormItem'
import Typography from '../../typography/Typography'

const { Paragraph } = Typography
const RequestInfo:FC = () => {
  const [form] = Form.useForm()
  const { user } = useAuth()
  const myId = user?.userProfile?.data?.id
  const [searchParams] = useSearchParams()
  const uuId = searchParams.get('id')
  const { setIsModalOpen, currentApplicationId } = useGeneralContext()
  const [createNotification] = useCreateNotificationMessageMutation()
  const [sendEmailRequest, {loading: isSending}] = useSendRequestInfoNotificationMutation()
  const currentAppId = uuId || currentApplicationId
  const { data } = useApplicationsQuery({
    fetchPolicy: globalFetchPolicy,
    variables: { filter: { uuid: { eq: currentAppId } } },
  })
  const currentAppData = data?.applications?.data?.[0]
  const onCancel = () => {
    setIsModalOpen(false)
  }
  const onFinish = async (value: { description: string; requestType: InputMaybe<EnumNotificationmessageRequesttype> }) => {

    if(currentAppData?.attributes?.project?.data?.attributes?.applicant?.data?.id && user?.userProfile?.data?.id) {
      await createNotification({
        fetchPolicy: globalFetchPolicy,
        variables: {
          input: {
            application: currentAppData?.id,
            from: myId,
            to: currentAppData?.attributes?.project?.data?.attributes?.applicant?.data?.id,
            message: value.description,
            type: 'request',
            requestType: value.requestType,
          },
        },

        onError: (error) => {
          message.error(error.message)
          setIsModalOpen(false)
        },
      })
      await sendEmailRequest({
        fetchPolicy: globalFetchPolicy,
        refetchQueries: 'active',
        variables: {
          input: {
            message: `Topic: ${value.requestType}; Message: ${value.description}`,
            organizationId: user?.organization?.data?.id ?? '',
            applicationId: currentApplicationId,
            recipientId: currentAppData?.attributes?.project?.data?.attributes?.applicant?.data?.id,
            senderId: user?.userProfile?.data?.id,
          }
        },
        onError: (error)=>{
          message.error(error.message)
          setIsModalOpen(false)
        },
        onCompleted: () => {
          form.resetFields()
          setIsModalOpen(false)
          BlackNotification(`Request notification created, email to ${currentAppData?.attributes?.project?.data?.attributes?.applicant?.data?.attributes?.primaryEmail} sent successfully`)
        },
      })
    }


  }
  const options = [
    {
      label: 'Outstanding documents',
      value: 'documents_issue',
    },
    {
      label: 'Licence issue',
      value: 'licence_issue',
    },
    {
      label: 'Payment issue',
      value: 'payment_issue',
    },
    {
      label: 'Other',
      value: 'other',
    },
  ]

  return (
    <>
      <FormItemDivider title={'Request info'} subTitle={`Request additional info concerning the current application ${currentAppData?.attributes?.title}`} />
      <Paragraph>{'Upon clicking the \'Send Request\' button, an internal platform notification will be generated, and an email will be sent to the applicant\'s email address.'}</Paragraph>
      <Form form={form} onFinish={onFinish} autoComplete={'off'} layout={'vertical'} style={{ marginTop: 12 }}>
        <Paragraph>{'Current application applicant:'}</Paragraph>
        <SelectUserItem
          name={currentAppData?.attributes?.project?.data?.attributes?.applicant?.data?.attributes?.fullName}
          email={currentAppData?.attributes?.project?.data?.attributes?.applicant?.data?.attributes?.primaryEmail}
          isBordered
          marginBottom={24}
        />
        <FormItem
          type={'select'}
          name={'requestType'}
          label={'Request reason'}
          selectOptions={options}
          placeholder={'Select request reason'}
        />
        <FormItem type={'textarea'} name={'description'} label={'Description'} placeholder={'Enter Description'} />
        <Row justify={'end'}>
          <Space>
            <Button text={'Cancel'} btnType={'text'} onClick={onCancel} loading={isSending} />
            <Button text={'Send request'} btnType={'primary'} htmlType={'submit'} loading={isSending} />
          </Space>
        </Row>
      </Form>
    </>
  )
}
export default RequestInfo
