import CustomUpload from '@/components/custom-upload/CustomUpload'
import EmailMessage from '@/components/email-message/EamilMessage'
import EmailRenderer from '@/components/email-renderer/EmailRenderer'
import FormItem from '@/components/form-item/FormItem'
import FormItemDivider from '@/components/form-item/FormItemDivider'
import { SvgIcon } from '@/components/icon'
import InfoTextItem from '@/components/info-text-item/InfoTextItem'
import { globalFetchPolicy } from '@/components/layout/DashboardLayout'
import TablePlaceholder from '@/components/table-placeholder/TablePlaceholder'
import { useEmailMessageLazyQuery, useSendApprovalItemEmailMutation } from '@/graphql'
import useApplicationByUuid from '@/hooks/useApplicationByUuid'
import useEmailHistory from '@/hooks/useEmailHistory'
import { DefaultFileListItem } from '@/types/upload'
import { getDateAndTime } from '@/utilities/utilities'
import { Col, Form, message, Row, Skeleton, Tooltip } from 'antd'
import { useForm } from 'antd/es/form/Form'
import moment from 'moment'
import { FC, useEffect, useState } from 'react'
import Button from '../../button/Button'
import { useGeneralContext } from '../../context/GeneralContext'
import Typography from '../../typography/Typography'

const { Text } = Typography
const EmailLog: FC = () => {
  const [form] = useForm()
  const { currentApprovalItemId, approvalCategory } = useGeneralContext()
  const { data, loading: appDataLoading } = useApplicationByUuid()
  const [sendApprovalItemEmail, { loading: emailSending }] = useSendApprovalItemEmailMutation()

  const [isComposeActive, setIsComposeActive] = useState(false)
  const [uploadFilesIds, setUploadFilesIds] = useState<InputMaybe<string[]>>([])
  const [isReplyComposeActive, setIsReplyComposeActive] = useState(false)
  const [replyEmail, setReplyEmail] = useState<Maybe<EmailMessageEntity>>()

  const application = data?.applications?.data?.[0] as ApplicationEntity

  const currentApproveItem = (approvalCategory === 'approval' ? application?.attributes?.approvals?.data : application?.attributes?.renewals?.data)?.find(
    it => currentApprovalItemId === it?.id,
  ) as ApprovalEntity

  const { emails, refetchEmails, loading } = useEmailHistory(currentApproveItem)

  const emailSubject = `Application number: (${application?.attributes?.title})`

  const onCompose = () => {
    setIsComposeActive(true)
  }
  const onCloseCompose = () => {
    setIsComposeActive(false)
    setIsReplyComposeActive(false)
    setReplyEmail(null)
    setUploadFilesIds([])
  }
  const onSendEmail = async (values: { message: string }) => {
    console.log(values, uploadFilesIds)

    application?.id && currentApproveItem?.id && await sendApprovalItemEmail({
      fetchPolicy: globalFetchPolicy,
      variables: {
        input: {
          message: values?.message,
          approvalItemId: currentApproveItem?.id,
          applicationId: application?.id,
          emailSubject: replyEmail ? replyEmail?.attributes?.subject ?? emailSubject : emailSubject,
          recipientEmail: replyEmail ? replyEmail?.attributes?.from ?? '' : currentApproveItem?.attributes?.company?.data?.attributes?.companyEmail || '',
          attachments: uploadFilesIds as unknown as number[],
        },
      },
      onCompleted: () => {
        setIsComposeActive(false)
        setIsReplyComposeActive(false)
        setReplyEmail(null)
        setUploadFilesIds([])
      },
      onError: (error) => {
        message.error(error.message)
      },
      refetchQueries: 'active',
    })
  }
  const onLogRefresh = () => {
    refetchEmails()
  }
  return (
    <>
      <FormItemDivider
        marginTop={12}
        marginBottom={24}
        title={'Email Log'}
        subTitle={`Review the sent and received emails for the current approval item`}
        extra={<Button btnType={'table-action'} text={'Refresh'} icon={<SvgIcon type={'refresh'} />} onClick={onLogRefresh} bordered loading={loading} />}
      />

      <div className={'drawer-scroll-container'}>
        <Row justify={'center'}>
          {loading ?
            Array.from({ length: 3 }).map((_, index) => (
              <Skeleton key={index} paragraph={{ rows: 4 }} active />
            )) : (
              emails?.map(it => (
                <EmailMessage key={it?.id} email={it} setIsReplyComposeActive={setIsReplyComposeActive} setReplyEmail={setReplyEmail} />
              ))
            )
          }
          {(!loading && !emails?.length) && (
            <Col>

              <TablePlaceholder text={'No emails inside current approval item'} />
            </Col>
          )}
        </Row>
      </div>
      {(isComposeActive || isReplyComposeActive) && (
        <Row className={'compose-email-form'}>
          <Button btnType={'icon'} icon={<SvgIcon type={'close-modal'} />} className={'close-compose'} onClick={onCloseCompose} />
          <Col span={24}>
            <FormItemDivider label={replyEmail ? 'Compose reply' : 'Compose email'} marginBottom={24} />
            <InfoTextItem label={'Subject'} text={replyEmail ? replyEmail?.attributes?.subject : emailSubject} />
            <InfoTextItem label={'From'} text={'app@info.easetech.co.za'} />
            <InfoTextItem label={'To'} text={replyEmail ? replyEmail?.attributes?.from : currentApproveItem?.attributes?.company?.data?.attributes?.companyEmail} />
            <Form form={form} layout={'vertical'} onFinish={onSendEmail} style={{ paddingTop: 12 }}>
              <FormItem name={'message'} type={'textarea'} placeholder={'Enter your message'} label={'Email message'} />
              {replyEmail && (
                <>
                  <Text>{'Original message:'}</Text>
                  <EmailRenderer emailHtml={replyEmail?.attributes?.html || ''} />
                </>
              )}

              <CustomUpload
                defaultFileList={[]}
                setUploadFilesIds={setUploadFilesIds}
                uploadButtonText={'Upload files'}
              />

              <Row justify={'end'}>
                <Button htmlType={'submit'} text={'Send email'} icon={<SvgIcon type={'arrow-left'} />} loading={emailSending} />
              </Row>
            </Form>

          </Col>
        </Row>
      )}


      <Row style={{ paddingTop: 30 }} justify={'end'}>
        <Button
          disabled={isComposeActive}
          icon={<SvgIcon type={'pencil'} />}
          text={'Compose'}
          btnType={'primary'}
          onClick={onCompose}
          loading={appDataLoading}

        />
      </Row>
    </>
  )
}
export default EmailLog
